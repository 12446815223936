<div class="bingo-teaser-sec">
    <div class="teasers-crousale teasers" [ngClass]="{ 'swiper-dots': oneTeaser }" *ngIf="enableTeasers && !isInGameClient && teasers.length > 0">
        <div [ngClass]="TeaserMode">
            <!--  promotional starts here class="md-teaser-section hero-teaser-new small-teaser"-->
            <!-- <swiper #teasersSwiper [autoplay]="autoplay" [navigation]="arrows"  [pagination]="dots"  [loop]="loop"
                    (slideChange)="onTeaserIndexChanged($event)" (mouseenter)="mouseHover(true)" (mouseleave)="mouseHover(false)"> -->
            <vn-swiper #teasersSwiper [swiperOptions]="swiperOptionsTeasers" (mouseenter)="mouseHover(true)" (mouseleave)="mouseHover(false)">
                <!-- <ngx-item *ngFor=" let teaser of teasers" (click)="teaserClick($event, teaser)" class="bottom-terms" -->
                <div *ngFor="let teaser of teasers; let i = index" class="bottom-terms swiper-slide" (click)="teaserClick($event, teaser)">
                    <!-- <ng-template *ngFor=" let teaser of teasers; let i=index" class="bottom-terms" (click)="teaserClick($event, teaser)" swiperSlide> -->
                    <div [ngClass]="teasersMainClass">
                        <div class="item-slide" class="{{ teaser.attributes.teaserAlignments }}" [ngClass]="{ 'show-terms': teaser.termsText }">
                            <div
                                *ngIf="!(teaser.jackpotName && teaser.jackpotName.trim().length > 0) && isTeaserMode"
                                teaser="teaser"
                                [ngClass]="{ 'bottom-title': teaser.attributes.bottomTitle === true }">
                                <div class="md-teaser-body">
                                    <div
                                        class="md-teaser-image"
                                        *ngIf="teaser.backgroundImage"
                                        [ngStyle]="{ 'background-image': 'url(' + teaser.backgroundImage.src + ')' }"></div>
                                    <!-- <img class="md-teaser-image  background-image" [src]=" teaser.backgroundImage.src"> -->
                                    <div class="md-teaser-text">
                                        <img *ngIf="teaser.image" [src]="teaser.image.src" alt="" />
                                        <div class="md-teaser-inner-text">
                                            <div
                                                class="title"
                                                [ngStyle]="{ color: teaser.attributes.titleColor }"
                                                [innerHtml]="teaser.title | trustAsHtml"></div>
                                            <div
                                                class="subtitle DrukWide-Medium"
                                                [ngStyle]="{ color: teaser.attributes.subtitleColor }"
                                                [innerHtml]="teaser.subtitle | trustAsHtml"></div>
                                            <div class="md-teaser-cta" *ngIf="teaser.ctaLink">
                                                <a
                                                    class="btn btn-primary"
                                                    (click)="ctaClick($event, teaser, i + 1)"
                                                    [innerHtml]="teaser.ctaTitle || moreInfoMessage"></a>
                                            </div>
                                        </div>
                                        <div
                                            class="small-terms"
                                            (click)="termsClick($event, teaser)"
                                            [ngStyle]="{ color: teaser.attributes.termsColor }">
                                            <div class="text-terms" [innerHtml]="teaser.termsText | trustAsHtml"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                *ngIf="teaser.jackpotName && teaser.jackpotName.trim().length > 0 && isTeaserMode"
                                teaser="teaser"
                                class="jackpot-teaser"
                                [ngClass]="{ 'bottom-title': teaser.attributes.bottomTitle === true }">
                                <div *ngIf="!EnableJackpotTickerTeasers && teaser.backgroundImage">
                                    <div class="md-teaser-body">
                                        <div class="md-teaser-image" [ngStyle]="{ 'background-image': 'url(' + teaser.backgroundImage.src + ')' }">
                                            <div class="md-teaser-text">
                                                <img *ngIf="teaser.image" [src]="teaser.image.src" alt="" />
                                                <div class="md-teaser-inner-text">
                                                    <div *ngIf="!embedded" class="title" [ngStyle]="{ color: teaser.attributes.jpTextColor }">
                                                        {{ teaser.jackpotName }}
                                                    </div>
                                                    <div
                                                        *ngIf="!embedded && teaser.jackpotValue"
                                                        class="title"
                                                        [ngStyle]="{ color: teaser.attributes.jpValueColor }">
                                                        {{ teaser.jpCurSymbol + ' ' }}

                                                        <bg-jp-counter [amt]="teaser.jackpotTickValue" [time]="1" [decimal]="2"></bg-jp-counter>
                                                    </div>
                                                    <div
                                                        class="subtitle DrukWide-Medium"
                                                        [ngStyle]="{ color: teaser.attributes.subtitleColor }"
                                                        [innerHtml]="teaser.subtitle | trustAsHtml"></div>
                                                    <div class="md-teaser-cta" *ngIf="teaser.ctaLink">
                                                        <a
                                                            href="#"
                                                            class="btn btn-primary"
                                                            (click)="ctaClick($event, teaser, i + 1)"
                                                            [innerHtml]="teaser.ctaTitle || moreInfoMessage"></a>
                                                    </div>
                                                </div>
                                                <div
                                                    class="small-terms"
                                                    [innerHtml]="teaser.termsText | trustAsHtml"
                                                    (click)="termsClick($event, teaser)"
                                                    [ngStyle]="{ color: teaser.attributes.termsColor }"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="EnableJackpotTickerTeasers && teaser.backgroundImage">
                                    <div class="md-teaser-body">
                                        <div class="md-teaser-image" [ngStyle]="{ 'background-image': 'url(' + teaser.backgroundImage.src + ')' }">
                                            <div class="md-teaser-text">
                                                <img *ngIf="teaser.image" [src]="teaser.image.src" alt="" />
                                                <div class="md-teaser-inner-text">
                                                    <div class="title">
                                                        <bg-flip-clock
                                                            [amount]="teaser.jackpotTickValue"
                                                            [currency]="teaser.jpCurSymbol"></bg-flip-clock>
                                                    </div>
                                                    <div
                                                        class="subtitle DrukWide-Medium"
                                                        [ngStyle]="{ color: teaser.attributes.subtitleColor }"
                                                        [innerHtml]="teaser.subtitle | trustAsHtml"></div>
                                                    <div class="md-teaser-cta" *ngIf="teaser.ctaLink" (click)="ctaClick($event, teaser, i + 1)">
                                                        <a href="#" class="btn btn-primary" [innerHtml]="teaser.ctaTitle || moreInfoMessage"></a>
                                                    </div>
                                                </div>
                                                <div
                                                    class="small-terms"
                                                    [innerHtml]="teaser.termsText | trustAsHtml"
                                                    (click)="termsClick($event, teaser)"
                                                    [ngStyle]="{ color: teaser.attributes.termsColor }"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vn-swiper>
            <div class="playbingo" *ngIf="enablebingoplay && bingocta">
                <div class="btn btn-primary playbingo-cta" [innerHtml]="bingocta.linkText | trustAsHtml" (click)="playbingoclick(bingocta)"></div>
            </div>
        </div>
    </div>
</div>
