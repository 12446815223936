<iframe [src]="iframeUrl" id="playing-now-iframe" title="playing menu" [hidden]="true">Preview not available</iframe>

<div class="playingnow-panel" *ngIf="activeGames.length > 0 && !isInGameClient">
    <div class="playingnow-text" (click)="toggleActiveGames()">
        {{ header }}
        <div class="playinggame-count">
            {{ activeGames.length }}<span class="mini-game-indicator" *ngIf="miniGame.length > 0 && this.isMiniGameInPorgress"></span>
        </div>
        <i class="theme-up" [hidden]="!isShow"></i>
        <i class="theme-down" [hidden]="isShow"></i>
    </div>
    <div class="game-card" [hidden]="!isShow">
        <div class="pn-item" *ngFor="let activeGame of activeGames; let i = index">
            <img alt="" [attr.src]="activeGame.img_src" (click)="gameLaunch(activeGame, i)" />
            <span *ngIf="!showIconImage" class="balltype" [ngClass]="'bingo-type-' + activeGame.bingo_type">{{ activeGame.bingo_type }}</span>
            <img alt="" *ngIf="showIconImage" class="iconimage" [ngClass]="'bingo-type-' + activeGame.bingo_type" src="{{ activeGame.icon_image }}" />
            <span class="theme-ex close-room" (click)="closePlayingRoom(activeGame.id)"></span>
            <span class="playingnow-title">{{ activeGame.room_name }}</span>
        </div>
        <div class="miniGame-card" *ngIf="miniGame.length > 0 && this.isMiniGameInPorgress">
            <img alt="" [attr.src]="miniGame[0].img_src" (click)="miniGameLaunch()" />
            <span class="theme-livecasinoblackjack"></span>
            <span class="theme-ex close-room" (click)="closeMiniGame()"></span>
            <span class="mini-game-title">{{ miniGame[0].game_name }}</span>
        </div>
    </div>
</div>
