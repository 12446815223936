import { Injectable } from '@angular/core';

import { Page, UserService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { RootServicesModule } from '../shared/root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class GameService {
    gameDataVar: any = {};
    categoryLookUp: any;

    private isVisible = new BehaviorSubject<boolean>(false);
    private gameData = new BehaviorSubject<any>(this.gameDataVar);

    get overlayVisibilityStatus(): Observable<boolean> {
        return this.isVisible;
    }
    get gameDataObject(): Observable<any> {
        return this.gameData;
    }

    constructor(
        private page: Page,
        private user: UserService,
    ) {}

    setOverlayVisibility(isVisible: boolean) {
        this.isVisible.next(isVisible);
    }

    gameDataInfo(data: any) {
        this.gameData.next(data);
    }

    getCategoryGamesFromLocalStorage(categoryType: string) {
        const dataFromSessionStorage = this.getDataFromSessionStorage(this.getCacheKey());
        if (dataFromSessionStorage) {
            const categoryLookUp = JSON.parse(dataFromSessionStorage);
            if (categoryLookUp[categoryType] && categoryLookUp[categoryType].games && categoryLookUp[categoryType].games.length > 0) {
                return this.findGamesInLocalStorage(categoryLookUp[categoryType].games);
            }
        }
        return null;
    }

    getGameDataByGameId(gameId: any) {
        const dataFromLocalStorage = this.getDataFromLocalStorage(gameId + '_GameMetaData');
        if (dataFromLocalStorage) {
            return JSON.parse(dataFromLocalStorage);
        }
        return null;
    }

    findGamesInLocalStorage(gameIds: any[]) {
        const gameDataSet: any = {
            gamesInLocalStorage: null,
            gamesNotAvailableInLocalStorage: null,
        };
        const localStorageGames: any[] = [];
        const localStorageGamesNotAvailable: string[] = [];
        gameIds.forEach((gameId: string) => {
            const dataFromLocalStorage = this.getDataFromLocalStorage(gameId + '_GameMetaData');
            if (dataFromLocalStorage) {
                localStorageGames.push(dataFromLocalStorage);
            } else {
                localStorageGamesNotAvailable.push(gameId);
            }
        });
        gameDataSet.gamesInLocalStorage = localStorageGames;
        gameDataSet.gamesNotAvailableInLocalStorage = localStorageGamesNotAvailable;
        return gameDataSet;
    }

    getCacheKey() {
        if (this.user.isAuthenticated) {
            return 'allCategoryLookupData_' + this.page.lang + '_' + this.user.username;
        } else {
            return 'allCategoryLookupData_' + this.page.lang;
        }
    }

    getDataFromSessionStorage(key: string) {
        try {
            return sessionStorage.getItem(key);
        } catch (error) {
            //  console.log("error occurred while getting categories from session storage" + error);
            return null;
        }
    }

    getDataFromLocalStorage(key: string) {
        try {
            return localStorage.getItem(key);
        } catch (error) {
            //  console.log("error occurred while getting game from local storage" + error);
            return null;
        }
    }
}
