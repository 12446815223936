import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { CasinoPlatformLoaderService } from '@casinocore/loader';
// import { filter } from 'rxjs/operators';
import {
    BingoConfigProviderService,
    EventEmitService,
    FeaturesClientConfig,
    FreeBingoTicketService,
    GameLaunchService,
    ModularCasinoClientConfig,
    ScheduleService,
    TeaserClientConfig,
} from '@frontend/bingo/core';
import {
    CookieService,
    DeviceService,
    LoginDialogService,
    MessageQueueService,
    NativeAppService,
    NavigationService,
    SessionStoreService,
    UserLoginEvent,
    UserService,
} from '@frontend/vanilla/core';
import { BalancePropertiesService } from '@frontend/vanilla/features/balance-properties';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { PromotionsBannerService } from '../promotions-banner/promotions-banner.service';
import { PerformanceMarker, PerformanceMarkerIdentifiers } from '../shared/services/performanceMarker.service';

@Component({
    selector: 'bg-main',
    templateUrl: 'main.component.html',
    providers: [PerformanceMarker],
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('iframeId') iframeheight: ElementRef;
    categoryName: string;
    timestamp: number;
    seoCategoryName: string;
    isProgressiveLobby: boolean;
    isMobile: boolean;
    showNavigation: boolean = true;
    showTopNavigation: boolean = true;
    showSubNavigation: boolean;
    casinoPane: boolean;
    casinorplWidgetUrl: any;
    isOverlay: boolean;
    casinoRPWidget: boolean;
    eventEmitSubscription: any;
    iframe: HTMLIFrameElement;
    isMainCategory: boolean = false;
    bingoNavigationData: any;
    bingoFavSubscription: Subscription;
    isModularCasinoEnabled: boolean;
    isInGameClient: boolean = false;
    isRPFavNpmEnabled: boolean;
    loadPortalIframeGameLaunch: boolean;
    gameRedirectUrl: any;
    bingoportalIframeSubscription: Subscription;
    enableTeasers: boolean;
    enableBingoNPM: boolean;
    isRoomsPage: boolean;
    categoryText: string = '';
    lobbyStaticBackgroundImage: string = '';
    isLobbyBgclr: boolean;
    displayTeasersAboveNav: boolean;
    showEmbeddedTeasersAboveGames: boolean;
    teaserOptimization: boolean;
    enableNavigationInIframe: boolean;
    isEnabledBingoPromotions: boolean;
    clientConfig: any;
    modularCasino: ModularCasinoClientConfig;
    bingoFeature: FeaturesClientConfig;
    teaserConfig: TeaserClientConfig;
    constructor(
        private deviceService: DeviceService,
        private activatedRoute: ActivatedRoute,
        private navigation: NavigationService,
        private sanitizer: DomSanitizer,
        // private bingoclientconfig: BingoConfig,
        private navigator: NavigationService,
        private nativeAppService: NativeAppService,
        private messageQueue: MessageQueueService,
        private loginDialogService: LoginDialogService,
        private user: UserService,
        // private router: Router,
        private gamelaunchservice: GameLaunchService,
        private session: SessionStoreService,
        private eventEmitService: EventEmitService,
        private scheduleService: ScheduleService,
        private balanceService: BalancePropertiesService,
        private eventService: EventEmitService,
        private casinoPlatformLoaderService: CasinoPlatformLoaderService,
        private performanceMarker: PerformanceMarker,
        public cookieService: CookieService,
        private freeBingoTicketService: FreeBingoTicketService,
        private promotionsBannerService: PromotionsBannerService,
        private configProviderService: BingoConfigProviderService,
        private router: Router,
    ) {
        this.modularCasino = this.configProviderService.provideModularCasinoClientConfig();
        this.bingoFeature = this.configProviderService.provideFeaturesConfig();
        this.teaserConfig = this.configProviderService.provideTeaserClientConfig();
    }

    ngOnInit() {
        this.clientConfig = this.activatedRoute.snapshot.data['config'];
        this.scheduleService.setPlayerSegmentationData();
        this.freeBingoTicketService.setUserFreeBingoTickets();
        this.isModularCasinoEnabled = this.modularCasino.isEnabled;
        this.isRPFavNpmEnabled = this.modularCasino.rpFavNpmEnabled;
        this.displayTeasersAboveNav = this.bingoFeature.displayTeasersAboveNav;
        this.enableNavigationInIframe = this.bingoFeature.enableNavigationInIframe;
        this.enableBingoNPM = this.teaserConfig.enableBingoNpm;
        this.showEmbeddedTeasersAboveGames = this.teaserConfig.showEmbeddedTeasersAboveGames;
        this.teaserOptimization = this.teaserConfig.teaserOptimization;
        this.casinorplWidgetUrl = this.clientConfig?.pageConfig?.value?.casinoPage['url'] + '/games/casinowidget/recentfavouritewidget?.box=1'; //(this.casinoClientConfig.casinoRPWidgetUrl);
        this.getPromotionsBannerData();
        if (this.nativeAppService.isNativeWrapper) {
            this.casinorplWidgetUrl += '&_nativeApp=' + this.nativeAppService.applicationName;
        }
        if (this.activatedRoute.snapshot.queryParamMap.get('.box')) {
            this.isInGameClient = true;
            this.isRPFavNpmEnabled = false;
        }
        this.eventEmitSubscription = this.eventEmitService.getEventObservable().subscribe((event) => {
            if (event.favoriteUpdated) this.PostMessgaeToWidget('favupdated', event.favoriteUpdated);
            else if (event.gameClosed) this.PostMessgaeToWidget('gameclosed', event.gameClosed);
        });
        this.bingoportalIframeSubscription = this.gamelaunchservice.BingoPortalIframeGameLaunchObs.subscribe((redirecturl: any) => {
            if (redirecturl) {
                this.gameRedirectUrl = this.sanitizer.bypassSecurityTrustResourceUrl(redirecturl);
                this.loadPortalIframeGameLaunch = true;
            }
        });
        this.user.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            this.reloadIframe();
            this.scheduleService.setPlayerSegmentationData();
            this.freeBingoTicketService.setUserFreeBingoTickets();
            this.checkTeasersConditions();
            this.onNavigationChange('');
            this.getPromotionsBannerData();
        });
        if (this.casinoPlatformLoaderService.isCasinoModuleLoaded()) {
            this.getBingoFavGamesFromCasino();
        } else {
            const casinoModuleLoadSubscription: Subscription = this.casinoPlatformLoaderService.casinoModuleLoadedObservable.subscribe(
                (isModuleLoaded: boolean) => {
                    if (isModuleLoaded) {
                        this.getBingoFavGamesFromCasino();
                        if (casinoModuleLoadSubscription) {
                            casinoModuleLoadSubscription.unsubscribe();
                        }
                    }
                },
            );
        }
        this.showSubNavigation = this.bingoFeature.enableSubNavigation;
        this.casinoPane = this.bingoFeature.casinoPane;
        this.casinoRPWidget = this.bingoFeature.enableCasinoRPWidget;
        this.checkTeasersConditions();
        if (this.activatedRoute.snapshot.queryParamMap.get('hideSubheader')) {
            this.showNavigation = false;
        }
        this.isMobile = this.deviceService.isMobilePhone;
        this.onNavigationChange('');
        this.gamelaunchservice.overlayVisibilityStatus.subscribe((isvisible: any) => {
            this.isOverlay = isvisible;
        });
        if (this.casinoPane || this.casinoRPWidget) {
            if (!document.getElementsByTagName('html')[0].classList.contains('help-contact-bar')) {
                document.getElementsByTagName('html')[0].classList.add('help-contact-bar');
            }
        }
        // window.addEventListener('CASINO_GAME_CLOSED',(event:Event)=>{
        //   let n = event.currentTarget;
        //     n.removeEventListener('casino',()=>{});
        // });
        window.onbeforeunload = () => {
            this.session.set('openedRooms', []);
        };
        this.gamelaunchservice.overlayVisibilityStatus.subscribe((isvisible: any) => {
            this.isOverlay = isvisible;
        });
        this.bingoNavigationData = this.clientConfig?.navigationLayoutConfig?.children || [];
        this.bingoNavigationData?.forEach((item: any) => {
            if (item && item.url == window.location.origin + window.location.pathname) {
                this.isMainCategory = true;
                this.categoryText = item.text;
            }
        });
        if (this.bingoFeature.isEnableFullTakeOverStaticImage) {
            this.getFullTakeOverData();
        }
        if (this.isInGameClient) this.isMainCategory = false;
        this.eventService.getEventObservable().subscribe((event) => {
            if (event.navigated) {
                this.onNavigationChange(event.navigated);
            }
        });
        if (!this.bingoFeature.enableClickInteraction) document.querySelector('html')!.classList.remove('click-interaction');
    }

    checkTeasersConditions() {
        this.enableTeasers = this.bingoFeature.enableTeasersInRooms;
        let activeRoute: string = this.navigation.location.absUrl().substr(this.navigation.location.absUrl().lastIndexOf('/') + 1);
        activeRoute = activeRoute && activeRoute.indexOf('?') ? activeRoute.split('?')[0] : activeRoute;
        if (
            activeRoute === 'bingo' ||
            activeRoute === '' ||
            activeRoute === 'en' ||
            this.router.url.indexOf('rooms') > 0 ||
            this.router.url.indexOf('schedule') > 0 ||
            this.router.url.indexOf('myfavourites') > 0
        )
            this.isRoomsPage = true;
        else {
            this.isRoomsPage = false;
        }
    }

    getBingoFavGamesFromCasino() {
        const favService = this.casinoPlatformLoaderService.getFavouriteService();
        if (favService) {
            this.bingoFavSubscription = favService.bingoProductFavouriteGamesFeedObservable.subscribe((res: any) => {
                if (res) {
                    this.eventEmitService.raiseEvent({
                        favoriteUpdatedFromWidget: {
                            roomName: res.gameName,
                            isFavourite: res.isFavourite,
                        },
                    });
                }
            });
        }
    }

    // getCasinoFavouriteService(): FavouriteService {
    //   return this.favouriteService;
    // }

    reloadIframe() {
        if (this.casinoRPWidget) {
            this.iframe = document.getElementById('casinorplWidget') as HTMLIFrameElement;
            if (this.iframe) {
                this.iframe.contentWindow!.location.href = this.casinorplWidgetUrl;
            }
        }
    }
    PostMessgaeToWidget(key: string, value: string) {
        const message = {
            key: key,
            type: 'bingovf',
            value: value,
        };
        if (!this.iframe) this.iframe = document.getElementById('casinorplWidget') as HTMLIFrameElement;
        if (this.iframe) {
            this.iframe.contentWindow!.postMessage(message, '*');
        }
    }

    @HostListener('window:message', ['$event']) receiveWidgetMessages(event: MessageEvent) {
        if (event.data.type && event.data.type == 'redirectToBingoLobby') {
            this.loadPortalIframeGameLaunch = false;
        }
        if (event.data.type && event.data.type == 'RequestGetBalance') {
            this.balanceService.refresh();
        }
        if (event.data.params && event.data.params.categoryid == 'rcp-widget') {
            if (event.data.eventName == 'LobbyLoaded') {
                // this.showWidgetLoading = false;
                if (!document.getElementsByTagName('html')[0].classList.contains('help-contact-bar')) {
                    document.getElementsByTagName('html')[0].classList.add('help-contact-bar');
                }
                this.resizeIframe(event.data.params);
            } else if (event.data.eventName == 'GameLaunch') {
                this.launchGameFromWidget(event.data.params);
            } else if (event.data.eventName == 'Error') {
                this.handleErrorResponse(event.data.params);
            } else if (event.data.eventName == 'UserAction') {
                this.handleWidgetUserActions(event.data.params);
            } else if (event.data.eventName == 'ContactUs') {
                this.getContactusUrl(event.data.params);
            } else if (event.data.eventName == 'Help') {
                this.getHelpUrl(event.data.params);
            } else if (event.data.eventName == 'favupdated') {
                this.eventEmitService.raiseEvent({
                    favoriteUpdatedFromWidget: { roomName: event.data.params.roomid, isFavourite: event.data.params.isFavourite },
                });
            } else if (event.data.eventName == 'HtmlClass') {
                //document.getElementsByTagName('html')[0].classList.add(event.data.params.htmlClass);
            }
        }
    }
    resizeIframe(data: any): void {
        this.iframe = document.getElementById('casinorplWidget') as HTMLIFrameElement;
        if (data.isFooterWidget) {
            this.iframe = document.getElementById('footerWidget') as HTMLIFrameElement;
        }
        //if (this.loadCasinoIframe) {
        if (this.iframeheight && this.iframeheight.nativeElement) {
            const iFrameID = this.iframeheight.nativeElement;
            iFrameID.style.height = data.height + 'px';
        }
        //}
        if (this.iframe) {
            this.iframe.height = data.height;
        }
    }

    launchGameFromWidget(data: any): void {
        this.navigator.goTo(data.redirectUrl, data.redirectOptions);
    }

    handleErrorResponse(data: any): void {
        if (data.errorMessages) {
            this.messageQueue.clear();
            this.messageQueue.addMultiple(data.errorMessages);
        }
        if (data.redirectUrl) {
            this.navigator.goTo(data.redirectUrl);
        }
    }

    handleWidgetUserActions(data: any): void {
        if (data.promptLogin) {
            this.loginDialogService.open({ returnUrl: data.redirectUrl, loginMessageKey: data.loginMessageKey });
        } else if (data.redirectUrl) {
            this.navigator.goTo(data.redirectUrl, data.redirectOptions);
        }
    }

    onNavigationChange(cname: string) {
        this.lobbyStaticBackgroundImage = '';
        let activeRoute: string = this.navigation.location.absUrl().substr(this.navigation.location.absUrl().lastIndexOf('/') + 1);
        activeRoute = activeRoute && activeRoute.indexOf('?') ? activeRoute.split('?')[0] : activeRoute;
        this.checkTeasersConditions();
        const seoActiveRoute = this.bingoNavigationData?.find((x: any) => x?.url?.substr(x?.url?.lastIndexOf('/') + 1) == activeRoute);
        if (activeRoute === 'bingo' || activeRoute === '' || activeRoute === 'en') {
            activeRoute = 'home';
            this.showTopNavigation = true;
            this.showSubNavigation = false;
        } else if (this.bingoFeature.enableSubNavigation && this.isMobile) {
            this.showTopNavigation = true;
            this.showSubNavigation = true;
        }
        if (cname && cname != '') {
            cname = cname.split('?')[0];
        }
        this.categoryName = cname != '' ? cname : activeRoute;
        const seoUrl = cname != '' ? cname : seoActiveRoute?.url?.substr(seoActiveRoute?.url?.lastIndexOf('/') + 1);
        this.seoCategoryName = this.categoryName == 'home' ? 'home' : seoUrl;
        this.categoryText = cname != '' ? cname : activeRoute;
        this.timestamp = new Date().getTime();
        this.isProgressiveLobby = false;
        if (this.bingoFeature.isEnableFullTakeOverStaticImage) {
            this.getFullTakeOverData();
        }
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    }
    getFullTakeOverData() {
        this.bingoNavigationData = this.clientConfig?.navigationLayoutConfig?.children || [];
        const activeRoute: string = this.navigation.location.absUrl().substr(this.navigation.location.absUrl().lastIndexOf('/') + 1);
        let isActivateRoute: boolean = false;
        if (activeRoute === 'bingo' || activeRoute === '' || activeRoute === 'en') {
            isActivateRoute = true;
        }
        const lastRoute: string = this.router.url.split('?')[0].split('/')[3];
        if (lastRoute == 'schedule' || lastRoute == 'rooms') {
            isActivateRoute = true;
        }
        if (this.bingoNavigationData?.length > 0 && isActivateRoute) {
            this.bingoNavigationData?.forEach((item: any) => {
                if (item && item.url === window.location.origin + window.location.pathname && item.image) {
                    this.lobbyStaticBackgroundImage = item.image.src ? item.image.src : '';
                    this.isLobbyBgclr = this.lobbyStaticBackgroundImage != '' ? true : false;
                }
            });
        }
    }
    // Navigating(category: string) {
    //   this.onNavigationChange(category);
    //   this.isProgressiveLobby = true;
    // }
    getContactusUrl(contactUSParam: any) {
        this.navigation.goTo(contactUSParam.contactUrl);
    }
    getHelpUrl(helpParam: any) {
        this.navigation.goTo(helpParam.helpUrl);
    }

    ngOnDestroy() {
        if (this.eventEmitSubscription) {
            this.eventEmitSubscription.unsubscribe();
        }
        this.session.set('openedRooms', []);
        if (this.bingoFavSubscription) {
            this.bingoFavSubscription.unsubscribe();
        }
        if (this.bingoportalIframeSubscription ? this.bingoportalIframeSubscription.unsubscribe() : null) {
        }
    }
    ngAfterViewInit() {
        this.performanceMarker.mark(PerformanceMarkerIdentifiers.FirstMeaningfulPaint);
    }
    getPromotionsBannerData(): void {
        if (this.user.isAuthenticated && this.deviceService?.isMobilePhone && this.bingoFeature.isEnablePromotionsBanner) {
            this.promotionsBannerService.fetchPromotionsBannerData();
            this.isEnabledBingoPromotions = true;
        }
    }
}
