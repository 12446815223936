<div
    *ngIf="bingoItem.displayRoom && !bingoItem.tournament_enabled && !isMobileView && !(ingame_rooms_status && innerWidth < 800 && isTabletView)"
    class="bingolobby-main"
    [ngClass]="renderRoomClass()">
    <div
        class="card"
        [ngClass]="
            bingoItem.isRoomLocked || bingoItem.lockRoomInLogoutStage || (bingoItem.displayPrivateRoom && bingoItem.privateRoom)
                ? isGrayedOut
                    ? 'card-greyout roomlack'
                    : 'roomlack'
                : isGrayedOut
                  ? 'card-greyout'
                  : ''
        "
        [style.--RoomGreyoutColor]="isGrayedOut ? roomIconslist?.attributes?.roomGreyoutColor : '#000'"
        [ngStyle]="{
            'border-color': isFabGrabRoom ? roomIconslist?.attributes?.fabgrabBorderClr : '',
            'background-color': isFabGrabRoom ? roomIconslist?.attributes?.fabgrabBgClr : ''
        }">
        <div
            *ngIf="bingoItem.isRoomLocked || bingoItem.lockRoomInLogoutStage || (bingoItem.displayPrivateRoom && bingoItem.privateRoom)"
            class="room-lacked">
            <span>{{ roomLockedText }}</span>
            <i class="theme-locked-i"></i>
            <div class="moreinfo-card" (click)="roomLockedMoreInfo(bingoItem)">
                <div class="btn btn-light btn-block moreinf">{{ moreInfoText }}</div>
            </div>
        </div>
        <div
            class="mm-spent"
            *ngIf="bingoItem.optimoveLabel && bingoItem.enableStickerForRoom"
            [ngStyle]="{ background: roomIconslist?.attributes?.roomTopRibbonBgClr, color: roomIconslist?.attributes?.roomTopRibbonClr }">
            <i class="theme-bingo-star"></i>{{ bingoItem.optimoveLabel }}
        </div>
        <div
            class="mm-spent"
            *ngIf="bingoItem.isPreferredRoom && !bingoItem.optimoveLabel"
            [ngStyle]="{ background: roomIconslist?.attributes?.roomTopRibbonBgClr, color: roomIconslist?.attributes?.roomTopRibbonClr }">
            <i class="theme-bingo-star"></i>{{ roomIconslist.columns.preferredBingoRoom }}
        </div>
        <div class="thumb">
            <img alt="" src="{{ bingoItem.roomImage ? bingoItem.roomImage : thumbImageSrc }}" />
            <div class="friends-copyRight" *ngIf="isFriendsRoom">
                <span [innerHTML]="friendsBingoCopyRights"></span>
            </div>
            <div
                [ngStyle]="{ background: roomIconslist?.attributes?.jackpotBgColor, color: roomIconslist?.attributes?.jackpotTextColor }"
                class="jackpot jackpot-values"
                [ngClass]="bingoItem.hover ? 'hover' : ''"
                *ngIf="bingoItem.pjps_in_player_currency && bingoItem.pjps_in_player_currency.length > 0 && !bingoItem.isCombinedRoom">
                <p *ngFor="let pjp of bingoItem.pjps_in_player_currency">{{ pjp.value.Cash | vnCurrency }}</p>
            </div>
            <bg-favourite
                class="favorite-icon"
                [RoomId]="bingoItem.id"
                [isFavourite]="bingoItem.isFavourite"
                [favAddClass]="'favorite-added'"
                [favIcon]="roomIconslist.columns.favIcon"
                [favMarkedIcon]="roomIconslist.columns.favMarkedIcon"
                [favRemClass]="'favorite-removed'"
                *ngIf="IsFavouriteEnabled">
            </bg-favourite>
            <div class="timerclass">
                <span
                    bgTimer
                    [timerColor]="roomIconslist?.attributes?.timerConfigTextColorTwo"
                    [timerBgColor]="roomIconslist?.attributes?.timerConfigBgColorTwo"
                    [ngStyle]="{ background: roomIconslist?.attributes?.timerConfigBgColor, color: roomIconslist?.attributes?.timerConfigTextColor }"
                    class="timer"
                    [ngClass]="{ 'insurance-btn': bingoItem.insurance && bingoItem.status?.state_end_time != null }">
                    <i class="theme-clock-solid" *ngIf="!bingoItem.sit_n_go_enabled"></i>
                    <div
                        class="insurance-timerclass"
                        *ngIf="(bingoItem.insurance && bingoItem.status?.state_end_time != null) || bingoItem.sit_n_go_enabled">
                        <div>{{ playingSoonText }}</div>
                    </div>
                    <bg-schedule-timer
                        *ngIf="(!bingoItem.insurance || bingoItem.status?.state_end_time == null) && !bingoItem.sit_n_go_enabled"
                        [timerinput]="bingoItem.status.state_end_time"
                        (timerChange)="onTimeNotification($event)">
                    </bg-schedule-timer>
                </span>
                <span class="room-feature" *ngFor="let item of bingoItem.features">
                    <bg-feature-icon [feature]="item"></bg-feature-icon>
                </span>
            </div>
            <div class="button-container" *ngIf="bingoItem.hover">
                <div class="btn-div btn-sub-div">
                    <!-- <input type="button" class="btn btn-outline-secondary pre-buy-button" (click)="preBuy(bingoItem.id)"
                        value="{{ roomnavlist.columns.roomTabPreBuyButtonText }}"
                        *ngIf="!disableAllPrebuy && IsPreBuyEnabled && bingoItem.prebuy_enabled" [disabled]="
                                                !bingoItem.prebuy_enabled ||
                                                bingoItem.isRoomLocked ||
                                                disablePreBuy ||
                                                bingoItem.insurance ||
                                                (bingoItem.isSuperLink && !bingoItem.prebuy_enabled)
                                            " />

                    <input
                        type="button"
                        class="btn btn-primary btn-block play-now-button"
                        (click)="gameLaunch(bingoItem, $event)"
                        value="{{ roomnavlist.columns.playNowButtonText }}"
                        [disabled]="bingoItem.isRoomLocked || isPlayButtonDisabled" />
                         [customStyle]="styleObj" -->

                    <bg-custombutton
                        (click)="preBuy(bingoItem.id)"
                        [variant]="'outline'"
                        [kind]="'tertiary'"
                        [inverse]="true"
                        [customClass]="
                            'btn-outline-secondary
                        pre-buy-button'
                        "
                        [decoratorClass]="'prebuys-btn'"
                        [disabled]="
                            !bingoItem.prebuy_enabled ||
                            bingoItem.isRoomLocked ||
                            disablePreBuy ||
                            bingoItem.insurance ||
                            (bingoItem.isSuperLink && !bingoItem.prebuy_enabled)
                        "
                        [button_text]="roomnavlist.columns.roomTabPreBuyButtonText"
                        *ngIf="!disableAllPrebuy && IsPreBuyEnabled && bingoItem.prebuy_enabled"
                        >{{ roomnavlist.columns.roomTabPreBuyButtonText }}
                    </bg-custombutton>
                    <bg-custombutton
                        (clickEmit)="gameLaunch(bingoItem, $event)"
                        [variant]="'filled'"
                        [kind]="'primary'"
                        [customClass]="'btn-primary btn-block play-now-button'"
                        [decoratorClass]="'prebuys-btn'"
                        [disabled]="bingoItem.isRoomLocked || isPlayButtonDisabled"
                        [button_text]="roomnavlist.columns.playNowButtonText"
                        >{{ roomnavlist.columns.playNowButtonText }}
                    </bg-custombutton>
                </div>
            </div>
        </div>
        <div class="progress-bar" *ngIf="IsProgressBar && !bingoItem.sit_n_go_enabled">
            <div class="progress-bar__content">
                <div class="progress-bar__bar" [@barAnimation]="getAnimationState()"></div>
            </div>
        </div>
        <div class="price-section" [ngClass]="{ 'superlink-rooms': bingoItem.isSuperLink, 'superlink-image': bingoItem.bgImage }">
            <img alt="" class="superlink-bg" *ngIf="bingoItem.bgImage" src="{{ bingoItem.bgImage }}" />

            <div class="name-section">
                <span *ngIf="!showIconImage" class="room-ball-type" [ngClass]="'bingo-type-' + bingoItem.bingo_type">
                    {{ bingoItem.bingo_type }}</span
                >
                <img *ngIf="showIconImage" [ngClass]="'bingo-type-' + bingoItem.bingo_type" class="icon-image" src="{{ iconImageSrc }}" />
                <!-- <span class="game-name">{{bingoItem.name}}</span> -->
                <span class="game-name" [ngStyle]="{ color: isFabGrabRoom ? roomIconslist?.attributes?.fabgrabTextClr : '' }"
                    >{{ bingoItem.name | uppercase }}
                    <div
                        class="single-entry-count"
                        *ngIf="bingoItem.isCombinedRoom"
                        [ngStyle]="{ color: roomIconslist?.attributes?.singleEntryPointClr }">
                        <span>{{ bingoItem.gameCount }} {{ combinedRoomsCount }}</span>
                    </div>
                </span>
                <!-- <span *ngIf="isFriendsRoom" class="friends-game-name">
                    <img src={{friendsLogoSrc}}>
                </span> -->
                <span *ngFor="let item of bingoItem.features">
                    <span *ngIf="item == 'linked_game'">
                        <i [ngClass]="roomIconslist.columns.linkedIcon"></i>
                    </span>
                </span>
            </div>
            <ul class="room-details" [ngClass]="{ 'combinedroom-details': bingoItem.isCombinedRoom && enableIconReplacement }">
                <li [ngStyle]="{ color: isFabGrabRoom ? roomIconslist?.attributes?.fabgrabTextClr : '' }" *ngIf="!bingoItem.sit_n_go_enabled">
                    <i class="cmn-icon" [ngClass]="roomIconslist.columns.playerIcon"></i>{{ bingoItem.number_of_players }}
                </li>
                <li [ngStyle]="{ color: isFabGrabRoom ? roomIconslist?.attributes?.fabgrabTextClr : '' }">
                    <i
                        class="cmn-icon"
                        [ngClass]="roomIconslist.columns.cardIcon"
                        *ngIf="!bingoItem.isCombinedRoom || (bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrice)"></i>
                    <span *ngIf="!bingoItem.isCombinedRoom">{{ bingoItem.card_price_in_player_currency.Cash | vnCurrency }}</span>
                    <bg-feature-icon
                        class="combined-room-icon"
                        *ngIf="bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrice && !enableIconReplacement"
                        [feature]="bingoItem.combinedRoomFeature"></bg-feature-icon>
                    <span
                        class="combined-text"
                        *ngIf="bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrice && enableIconReplacement"
                        [ngClass]="{ sng: bingoItem.sit_n_go_enabled }"
                        >{{ combinedPriceText }}</span
                    >
                </li>
                <li [ngStyle]="{ color: isFabGrabRoom ? roomIconslist?.attributes?.fabgrabTextClr : '' }" *ngIf="!bingoItem.sit_n_go_enabled">
                    <i
                        class="cmn-icon"
                        [ngClass]="roomIconslist.columns.jackpotIcon"
                        *ngIf="!bingoItem.isCombinedRoom || (bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrize)"></i
                    ><span *ngIf="!isPhysicalPrize && !isBonusPrize && !bingoItem.isCombinedRoom">{{
                        bingoItem.pot_in_player_currency.Cash | bgFloatTruncate: bingoItem.number_of_players : dynaconParams
                    }}</span>
                    <span *ngIf="isPhysicalPrize && !isBonusPrize && !bingoItem.isCombinedRoom">{{ physicalPrizeText }}</span>
                    <span *ngIf="isBonusPrize && !bingoItem.isCombinedRoom">{{ bonusPrizeText }}</span>
                    <bg-feature-icon
                        class="combined-room-icon"
                        *ngIf="bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrize && !enableIconReplacement"
                        [feature]="bingoItem.combinedRoomFeature"></bg-feature-icon>
                    <span class="combined-text" *ngIf="bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrize && enableIconReplacement">{{
                        combinedPrizeText
                    }}</span>
                </li>
            </ul>
        </div>
    </div>
</div>

<!--Mobile View -->

<div
    *ngIf="(isMobileView || (ingame_rooms_status && isTabletView && innerWidth < 800)) && bingoItem.displayRoom"
    class="bingolobby-mobile-view"
    [ngClass]="renderRoomClass()"
    [style.--RoomGreyoutColor]="isGrayedOut ? roomIconslist?.attributes?.roomGreyoutColor : '#000'"
    [ngStyle]="{ 'background-color': isFabGrabRoom ? roomIconslist?.attributes?.fabgrabBgClr : '' }">
    <img alt="" class="superlink-bg" *ngIf="bingoItem.bgImage" src="{{ bingoItem.bgImage }}" />
    <div
        *ngIf="bingoItem.isRoomLocked || bingoItem.lockRoomInLogoutStage || (bingoItem.displayPrivateRoom && bingoItem.privateRoom)"
        class="room-lacked">
        <i class="theme-locked-i"></i><span>{{ roomLockedText }}</span>
        <div (click)="roomLockedMoreInfo(bingoItem)">
            <div class="btn btn-light btn-block moreinf">{{ moreInfoText }}</div>
        </div>
    </div>
    <div class="mobile-roomtile-container">
        <div
            class="mm-spent"
            *ngIf="bingoItem.optimoveLabel && bingoItem.enableStickerForRoom"
            [style.--StickerBgColor]="roomIconslist?.attributes?.roomTopRibbonBgClr ? roomIconslist?.attributes?.roomTopRibbonBgClr : '#ff3333'"
            [style.--StickerTextColor]="roomIconslist?.attributes?.roomTopRibbonClr ? roomIconslist?.attributes?.roomTopRibbonClr : '#fff'">
            <div class="mm-spentinnerclass"><i class="theme-bingo-star"></i>{{ bingoItem.optimoveLabel }}</div>
        </div>
        <div
            class="mm-spent"
            *ngIf="bingoItem.isPreferredRoom && !bingoItem.optimoveLabel"
            [style.--StickerBgColor]="roomIconslist?.attributes?.roomTopRibbonBgClr ? roomIconslist?.attributes?.roomTopRibbonBgClr : '#ff3333'"
            [style.--StickerTextColor]="roomIconslist?.attributes?.roomTopRibbonClr ? roomIconslist?.attributes?.roomTopRibbonClr : '#fff'">
            <div class="mm-spentinnerclass"><i class="theme-bingo-star"></i>{{ roomIconslist.columns.preferredBingoRoom }}</div>
        </div>
        <div class="mobile-game-details-container">
            <div class="mobile-thumbnail-container">
                <img alt="" src="{{ bingoItem.roomImage ? bingoItem.roomImage : thumbImageSrc }}" />
                <div class="friends-copyRight" *ngIf="isFriendsRoom">
                    <span [innerHTML]="friendsBingoCopyRights"></span>
                </div>
                <div
                    [ngStyle]="{ background: roomIconslist?.attributes?.jackpotBgColor, color: roomIconslist?.attributes?.jackpotTextColor }"
                    class="jackpot jackpot-values"
                    *ngIf="bingoItem.pjps_in_player_currency && bingoItem.pjps_in_player_currency.length > 0 && !bingoItem.isCombinedRoom">
                    <p *ngFor="let pjp of bingoItem.pjps_in_player_currency">{{ pjp.value.Cash | vnCurrency }}</p>
                </div>
                <bg-favourite
                    class="favorite-icon"
                    [RoomId]="bingoItem.id"
                    [isFavourite]="bingoItem.isFavourite"
                    [favAddClass]="'favorite-added'"
                    [favIcon]="roomIconslist.columns.favIcon"
                    [favMarkedIcon]="roomIconslist.columns.favMarkedIcon"
                    [favRemClass]="'favorite-removed'"
                    *ngIf="IsFavouriteEnabled">
                </bg-favourite>

                <span
                    bgTimer
                    [timerColor]="roomIconslist?.attributes?.timerConfigTextColorTwo"
                    [timerBgColor]="roomIconslist?.attributes?.timerConfigBgColorTwo"
                    [ngStyle]="{ background: roomIconslist?.attributes?.timerConfigBgColor, color: roomIconslist?.attributes?.timerConfigTextColor }"
                    class="timer"
                    [ngClass]="{ 'insurance-btn': bingoItem.insurance }">
                    <i class="theme-clock-solid" *ngIf="!bingoItem.sit_n_go_enabled"></i>
                    <div
                        class="insurance-timerclass"
                        *ngIf="(bingoItem.insurance && bingoItem.status?.state_end_time != null) || bingoItem.sit_n_go_enabled">
                        <div>{{ playingSoonText }}</div>
                    </div>
                    <bg-schedule-timer
                        *ngIf="(!bingoItem.insurance || bingoItem.status?.state_end_time == null) && !bingoItem.sit_n_go_enabled"
                        [timerinput]="bingoItem.status.state_end_time"
                        (timerChange)="onTimeNotification($event)">
                    </bg-schedule-timer>
                </span>
                <div class="feature-icon">
                    <span class="mobile-room-feature" *ngFor="let item of bingoItem.features">
                        <bg-feature-icon [feature]="item"></bg-feature-icon>
                    </span>
                </div>
            </div>

            <div class="mobile-game-details" [ngClass]="{ 'superlink-rooms': bingoItem.isSuperLink, 'superlink-image': bingoItem.bgImage }">
                <div class="mobile-game-name-cntr" [ngClass]="{ 'single-entry': bingoItem.isCombinedRoom }">
                    <span *ngIf="!showIconImage" class="room-ball-type" [ngClass]="'bingo-type-' + bingoItem.bingo_type">
                        {{ bingoItem.bingo_type }}</span
                    >
                    <img alt="" *ngIf="showIconImage" [ngClass]="'bingo-type-' + bingoItem.bingo_type" class="icon-image" src="{{ iconImageSrc }}" />
                    <span class="game-name">
                        {{ bingoItem.name | uppercase }}
                        <div
                            class="single-entry-count"
                            *ngIf="bingoItem.isCombinedRoom"
                            [ngStyle]="{ color: roomIconslist?.attributes?.singleEntryPointClr }">
                            <span>{{ bingoItem.gameCount }} {{ combinedRoomsCount }}</span>
                        </div>
                    </span>
                    <span class="linked-icon" *ngFor="let item of bingoItem.features">
                        <i *ngIf="item == 'linked_game'" [ngClass]="roomIconslist.columns.linkedIcon"></i>
                    </span>
                </div>
                <div
                    class="mobile-game-price-details-container"
                    [ngClass]="{ 'combined-container': bingoItem.isCombinedRoom && enableIconReplacement }">
                    <div
                        class="mobile-players"
                        [ngClass]="{ 'combined-player': bingoItem.isCombinedRoom && enableIconReplacement }"
                        *ngIf="!bingoItem.sit_n_go_enabled">
                        <span
                            ><i
                                [ngClass]="roomIconslist.columns.playerIcon"
                                class="cmn-icon"
                                [ngStyle]="{ color: isFabGrabRoom ? roomIconslist?.attributes?.fabgrabTextClr : '' }"></i> </span
                        ><span class="mobile-player-count" [ngStyle]="{ color: isFabGrabRoom ? roomIconslist?.attributes?.fabgrabTextClr : '' }">{{
                            bingoItem.number_of_players
                        }}</span>
                    </div>
                    <div
                        class="mobile-price"
                        [ngClass]="{
                            'combined-price': bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrice && enableIconReplacement,
                            'battle-royale': bingoItem.isCombinedRoom && !bingoItem.combinedRoomHasPrice
                        }">
                        <span
                            ><i
                                [ngClass]="roomIconslist.columns.cardIcon"
                                *ngIf="!bingoItem.isCombinedRoom || (bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrice)"
                                class="cmn-icon"></i>
                        </span>
                        <span
                            *ngIf="!bingoItem.isCombinedRoom"
                            class="mobile-card-price"
                            [ngStyle]="{ color: isFabGrabRoom ? roomIconslist?.attributes?.fabgrabTextClr : '' }"
                            >{{ bingoItem.card_price_in_player_currency.Cash | vnCurrency }}</span
                        >
                        <span
                            class="mobile-card-price"
                            [ngStyle]="{ color: isFabGrabRoom ? roomIconslist?.attributes?.fabgrabTextClr : '' }"
                            *ngIf="bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrice && !enableIconReplacement">
                            <bg-feature-icon class="combined-room-icon" [feature]="bingoItem.combinedRoomFeature"></bg-feature-icon>
                        </span>
                        <span
                            class="mobile-card-price combined-mob-text"
                            *ngIf="bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrice && enableIconReplacement"
                            [ngStyle]="{ color: isFabGrabRoom ? roomIconslist?.attributes?.fabgrabTextClr : '' }"
                            [ngClass]="{ sng: bingoItem.sit_n_go_enabled }"
                            >{{ combinedPriceText }}</span
                        >
                    </div>

                    <div
                        class="mobile-prize"
                        [ngClass]="{
                            'for-merch-prize': isPhysicalPrize,
                            'for-bonus-prize': isBonusPrize,
                            'combined-prize': bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrize && enableIconReplacement
                        }"
                        *ngIf="!bingoItem.sit_n_go_enabled">
                        <span>
                            <i
                                [ngClass]="roomIconslist.columns.jackpotIcon"
                                *ngIf="!bingoItem.isCombinedRoom || (bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrize)"
                                class="cmn-icon"></i>
                        </span>
                        <span
                            class="mobile-player-pot"
                            [ngStyle]="{ color: isFabGrabRoom ? roomIconslist?.attributes?.fabgrabTextClr : '' }"
                            *ngIf="!isPhysicalPrize && !isBonusPrize && !bingoItem.isCombinedRoom">
                            {{ bingoItem.pot_in_player_currency.Cash | bgFloatTruncate: bingoItem.number_of_players : dynaconParams }}</span
                        >
                        <span
                            class="mobile-player-pot merch"
                            [ngStyle]="{ color: isFabGrabRoom ? roomIconslist?.attributes?.fabgrabTextClr : '' }"
                            *ngIf="isPhysicalPrize && !isBonusPrize && !bingoItem.isCombinedRoom">
                            {{ physicalPrizeText }}</span
                        >
                        <span
                            class="mobile-player-pot bonusPrize"
                            [ngStyle]="{ color: isFabGrabRoom ? roomIconslist?.attributes?.fabgrabTextClr : '' }"
                            *ngIf="isBonusPrize && !bingoItem.isCombinedRoom">
                            {{ bonusPrizeText }}</span
                        >
                        <span
                            class="mobile-player-pot"
                            [ngStyle]="{ color: isFabGrabRoom ? roomIconslist?.attributes?.fabgrabTextClr : '' }"
                            *ngIf="bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrize && !enableIconReplacement">
                            <bg-feature-icon class="combined-room-icon" [feature]="bingoItem.combinedRoomFeature"></bg-feature-icon>
                        </span>
                        <span
                            class="mobile-player-pot combined-mob-text"
                            [ngStyle]="{ color: isFabGrabRoom ? roomIconslist?.attributes?.fabgrabTextClr : '' }"
                            *ngIf="bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrize && enableIconReplacement"
                            >{{ combinedPrizeText }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="game-play-container" [ngClass]="{ 'combined-game-play': bingoItem.isCombinedRoom && enableIconReplacement }">
            <div class="mobile-prebuy-btn">
                <!-- <input
                    type="button"
                    class="pre-buy-button btn btn-outline-secondary btn-block"
                    (click)="preBuy(bingoItem.id)"
                    value="{{ roomnavlist.columns.preBuyButtonText }}"
                    *ngIf="!disableAllPrebuy && IsPreBuyEnabled && bingoItem.prebuy_enabled"
                    [disabled]="
                        !bingoItem.prebuy_enabled ||
                        bingoItem.isRoomLocked ||
                        disablePreBuy ||
                        bingoItem.insurance ||
                        (bingoItem.isSuperLink && !bingoItem.prebuy_enabled)
                    " /> -->

                <bg-custombutton
                    (click)="preBuy(bingoItem.id)"
                    [variant]="'outline'"
                    [kind]="'tertiary'"
                    [size]="'small'"
                    [decoratorClass]="'prebuy-btn-mobile'"
                    [customClass]="
                        'btn-outline-secondary
                        pre-buy-button'
                    "
                    [disabled]="
                        !bingoItem.prebuy_enabled ||
                        bingoItem.isRoomLocked ||
                        disablePreBuy ||
                        bingoItem.insurance ||
                        (bingoItem.isSuperLink && !bingoItem.prebuy_enabled)
                    "
                    [button_text]="roomnavlist.columns.roomTabPreBuyButtonText"
                    *ngIf="!disableAllPrebuy && IsPreBuyEnabled && bingoItem.prebuy_enabled"
                    >{{ roomnavlist.columns.roomTabPreBuyButtonText }}
                </bg-custombutton>
            </div>

            <div class="mobile-game-play-btn">
                <!-- <input
                    type="button"
                    class="play-now-button btn btn-primary btn-block"
                    (click)="gameLaunch(bingoItem, $event)"
                    value="{{ roomnavlist.columns.playNowButtonText }}"
                    [disabled]="bingoItem.isRoomLocked || isPlayButtonDisabled" /> -->

                <bg-custombutton
                    (clickEmit)="gameLaunch(bingoItem, $event)"
                    [variant]="'filled'"
                    [kind]="'primary'"
                    [decoratorClass]="'prebuy-btn-mobile'"
                    [size]="'small'"
                    [customClass]="'btn-primary btn-block play-now-button'"
                    [disabled]="bingoItem.isRoomLocked || isPlayButtonDisabled"
                    [button_text]="roomnavlist.columns.playNowButtonText"
                    >{{ roomnavlist.columns.playNowButtonText }}
                </bg-custombutton>
            </div>
        </div>
    </div>
    <div class="progress-bar" *ngIf="IsProgressBar && !bingoItem.sit_n_go_enabled">
        <div class="progress-bar__content">
            <div class="progress-bar__bar" [@barAnimation]="getAnimationState()"></div>
        </div>
    </div>
</div>
