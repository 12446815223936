import { Component, Input, OnInit } from '@angular/core';

import { TimerService } from '@frontend/vanilla/core';

@Component({
    selector: 'bg-clientgametime',
    templateUrl: 'clientgametime.component.html',
    //template: `<span>{{time | date: format }}</span>`,

    styleUrls: ['./clientgametime.component.css'],
})
export class ClientgametimeComponent implements OnInit {
    time: any;
    initialTime: number;
    startTime: number;
    @Input() format = 'mediumTime';
    @Input() set initial(value: number) {
        const initialDate = value == null ? Date.now() : new Date(value).getTime();
        this.initialTime = initialDate + this.getTimezoneOffset();
    }
    constructor(private timerService: TimerService) {}
    ngOnInit(): void {
        this.startTime = Date.now() + this.getTimezoneOffset();
        this.setTime();
        this.timerService.setIntervalOutsideAngularZone(() => {
            this.setTime();
        }, 1000);
    }
    private setTime() {
        const diff = Date.now() + this.getTimezoneOffset() - this.startTime;
        this.time = new Date(this.initialTime + diff);
    }
    private getTimezoneOffset() {
        return new Date().getTimezoneOffset() * 60000;
    }
}
