import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { BingoConfigProviderService, BingoHelperService } from '@frontend/bingo/core';
import { NavigationService, Page, ProductHomepagesConfig } from '@frontend/vanilla/core';

import { RootServicesModule } from '../root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class BingoTournamentsGuardService implements CanActivate {
    bingoTournamentsConfig: any;
    enableTournaments: any;
    constructor(
        private productHomepagesConfig: ProductHomepagesConfig,
        private configProviderService: BingoConfigProviderService,
        private navigator: NavigationService,
        private page: Page,
        private bingoHelperService: BingoHelperService,
    ) {}

    canActivate(): boolean {
        this.bingoTournamentsConfig = this.configProviderService.provideBingoTournamentsClientConfig();
        this.enableTournaments = this.bingoHelperService.getConfigBasedOnInvokerProduct(this.bingoTournamentsConfig.enableBingoTournaments);
        if (!this.enableTournaments) {
            let apiUrl: string = '';
            apiUrl = this.productHomepagesConfig.bingo + '/bingo/rooms';
            this.navigator.goTo(apiUrl);
            return false;
        }
        return true;
    }
}
