<!-- <div *ngFor="let seobox of seoboxes">
    <div class="toggle-element">
        <div>
            <vn-page-matrix [path]="'CozyBingo/SEOBoxes/' + seobox.parent + '/' + seobox.id"></vn-page-matrix>
        </div>
    </div>
</div> -->
<div *ngIf="seoboxes?.length > 0">
    <div *ngFor="let seobox of seoboxes" class="seo-main-header">
        <div class="toggle-element">
            <div class="content seo-blocks">
                <vn-page-matrix [path]="'CozyBingo/SEOBoxes/' + seobox.parent + '/' + seobox.id"></vn-page-matrix>
            </div>
        </div>
    </div>
</div>
