import { Injectable } from '@angular/core';

import { UserService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BingoConfigProviderService } from '../../bingo-config-provider/services/bingo-config-provider.service';
import { BingoPlatformApiService } from '../../bingo-platform-api/services/bingo-platform-api.service';
import { FeaturesClientConfig } from '../../client-config/features-client.config';

@Injectable({
    providedIn: 'root',
})
export class FreeBingoTicketService {
    freeBingoTicketsDataSubject = new BehaviorSubject<any>(null);
    freeBingoTicketsDataObservable: Observable<any> = this.freeBingoTicketsDataSubject.asObservable();
    constructor(
        private api: BingoPlatformApiService,
        private user: UserService,
        private configProviderService: BingoConfigProviderService,
    ) {}
    setUserFreeBingoTickets() {
        const bingoFeature: FeaturesClientConfig = this.configProviderService.provideFeaturesConfig();
        if (this.user.isAuthenticated && bingoFeature.enableFreeBingoTickets) {
            this.api.get('PlayerFreeBingoTickets', {}, { withCredentials: true }).subscribe((response) => {
                if (response && response.bingoTicketDtls) {
                    this.freeBingoTicketsDataSubject.next(response.bingoTicketDtls);
                }
            });
        }
    }
}
