import { Injectable } from '@angular/core';

import { CasinoPlatformLoaderService } from '@casinocore/loader';
import {
    BingoConfigProviderService,
    BingoNpmManagerService,
    BingoNpmParams,
    FeaturesClientConfig,
    ModularCasinoClientConfig,
    OnAppInitHandler,
} from '@frontend/bingo/core';
import {
    AnimationControl,
    AnimationControlService,
    HtmlNode,
    NativeAppService,
    OnAppInit,
    ProductBootstrapper,
    UserLoginEvent,
    UserLogoutEvent,
    UserService,
} from '@frontend/vanilla/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { GamesService } from '../casino-games-lobby/casinogames.service';
import { GLobalSearchService } from '../shared/services/globalsearch.service';
import { ScreenNameService } from '../shared/services/screen-name.service';
import { TagManagerRendererService } from '../shared/services/tagmanager-renderer.service';

@Injectable()
export class HtmlBootstrapService implements ProductBootstrapper, OnAppInit {
    fromScreen = 'default';
    private deactivate = new Subject<void>();
    private featureConfig: FeaturesClientConfig;
    private modularCasino: ModularCasinoClientConfig;

    constructor(
        private nativeApp: NativeAppService,
        private htmlNode: HtmlNode,
        private animationControlService: AnimationControlService,
        private gamesService: GamesService,
        private user: UserService,
        private casinoPlatformLoaderService: CasinoPlatformLoaderService,
        private globalSearchService: GLobalSearchService,
        private tagManagerRenderer: TagManagerRendererService,
        private screenNameService: ScreenNameService,
        private onAppInitHandler: OnAppInitHandler,
        private configProviderService: BingoConfigProviderService,
        private bingoNpmManagerService: BingoNpmManagerService,
    ) {}

    onLoad() {}

    onActivate() {}

    onDeactivate(): void | Promise<void> {
        this.deactivate.next();
        this.deactivate.complete();
    }

    onAppInit() {
        if (localStorage.getItem('config')) {
            localStorage.removeItem('config');
        }
        const bingoNpmParams: BingoNpmParams = new BingoNpmParams();
        bingoNpmParams.isDll = true;
        this.bingoNpmManagerService.intiateBingoLobby(bingoNpmParams);
        this.onAppInitHandler.execute();
        if (window?.location?.href) {
            const url = window.location.href;
            if (url.includes('bingowidget/cozybingo')) {
                this.fromScreen = 'bingowidget';
            } else if (url.includes('.box=1') && url.includes('ingame=true')) {
                if (url.includes('/search')) {
                    this.fromScreen = 'search';
                } else if (url.includes('/schedule')) {
                    this.fromScreen = 'schedule';
                } else if (url.includes('/casinosidepane')) {
                    this.fromScreen = 'casinosidepane';
                } else if (url.includes('/sessionpopup')) {
                    this.fromScreen = 'sessionpopup';
                }
            }
        }

        this.htmlNode.setCssClass('native', this.nativeApp.isNativeApp);
        // FIX menu animation issue by disabling it
        this.animationControlService.addCondition((element) => {
            if (element.id === 'main-menu') {
                return AnimationControl.Disable;
            }
            return;
        });

        this.featureConfig = this.configProviderService.provideFeaturesConfig();
        this.modularCasino = this.configProviderService.provideModularCasinoClientConfig();
        this.loadRequiredCallsByCondition();
        this.user.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            this.screenNameService.getScreenChatName();
            this.fetchCasinoFeedAndStroreToSession();
        });
        this.user.events.pipe(filter((e) => e instanceof UserLogoutEvent)).subscribe(() => {
            this.screenNameService.setDefaultValuesScreenName();
        });
    }

    fetchCasinoFeedAndStroreToSession() {
        if (this.fromScreen === 'default' || this.fromScreen === 'casinosidepane' || this.fromScreen === 'search') {
            this.gamesService.allCategories().subscribe((response: any) => {
                localStorage.setItem('CasinoLmtFeed', JSON.stringify(response));
            });
        }
    }

    loadRequiredCallsByCondition() {
        this.fetchCasinoFeedAndStroreToSession();
        if (this.featureConfig?.disableUnnecessaryCalls) {
            if (this.fromScreen === 'default') {
                this.loadDefaultCalls();
            }
        } else {
            this.loadDefaultCalls();
        }
    }

    loadDefaultCalls() {
        this.tagManagerRenderer.renderGTMTag();
        this.globalSearchService.init();
        if (this.modularCasino.isEnabled) {
            const params = {
                lobbyTypeMap: this.modularCasino.lobbyTypeMap,
                disableProductLobbyDataFetch: this.modularCasino.disableProductLobbyDataFetch,
                loadlazystyles: true,
                disableSlotracesCallsOnBootstrap: true,
            };
            this.casinoPlatformLoaderService.loadCasinoPlatformModule(params);
        }
    }
}
