import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { RootServicesModule } from '../shared/root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class FilterAndSortingService {
    favouriteInfo: any;
    appliedFilterList = new Subject<any>();
    filteredResultList = new Subject<any>();
    selectedSortOption = new Subject<any>();

    constructor() {}

    filterItemsByCatagory(catagoryList: any, allItemsList: any): any {
        allItemsList = allItemsList?.filter((item: any) => {
            return !item?.isHidden;
        });
        let filterResult: any = [];
        for (let i = 0; i < catagoryList?.length; i++) {
            for (let j = 0; j < allItemsList.length; j++) {
                if (catagoryList[i]?.Type == 'ALL') return allItemsList;
                if (allItemsList[j]?.bingo_type == catagoryList[i]?.category || allItemsList[j]?.game_tags == catagoryList[i]?.category)
                    filterResult.push(allItemsList[j]);
            }
        }
        if (catagoryList?.length == 0) {
            filterResult = allItemsList;
        }
        return (filterResult = Array.from(new Set(filterResult)));
    }
}
