import { Component, Input, OnChanges } from '@angular/core';

import { SeoboxesService } from './seoboxes.service';

@Component({
    selector: 'bg-seoboxes',
    templateUrl: 'seoboxes.component.html',
})
export class SeoboxesComponent implements OnChanges {
    @Input() categoryName: string;
    @Input() timestamp: number;

    id: number = 1;
    seoboxes: any = [];

    constructor(private SeoService: SeoboxesService) {}

    ngOnChanges(): void {
        this.SeoService.getSeoboxes(this.categoryName).subscribe((response: any) => {
            this.seoboxes = response.content;
        });
    }
}
