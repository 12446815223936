import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CasinoPlatformLoaderService } from '@casinocore/loader';
import {
    BingoConfigProviderService,
    BingoHelperService,
    EventEmitService,
    FeaturesClientConfig,
    ModularCasinoClientConfig,
    ScheduleService,
} from '@frontend/bingo/core';
import {
    AppInfoConfig,
    CookieService,
    DeviceService,
    MenuContentItem,
    NativeAppService,
    NavigationService,
    ProductHomepagesConfig,
    TrackingService,
    UserLoginEvent,
    UserService,
} from '@frontend/vanilla/core';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { FilterAndSortingService } from '../filter-and-sorting/filter-and-sorting.service';
import { Games } from '../models/games';
import { NavService } from './navigation.service';

@Component({
    selector: 'bg-navigation',
    templateUrl: 'navigation.component.html',
    styleUrls: ['../../../../themepark/themes/whitelabel/components/nav-main/styles.scss'],
})
export class NavigationComponent implements OnInit, AfterViewInit, OnDestroy {
    //@Output() nvaigationChange = new EventEmitter<string>();
    timeMilliseconds: any;
    IsFilterSortEnabled: boolean;
    isMobile: boolean;
    navigation_data: any;
    isRoomsActive: boolean;
    isScheduleActive: boolean;
    roomlist: any = [];
    datelist: any = [];
    timelist: any = [];
    gameData: Games[] = [];
    favouriteInfo: any = [];
    facetItems: any = [];
    appliedFilterList: any = [];
    filteredResultList: any = [];
    Global: any;
    filterButtonClicked: boolean = false;
    currentRoute: string = 'bingo';
    innerWidth: any;
    isInGameClient: boolean = false;
    isUserisAuthenticated: boolean = false;
    IsRoomTabEnabled: boolean;
    IsFavouriteEnabled: boolean;
    selectedSortOption: any;
    _fiteringAndSortingText: string;
    _roomsText: string;
    _myFavouritesText: string;
    isFilterEnabled: boolean = true;
    _showResultsMobileText: string;
    isProgressiveLobby: boolean;
    hideFavPopupStatus: boolean = true;
    bingoGameMappings: any;
    schedule_route_status: boolean;
    bingoNavigationData: any;
    selectedTopItem: any;
    isNavigationV6Design: boolean;
    displayTimerinNavinClient: boolean;
    casinoLobbyService: any;
    lobbyLoadSubscription: Subscription;
    casinoModuleLoadSubscription: Subscription;
    casinoNavItems: any = [];
    routeSubscription: Subscription;
    value: any;
    enableCasinoNavCategories: boolean;
    locationChangeEvent: Subscription;
    @ViewChild('navContainer', { static: false }) navContainer: ElementRef<HTMLDivElement>;
    modularCasinoConfig: ModularCasinoClientConfig;
    featureConfig: FeaturesClientConfig;
    bingoTournamentsConfig: any;
    clientConfig: any;
    lastRoute: any;
    alreadyHighlighted: string = '';

    constructor(
        private filterAndSortingService: FilterAndSortingService,
        private router: Router,
        private user: UserService,
        private activeroute: ActivatedRoute,
        private eventService: EventEmitService,
        private deviceService: DeviceService,
        private scheduleService: ScheduleService,
        private casinoLoader: CasinoPlatformLoaderService,
        private navigation: NavigationService,
        private productHomepagesConfig: ProductHomepagesConfig,
        private appInfoConfig: AppInfoConfig,
        private navigator: NavService,
        private nativeAppService: NativeAppService,
        private configProviderService: BingoConfigProviderService,
        private cookie: CookieService,
        private bingoHelperService: BingoHelperService,
        private tracking: TrackingService,
        //private menuActionsService: MenuActionsService
    ) {
        this.modularCasinoConfig = this.configProviderService.provideModularCasinoClientConfig();
        this.bingoTournamentsConfig = this.configProviderService.provideBingoTournamentsClientConfig();
        this.featureConfig = this.configProviderService.provideFeaturesConfig();
        this.isProgressiveLobby = true;
    }

    ngOnInit() {
        this.clientConfig = this.activeroute.snapshot.data['config'];
        this.Global = this.clientConfig?.global;
        this.eventService.getEventObservable().subscribe((event) => {
            if (event.navigateToUrl) {
                this.navigateToUrl(event.navigateToUrl);
            }
            if (event == 'dataLoaded') {
                this.isProgressiveLobby = false;
            }
            if (event == 'scheduleDataLoaded') {
                this.isProgressiveLobby = false;
                this.currentRoute = 'schedule';
            }
        });
        this.bingoNavigationData = this.clientConfig?.navigationLayoutConfig?.children || [];
        this.navigation.locationChange.subscribe((value: any) => {
            if (value) {
                this.highlightCategoryItem();
            }
        });
        const iframe_ingame_status = this.activeroute.snapshot.queryParamMap.get('ingame');
        this.enableCasinoNavCategories = this.modularCasinoConfig.enableCasinoNavCategories;
        const enableCasinoNavCategoriesInGameClient: boolean = this.modularCasinoConfig.enableCasinoNavCategoriesInGameClient;
        if (this.enableCasinoNavCategories && iframe_ingame_status && !enableCasinoNavCategoriesInGameClient) {
            this.enableCasinoNavCategories = false;
        }
        if (this.enableCasinoNavCategories) {
            this.casinoModuleLoadSubscription = this.casinoLoader.casinoModuleLoadedObservable.subscribe((isModuleLoaded: boolean) => {
                if (isModuleLoaded) {
                    this.getNavCategoriesFromCasino();
                    if (this.casinoModuleLoadSubscription) {
                        this.casinoModuleLoadSubscription.unsubscribe();
                    }
                }
            });
        }
        this.highlightCategoryItem();
        this.timeMilliseconds = this.featureConfig.timeMilliseconds;
        this.displayTimerinNavinClient = this.featureConfig.displayTimerInClient;
        this.bingoNavigationData = this.clientConfig?.navigationLayoutConfig?.children || [];
        this.appendDomainFlagToBingoNavItems();
        this.IsFilterSortEnabled = this.featureConfig.isFilterSortEnabled;
        this.isNavigationV6Design = this.featureConfig.enableV6Navigation;
        if (this.currentRoute === undefined) this.currentRoute = '';
        this.locationChangeEvent = this.navigation.locationChange.subscribe((value: any) => {
            if (value) {
                this.highlightNavItem();
                // this.navigator.scrollToDefault(this.navContainer);
                if (this.selectedTopItem) {
                    this.navigator.navContainerScroll(this.navContainer, this.selectedTopItem);
                }
            }
        });
        this.highlightNavItem();
        this.bingoGameMappings = this.clientConfig?.pageConfig?.value['bingoGameMappings'];
        if (this.activeroute.snapshot.queryParamMap.get('hideHeader') == 'true') {
            this.isInGameClient = true;
        }
        this.scheduleService.getGameData((response: any) => {
            if (response) {
                this.gameData = response?.result?.games_list;
                this.navigation_data = response['navigationItems'];
                if (this.user.isAuthenticated && response.result.favouriteRooms) {
                    this.favouriteInfo = response.result.favouriteRooms;
                }
                if (this.gameData != null && this.gameData != undefined) {
                    this.gameData?.forEach((i: any) => {
                        i.isFavourite = this.favouriteInfo.indexOf(i.id) >= 0;
                    });
                }
            }
        });
        const indexOfAll = this.appliedFilterList.findIndex((x: any) => x.category == 'ALL');
        if (indexOfAll != 0) {
            this.facetItems = this.appliedFilterList;
        } else {
            this.facetItems = [];
        }
        //    this.facetItems = this.appliedFilterList;
        this._fiteringAndSortingText = this.Global.messages['filterAndSorting'];
        this._showResultsMobileText = this.Global.messages['showResultsMobile'];
        this.isUserisAuthenticated = this.user.isAuthenticated;
        this.IsRoomTabEnabled = this.featureConfig.isRoomTabEnabled;
        this.IsFavouriteEnabled = this.featureConfig.isFavouriteEnabled;
        this._myFavouritesText = this.Global.messages['myFavourites'];
        this._roomsText = this.Global.messages['rooms'];
        this.isFilterEnabled = this.user.isAuthenticated;
        if (this.router.url.endsWith('myfavourites')) {
            this.isFilterEnabled = false;
        }
        this.isMobile = this.deviceService.isMobilePhone;
        this.user.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            this.isUserisAuthenticated = true;
            this.isFilterEnabled = true;
        });
        if (iframe_ingame_status !== null && iframe_ingame_status !== undefined) {
            if (iframe_ingame_status) {
                this.schedule_route_status = true;
                document.getElementById('main-content')!.classList.add('custom-nav-model');
            }
        }
    }

    appendDomainFlagToBingoNavItems() {
        this.bingoNavigationData?.forEach((data: any) => {
            data.isEnabled = true;
            data.isBingoDomain = this.checkBingoDomain(data.url);
            data.pathName = new URL(data.url).pathname;
            if (data.name.toLowerCase() == this.bingoTournamentsConfig.btSitecoreItemText) {
                if (!this.bingoHelperService.getConfigBasedOnInvokerProduct(this.bingoTournamentsConfig.enableBingoTournaments)) {
                    data.isEnabled = false;
                }
            }
        });
    }

    checkBingoDomain(url: any) {
        const bingoDomain = this.productHomepagesConfig.bingo;
        return url?.includes(bingoDomain);
    }

    getNavCategoriesFromCasino() {
        this.casinoLobbyService = this.casinoLoader.getCasinoLobbyService();
        const lobbyType = this.modularCasinoConfig.navigationLobbyType;
        this.lobbyLoadSubscription = this.casinoLobbyService.lobbyLoadedObservable.subscribe((response: any) => {
            if (
                response &&
                response.lobbyTypeMaps &&
                response.lobbyTypeMaps.size > 0 &&
                response.lobbyTypeMaps.get(lobbyType) &&
                response.lobbyTypeMaps.get(lobbyType).categoriesMap &&
                response.lobbyTypeMaps.get(lobbyType).categoriesMap.size > 0
            ) {
                let casinoCategories = [];
                let navCategories = [];
                this.casinoNavItems = [];
                casinoCategories = this.casinoLobbyService.getNavCategories(lobbyType);
                navCategories = casinoCategories.filter((navItem: any) => {
                    return (
                        navItem.isNavigationCategory &&
                        navItem.categoryid != 'recentgames' &&
                        navItem.categoryid != 'favouritegames' &&
                        ((navItem.gamelist && navItem.gamelist.length > 0) || (navItem.lmtSubCategories && navItem.lmtSubCategories.length > 0))
                    );
                });
                if (navCategories && navCategories.length > 0) {
                    navCategories.forEach((navItem: any) => {
                        const navItems = {
                            text: navItem.categoryname,
                            url: 'games/' + navItem.categoryid,
                            externalUrl: navItem.url,
                            isBingoDomain: this.checkBingoDomain(navItem.url),
                            //url: 'https://dev.bingo.borgataonline.com' + '/' + this.page.lang + '/bingo/games/' + navItem.route,
                            name: navItem.categoryid,
                            icon: navItem.sitecoreData?.attributes?.icon ? navItem.sitecoreData.attributes.icon : '',
                        };
                        this.casinoNavItems.push(navItems);
                    });
                }
            }
        });
    }

    highlightNavItem() {
        const checkMoreInfoUrl = this.router.url.split('/');
        checkMoreInfoUrl.splice(-2);
        this.currentRoute = this.router.url.split('?')[0].split('/')[4];
        this.bingoNavigationData?.forEach((item: any) => {
            if (!this.currentRoute) {
                const lastRoute = this.router.url.split('?')[0].split('/')[3];
                if (lastRoute == 'schedule' || lastRoute == 'rooms') {
                    this.selectedTopItem = 'rooms';
                } else if (
                    (item && item.url == window.location.origin + window.location.pathname) ||
                    (location.href.includes('games-info') && item.url == window.location.origin + checkMoreInfoUrl.join('/'))
                ) {
                    this.selectedTopItem = item.name;
                }
            } else if (
                (item && item.url == window.location.origin + window.location.pathname) ||
                (location.href.includes('games-info') && item.url == window.location.origin + checkMoreInfoUrl.join('/'))
            ) {
                this.selectedTopItem = item.name;
            }
        });
    }

    processClick(item: MenuContentItem) {
        if (item.name == 'filterandsorting') {
            this.onFilterAndSortingClick();
        } else {
            //this.nvaigationChange.emit(item.text);
            //this.selectedTopItem = item.name;
            const routeparts = item.url.split('/');
            if (routeparts[2].indexOf('bingo') == -1) window.location.href = item.url;
            else {
                routeparts.splice(0, 3);
                const routeUrl = routeparts.join('/');
                this.router.navigate([routeUrl], { queryParamsHandling: 'merge' });
            } //this.menuActionsService.processClick(event, item, 'BingoTopNavigation');
        }
    }
    emitNavEvent(item: any) {
        const navigatedItem = {
            navigated: item,
        };
        this.eventService.raiseEvent(navigatedItem);
    }
    onFilterApplied($event: any) {
        this.appliedFilterList = $event.appliedFilterList;
        this.filteredResultList = $event.filteredResultList;
        this.selectedSortOption = $event.selectedSortOption;
        const indexOfAll = $event.appliedFilterList.findIndex((x: any) => x.category == 'ALL');
        if (indexOfAll != 0) {
            this.facetItems = $event.appliedFilterList;
        } else {
            this.facetItems = [];
        }
        this.filterAndSortingService.filteredResultList.next(this.filteredResultList);
        this.filterAndSortingService.appliedFilterList.next(this.appliedFilterList);
        this.filterAndSortingService.selectedSortOption.next(this.selectedSortOption);
        this.filterButtonClicked = false;
        this._showResultsMobileText = this.Global.messages['showResultsMobile']
            .replace('{0}', this.filteredResultList.length)
            .replace('{1}', this.gameData?.length);
    }

    highlightCategoryItem() {
        this.currentRoute = this.router.url.split('?')[0].split('/')[3];
        if (this.currentRoute == 'games') {
            this.selectedTopItem = this.router.url.split('?')[0].split('/')[4];
        } else {
            this.bingoNavigationData?.forEach((item: any) => {
                if (item && item.url == window.location.origin + window.location.pathname) {
                    this.selectedTopItem = item.name;
                }
            });
        }
    }

    navigateToUrl(routeUrl: any) {
        this.hideFavPopupStatus = true;
        const item = this.bingoNavigationData?.filter((elem: any) => {
            return elem.url && elem.url.indexOf(routeUrl) !== -1;
        });
        if (item && item.length > 0) this.processClick(item[0]);
    }

    ClearFilter() {
        //this.hideFavPopupStatus = false;
        this.facetItems = [];
        this.appliedFilterList = [];
        this.filteredResultList = [];
        this.filterButtonClicked = false;
        this.appliedFilterList = [];
        this.selectedSortOption = null;
        // this.currentRoute = false;
        if (this.isUserisAuthenticated === true) {
            this.isFilterEnabled = true;
        }
    }
    onFacetRemoved(facetItems: any) {
        this.appliedFilterList = JSON.parse(JSON.stringify(this.facetItems)!);
        let filteredResult = [];
        if (facetItems.length == 0) {
            filteredResult = this.gameData;
            setTimeout(() => {
                this.isProgressiveLobby = false;
            }, 1000);
        } else {
            filteredResult = this.filterAndSortingService.filterItemsByCatagory(this.appliedFilterList, this.gameData);
        }
        this.filterAndSortingService.filteredResultList.next(filteredResult);
    }
    onFilterAndSortingClick() {
        if (this.isUserisAuthenticated && this.isFilterEnabled) {
            this.filterButtonClicked = !this.filterButtonClicked;
        }
    }
    toggle() {
        window.location.reload();
    }
    isODR() {
        const nativeAppCookie = this.cookie.get('NativeApp');
        const isodr: boolean =
            this.nativeAppService?.applicationName?.toLowerCase() === 'casinowodr' || nativeAppCookie?.toLowerCase() === 'casinowodr';
        return isodr;
    }
    ngAfterViewInit() {
        setTimeout(() => {
            if (document.getElementsByTagName('header') && document.getElementsByTagName('header').length > 0) {
                new ResizeSensor(document.getElementsByTagName('header')[0], () => {
                    if (document.getElementsByClassName('main-navigation') && document.getElementsByClassName('main-navigation').length > 0) {
                        const navHeight: any = document.getElementsByClassName('main-navigation')[0];
                        navHeight.style.top = document.getElementsByTagName('header')[0].clientHeight - 1 + 'px';
                    }
                });
            } else if (this.isODR()) {
                if (document.getElementsByClassName('main-navigation') && document.getElementsByClassName('main-navigation').length > 0) {
                    const navHeight: any = document.getElementsByClassName('main-navigation')[0];
                    navHeight.style.top = 0 + 'px';
                }
            }
            if (this.selectedTopItem) {
                this.navigator.navContainerScroll(this.navContainer, this.selectedTopItem);
            }
        }, this.featureConfig.navigationBarTimeFrame);
    }

    ngOnDestroy() {
        if (this.locationChangeEvent) {
            this.locationChangeEvent.unsubscribe();
        }
        if (this.lobbyLoadSubscription) {
            this.lobbyLoadSubscription.unsubscribe();
        }
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    trackSubNavEvent(item: any) {
        // const item: any = this.bingoNavigationData.find((item: any) => item.name == this.selectedTopItem);
        this.lastRoute = this.currentRoute ?? this.router.url.split('?')[0].split('/').at(-1);
        switch (this.lastRoute) {
            case 'bingo':
                this.lastRoute = 'home';
                break;
            case 'schedule':
                this.lastRoute = 'all rooms';
                break;
            default:
                // If no case matches, lastRoute remains unchanged
                break;
        }
        // this.lastRoute = this.navigation?.previousUrl?.split('?')[0].split('/').at(-1);
        this.tracking.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'navigation',
            'component.LabelEvent': 'quick nav',
            'component.ActionEvent': 'click',
            'component.PositionEvent': this.appInfoConfig.product,
            'component.LocationEvent': this.lastRoute,
            'component.EventDetails': item.text,
            'component.URLClicked': item.url,
        });
    }
}
