import { Component, Input } from '@angular/core';

@Component({
    selector: 'bg-parallax-animation',
    templateUrl: 'parallax-animation.component.html',
})
export class ParallaxAnimationComponent {
    @Input() parallaxData: any;
    constructor() {}
}
