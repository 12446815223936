import { Injectable } from '@angular/core';

import { BingoConfigProviderService, BingoPlatformApiService, JackpotDataClientConfig } from '@frontend/bingo/core';
import { TimerService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { RootServicesModule } from '../shared/root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class JackpotService {
    private jackpotSubject = new BehaviorSubject<any>(null);
    // tslint:disable-next-line:member-ordering
    jackpotFeedObservable: Observable<any> = this.jackpotSubject.asObservable();
    // tslint:disable-next-line:member-ordering
    jackpotsCache: any = {};
    // tslint:disable-next-line:member-ordering
    gameJackpotMap: Map<string, string> = new Map<string, string>();
    jackpotGroupNameMap: Map<string, any> = new Map<string, any>();
    // tslint:disable-next-line:member-ordering
    fetchJackpotPromise: any;
    jackpotData: JackpotDataClientConfig;
    constructor(
        private api: BingoPlatformApiService,
        private timerService: TimerService,
        private configProviderService: BingoConfigProviderService,
    ) {}

    fetchJackpot() {
        this.api.get('casino/jackpot', null, { showSpinner: false }).subscribe((response: any) => {
            this.jackpotsCache = response;
            this.setJackpots(response);
            //console.log(JSON.stringify(this.jackpotsCache));
        });
    }
    startFeed() {
        this.jackpotData = this.configProviderService.provideJackpotDataClientConfig();
        if (!this.fetchJackpotPromise) {
            this.fetchJackpot();
            this.fetchJackpotPromise = this.timerService.setIntervalOutsideAngularZone(() => {
                this.fetchJackpot();
            }, this.jackpotData.jpInterval);
        }
    }
    setJackpots(jackpotFeed: any) {
        if (jackpotFeed) {
            Object.keys(jackpotFeed).forEach((jackpotKey: string) => {
                // jackpotFeed[jackpotKey].gamesAvailable.forEach((gameId:string) => {
                //     this.gameJackpotMap.set(gameId,jackpotFeed[jackpotKey].amount);
                // });
                if (jackpotFeed[jackpotKey]?.jackpotGroupName) {
                    this.jackpotGroupNameMap.set(jackpotFeed[jackpotKey].jackpotGroupName.toLowerCase(), jackpotFeed[jackpotKey]);
                }
                if (jackpotFeed[jackpotKey] && jackpotFeed[jackpotKey].gamesList && jackpotFeed[jackpotKey].gamesList.length > 0) {
                    jackpotFeed[jackpotKey].gamesList.forEach((gameId: string) => {
                        const jackpotObject: any = {
                            amount: jackpotFeed[jackpotKey].amount,
                            isHotJackpotGroup: jackpotFeed[jackpotKey].isHotJackpotGroup,
                            jackpotName: jackpotKey,
                            jackpotAttributes: jackpotFeed[jackpotKey].attributes,
                            globalJackpotAttributes: jackpotFeed[jackpotKey].globalJackpotAttributes,
                        };
                        this.gameJackpotMap.set(gameId, jackpotObject);
                    });
                }
            });
        }
        this.jackpotSubject.next(this.gameJackpotMap);
    }
    getJackpotData() {
        return this.jackpotsCache;
    }
    fetchJackpotValues(): Observable<any> {
        return this.api.get('casino/jackpot');
    }

    getJackpotByGroupName(groupName: string): any {
        if (this.jackpotGroupNameMap && this.jackpotGroupNameMap.size > 0) {
            return this.jackpotGroupNameMap.get(groupName);
        }
        return null;
    }

    getJackpotGroupNameMap() {
        return this.jackpotGroupNameMap;
    }
}
