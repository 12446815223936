import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CasinoPlatformLoaderModule } from '@casinocore/loader';
import { BingoSlotRacesModule } from '@frontend/bingo/bingo-tournaments';
import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';
import { TrustAsResourceUrlPipe } from '@frontend/vanilla/shared/browser';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { CasinoGamesLobbyModule } from '../casino-games-lobby/casino-games-lobby.module';
import { ParallaxAnimationModule } from '../parallax-animation/parallax-animation.module';
import { RoomsModules } from '../rooms/rooms.module';
import { SharedModule } from '../shared/shared.module';
import { TeasersModule } from '../teasers/teasers.module';
import { NavigationModule } from './../navigation/navigation.module';
import { SeoboxesModule } from './../seoboxes/seoboxes.module';
import { HomeComponent } from './home.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TrustAsResourceUrlPipe,
        NavigationModule,
        CasinoGamesLobbyModule,
        RoomsModules,
        TeasersModule,
        SeoboxesModule,
        SharedModule,
        LazyLoadImageModule,
        MessagePanelComponent,
        CasinoPlatformLoaderModule,
        ParallaxAnimationModule,
        BingoSlotRacesModule,
        SwiperComponent,
    ],
    declarations: [HomeComponent],
    exports: [HomeComponent],
})
export class HomeModule {
    public static forRoot(): ModuleWithProviders<HomeModule> {
        return {
            ngModule: HomeModule,
        };
    }
}
