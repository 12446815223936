import { Injectable } from '@angular/core';

import { BingoPlatformApiService } from '@frontend/bingo/core';
import { Observable } from 'rxjs';

import { RootServicesModule } from '../shared/root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class SeoboxesService {
    constructor(private api: BingoPlatformApiService) {}
    getSeoboxes(id: any): Observable<any> {
        return this.api.get('seoboxes', { categoryType: id });
    }
}
