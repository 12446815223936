import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { PromotionsBannerComponent } from './promotions-banner.component';

@NgModule({
    declarations: [PromotionsBannerComponent],
    imports: [CommonModule],
    exports: [PromotionsBannerComponent],
})
export class PromotionsBannerModule {
    public static forRoot(): ModuleWithProviders<PromotionsBannerModule> {
        return {
            ngModule: PromotionsBannerModule,
        };
    }
}
