<div class="bingo-teaser-npm-sec" [ngClass]="embeddedTeaserWidthClass">
    <div class="teasers-casino-npm" *ngIf="!enableTeaserOptimization && enableTeasers && !isInGameClient && teasersSource.length > 0">
        <ng-container [bgCasinoModuleLoad]="'TeasersWrapperComponent'" [data]="inputObjectForNpm"></ng-container>
    </div>
    <div class="opt-teasers-casino-npm" *ngIf="enableTeaserOptimization && enableTeasers && !isInGameClient && optimizedTeaserSource.length > 0">
        <ng-container [bgCasinoModuleLoad]="'TeasersWrapperComponent'" [data]="inputObjectForNpm"></ng-container>
    </div>
    <div
        class="playbingo"
        *ngIf="
            bingocta &&
            enablebingoplay &&
            isMobileWidth &&
            enableTeaserOptimization &&
            enableTeasers &&
            !isInGameClient &&
            optimizedTeaserSource.length > 0
        ">
        <div class="btn btn-primary playbingo-cta" [innerHtml]="bingocta.name | trustAsHtml" (click)="playbingoclick(bingocta)"></div>
    </div>
</div>
