<nav *ngIf="bingoNavigationData && bingoNavigationData.length > 0" item.parameters class="navbar navbar-expand-sm sub-nav-wrapper main-navigation">
    <ul class="tab-nav navbar-nav bingo-nav" id="bingo-nav" #navContainer [ngStyle]="{ width: isInGameClient == false ? '100%' : '90%' }">
        <li
            *ngFor="let item of bingoNavigationData | dsl | async"
            id="{{ item.name }}"
            [style]="item.parameters.style"
            class="tab-nav-item"
            [class.active]="item.name === selectedTopItem">
            <ng-container *ngIf="item.isEnabled">
                <a
                    class="tab-nav-link {{ item.class }}"
                    *ngIf="item.isBingoDomain"
                    [routerLink]="item.pathName"
                    [ngClass]="{ 'new-nav': isNavigationV6Design }"
                    (click)="ClearFilter(); processClick(item); trackSubNavEvent(item)">
                    <span>
                        <div class="icon {{ item.parameters.icon }}"></div>
                        <div class="name">{{ item.text }}</div>
                    </span>
                </a>
                <a
                    class="tab-nav-link {{ item.class }}"
                    *ngIf="!item.isBingoDomain"
                    [href]="item.url"
                    [ngClass]="{ 'new-nav': isNavigationV6Design }"
                    (click)="ClearFilter(); processClick(item)">
                    <span>
                        <div class="icon {{ item.parameters.icon }}"></div>
                        <div class="name">{{ item.text }}</div>
                    </span>
                </a>
            </ng-container>
        </li>
        <li
            *ngFor="let item of casinoNavItems"
            id="{{ item.name }}"
            class="tab-nav-item"
            [class.active]="item.name === selectedTopItem"
            [hidden]="!enableCasinoNavCategories">
            <ng-container *ngIf="item.isEnabled">
                <a
                    class="tab-nav-link {{ item.icon }}"
                    *ngIf="item.isBingoDomain"
                    [ngClass]="{ 'new-nav': isNavigationV6Design }"
                    [routerLink]="item.url"
                    (click)="emitNavEvent(item.name)">
                    <span>
                        <div class="icon {{ item.icon }}"></div>
                        <div class="name">{{ item.text }}</div>
                    </span>
                </a>
                <a
                    class="tab-nav-link {{ item.icon }}"
                    *ngIf="!item.isBingoDomain"
                    [ngClass]="{ 'new-nav': isNavigationV6Design }"
                    [href]="item.externalUrl"
                    (click)="emitNavEvent(item.name)">
                    <span>
                        <div class="icon {{ item.icon }}"></div>
                        <div class="name">{{ item.text }}</div>
                    </span>
                </a>
            </ng-container>
        </li>
    </ul>
    <div *ngIf="isInGameClient && displayTimerinNavinClient" class="bingo-timerinnavclient">
        <bg-clientgametime [initial]="timeMilliseconds"></bg-clientgametime>
    </div>
</nav>
<div>
    <bg-facets
        *ngIf="facetItems && facetItems.length > 0 && !isMobile"
        [facetItems]="facetItems"
        (onFacetRemoved)="onFacetRemoved($event)"></bg-facets>
    <div *ngIf="facetItems && facetItems.length > 0 && isMobile" class="filterResult">{{ _showResultsMobileText }}</div>
</div>
<div class="filter-sort-menu-body">
    <div class="filter-sort-mobile-header" [ngClass]="{ 'filter-sort-menu-show': filterButtonClicked }">
        <i class="theme-arrow-left theme-arrow-left-icon" (click)="filterButtonClicked = !filterButtonClicked"></i>
        <span class="title">{{ _fiteringAndSortingText | uppercase }}</span>
    </div>
    <div class="read-only-mask" [ngClass]="{ 'read-only-mode': filterButtonClicked }" (click)="filterButtonClicked = !filterButtonClicked">
        <!-- empty div just a lay over  -->
    </div>
    <div class="filter-sort-menu filter-sort-menu-right" [ngClass]="{ 'filter-sort-menu-show': filterButtonClicked }">
        <bg-filter-and-sorting
            *ngIf="filterButtonClicked"
            [allItems]="gameData"
            [appliedFilters]="appliedFilterList"
            [selectedSortOption]="selectedSortOption"
            (onFilterApplied)="onFilterApplied($event)"></bg-filter-and-sorting>
    </div>
</div>
