<div class="qi-noverlay" (click)="closeModal()"></div>
<div class="qi-nrectangle qi-popup">
    <div class="total-data">
        <div class="qi-nrectangle-int">
            <div class="qi-nimage qi-desktop-nimage bingo-quick-info">
                <img src="{{ data.src }}" alt="" />
                <div *ngIf="data.jackpotData && data.bingoItem" class="qi-stickers">
                    <div class="qi-Jackpotcta">
                        <div class="qi-hot-icon">
                            <img [src]="hotIcon" alt="" />
                        </div>
                        <div class="qi-jp-value">{{ data.jackpotData | currency: data.bingoItem.currency }}</div>
                    </div>
                </div>
                <div>
                    <div class="qi-ngradient"></div>
                    <div
                        (click)="favoriteClick($event, data.room)"
                        class="qi-nfavourite"
                        [ngClass]="data.room.isFavourite ? favouriteStyle : unFavouriteStyle"></div>
                </div>
            </div>
            <div class="qi-nrectangle-right bingo-qi-nrectangle-right">
                <!-- <div class="qi-ngame-provider">{{data.gameProviderText | uppercase}}</div> -->
                <div class="close theme-close qi-close qi-desktop-close" (click)="closeModal()"></div>
                <!-- <div *ngIf="data.vendorLogo" class="qi-nlogo"><img src={{data.vendorLogo}}/></div>
                <div class="qi-nlogo" *ngIf="!data.vendorLogo">{{data.providerName | uppercase}}</div> -->
                <div class="qi-ncta qi-ncta-left" (click)="launchGame()">
                    <button class="qi-nplay-now btn btn-primary">{{ data.cta | uppercase }}</button>
                </div>
            </div>
            <div style="clear: both"></div>
        </div>

        <div class="gamedata">
            <div class="qi-ngame">{{ data.roomTitle }}</div>
            <span class="gameData-body" [innerHTML]="data.roomDescription"></span>
        </div>
    </div>
</div>
