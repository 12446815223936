//import { VanillaCoreModule } from '@frontend/vanilla/core';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { CasinoPlatformLoaderModule } from '@casinocore/loader';
// import { LabelHostModule } from '@frontend/vanilla/core';
import 'hammerjs';

import { CasinoGamesLobbyModule } from '../casino-games-lobby/casino-games-lobby.module';
import { SharedModule } from '../shared/shared.module';
import { GameIframeLauncherComponent } from './game-Iframe-launcher.component';

@NgModule({
    imports: [
        //VanillaCoreModule,
        CommonModule,
        RouterModule,
        SharedModule,
        CasinoGamesLobbyModule,
        BrowserAnimationsModule,
        CasinoPlatformLoaderModule,
    ],
    declarations: [GameIframeLauncherComponent],
    exports: [GameIframeLauncherComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GameIframeLauncherModules {
    public static forRoot(): ModuleWithProviders<GameIframeLauncherModules> {
        return {
            ngModule: GameIframeLauncherModules,
        };
    }
}
