import { Component, Input } from '@angular/core';

import { ScheduleService } from '@frontend/bingo/core';

//import { ToastMessageService } from '../toast-message/toast-message.service';
import { BingoFavouriteService } from './bingofavourite.service';

//import { NavigationService } from '@frontend/vanilla/core';
//import { UserService } from '@frontend/vanilla/core';
//import { GlobalClientConfig, featuresClientConfig } from '../../../client-config';
//import { EventEmitService } from './../../services/eventemit.service';

@Component({
    selector: 'bg-favourite',
    templateUrl: './favourite.component.html',
})
export class FavouriteComponent {
    @Input()
    favIcon: string;
    @Input()
    favMarkedIcon: string;
    @Input()
    RoomId: string;
    @Input()
    isFavourite: boolean;
    @Input()
    favAddClass: string;
    @Input()
    favRemClass: string;

    // _favouriteAddedMessage: string;
    // _favouriteRemovedMessage: string;
    // _favouriteAddedErrorMessage: string;
    // _favouriteRemovedErrorMessage: string;
    // toasterRequired: any;

    constructor(
        //private toastMessageService: ToastMessageService,
        private favouriteService: BingoFavouriteService,
        private scheduleService: ScheduleService,
        // private user: UserService,
        // private featuresclientconfig: featuresClientConfig,
        // private navigator: NavigationService,
        // private Global: GlobalClientConfig,
        // private eventEmitService: EventEmitService
    ) {
        // this._favouriteAddedMessage = this.Global.messages['favouriteAddedMessage'];
        // this._favouriteRemovedMessage = this.Global.messages['favouriteRemovedMessage'];
        // this._favouriteAddedErrorMessage = this.Global.messages['errorOccuredWhileAddingToFavMessage'];
        // this._favouriteRemovedErrorMessage = this.Global.messages['errorOccuredWhileRemovingFromFavMessage'];
    }

    // ngOnInit() {
    //     this.toasterRequired = this.featuresclientconfig['favouritesConfirmationPopUp'];
    // }

    toggleFavourite() {
        let roomIdsArray = [];
        roomIdsArray = this.scheduleService.getRoomsbyRoomId(this.RoomId);
        if (roomIdsArray.length > 0) {
            const roomId = roomIdsArray.reduce((c: any, value: any) => (!c.includes(value) ? c.concat(value) : c), []).join(',');
            this.favouriteService.toggleFavourite(roomId, this.isFavourite, this.favAddClass, this.favRemClass);
        } else {
            this.favouriteService.toggleFavourite(this.RoomId, this.isFavourite, this.favAddClass, this.favRemClass);
        }

        // if (!this.user.isAuthenticated) {
        //     this.navigator.goToLogin({ appendReferrer: true });
        //     return;
        // }
        // else {
        //     if (this.isFavourite) {
        //         this.favouriteService.removeFavourite(this.RoomId).then(
        //             (response: any) => {
        //                 if (this.toasterRequired) {
        //                     this.toastMessageService.on('theme-heart-broken', this._favouriteRemovedMessage, true);
        //                 }
        //                 this.isFavourite = !this.isFavourite;
        //                 this.eventEmitService.raiseEvent({ 'favoriteUpdated': this.RoomId });
        //             }, (error) => {
        //                 if (this.toasterRequired) {
        //                     this.toastMessageService.on('theme-heart-broken', this._favouriteRemovedErrorMessage, true);
        //                 }
        //                 //this.emitParentView();
        //             });
        //     }
        //     else {
        //         this.favouriteService.addFavourite(this.RoomId).then(
        //             (response: any) => {
        //                 if (this.toasterRequired) {
        //                     this.toastMessageService.on('theme-bingo-heart', this._favouriteAddedMessage, true);
        //                 }
        //                 this.isFavourite = !this.isFavourite;
        //                 this.eventEmitService.raiseEvent({ 'favoriteUpdated': this.RoomId });
        //             }, (error) => {
        //                 if (this.toasterRequired) {
        //                     this.toastMessageService.on('theme-bingo-heart', this._favouriteAddedErrorMessage, true);
        //                 }
        //                 //this.emitParentView();
        //             });
        //     }
        // }
    }

    // emitParentView() {
    //     this.eventEmitService.raiseEvent({ 'favoriteUpdated': this.RoomId });
    // }
}
