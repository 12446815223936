//import { VanillaCoreModule } from '@frontend/vanilla/core';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';

import { CurrencyPipe } from '@frontend/vanilla/core';
import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';

import { CasinoGamesLobbyModule } from '../casino-games-lobby/casino-games-lobby.module';
import { FavouriteModule } from '../shared/components/favourite/favourite.module';
import { ToastMessageModule } from '../shared/components/toast-message/toast-message.module';
import { SharedModule } from '../shared/shared.module';
// import { LabelHostModule } from '@frontend/vanilla/core';
import { MyFavouriteComponent } from './my-favourite.component';

@NgModule({
    imports: [
        //VanillaCoreModule,
        CommonModule,
        // LabelHostModule,
        SharedModule,
        ToastMessageModule.forRoot(),
        MatTabsModule,
        FavouriteModule,
        CasinoGamesLobbyModule,
        MessagePanelComponent,
        CurrencyPipe,
    ],
    declarations: [MyFavouriteComponent],
    exports: [MyFavouriteComponent, MatTabsModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MyFavouriteModule {
    public static forRoot(): ModuleWithProviders<MyFavouriteModule> {
        return {
            ngModule: MyFavouriteModule,
        };
    }
}
