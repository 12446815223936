<div
    class="schedule-item schedule-grid tiles schedule-item__tile"
    [ngClass]="{ 'cozy-card-greyout': isGrayedOut, 'cozy-combined-room': bingoItem.isCombinedRoom }"
    *ngIf="!bingoItem.tournament_enabled">
    <div
        *ngIf="bingoItem.isRoomLocked || bingoItem.lockRoomInLogoutStage || (bingoItem.displayPrivateRoom && bingoItem.privateRoom)"
        class="room-lacked">
        <span>{{ roomLockedText }}</span>
        <i class="theme-locked-i"></i>
        <div class="moreinfo-card" (click)="roomLockedMoreInfo(bingoItem)">
            <div class="btn btn-light btn-block moreinf">{{ moreInfoText }}</div>
        </div>
    </div>
    <div class="schedule-item__main">
        <div class="schedule-item-body-container" [style.--RoomGreyoutColor]="isGrayedOut ? roomIconslist?.attributes?.roomGreyoutColor : '#000'">
            <img alt="" class="superlink-bg" *ngIf="bingoItem.bgImage" src="{{ bingoItem.bgImage }}" />
            <div class="schedule-item__header">
                <div
                    class="mm-spent"
                    *ngIf="bingoItem.optimoveLabel && bingoItem.enableStickerForRoom"
                    [ngStyle]="{ background: roomIconslist?.attributes?.roomTopRibbonBgClr, color: roomIconslist?.attributes?.roomTopRibbonClr }">
                    <i class="theme-bingo-star"></i>{{ bingoItem.optimoveLabel }}
                </div>
                <div
                    class="mm-spent"
                    *ngIf="bingoItem.isPreferredRoom && !bingoItem.optimoveLabel"
                    [ngStyle]="{ background: roomIconslist?.attributes?.roomTopRibbonBgClr, color: roomIconslist?.attributes?.roomTopRibbonClr }">
                    <i class="theme-bingo-star"></i>{{ roomIconslist.columns.preferredBingoRoom }}
                </div>
                <div class="schedule-item__image-section">
                    <div class="schedule-item__image-container">
                        <img alt="" class="schedule-item__image" src="{{ bingoItem.roomImage ? bingoItem.roomImage : thumbImageSrc }}" />
                    </div>
                </div>
                <div class="schedule-item__title-section">
                    <div class="features-container">
                        <div class="timer-feature-container">
                            <div class="timerclass">
                                <span
                                    bgTimer
                                    [timerColor]="roomIconslist?.attributes?.timerConfigTextColorTwo"
                                    [timerBgColor]="roomIconslist?.attributes?.timerConfigBgColorTwo"
                                    [ngStyle]="{
                                        background: roomIconslist?.attributes?.timerConfigBgColor,
                                        color: roomIconslist?.attributes?.timerConfigTextColor
                                    }"
                                    class="timer"
                                    [ngClass]="{ 'insurance-btn': bingoItem.insurance && bingoItem.status?.state_end_time != null }">
                                    <i class="theme-clock-solid" *ngIf="!bingoItem.sit_n_go_enabled"></i>
                                    <div
                                        class="insurance-timerclass"
                                        *ngIf="(bingoItem.insurance && bingoItem.status?.state_end_time != null) || bingoItem.sit_n_go_enabled">
                                        <div>{{ playingSoonText }}</div>
                                    </div>
                                    <bg-schedule-timer
                                        *ngIf="(!bingoItem.insurance || bingoItem.status?.state_end_time == null) && !bingoItem.sit_n_go_enabled"
                                        [timerinput]="bingoItem.status.state_end_time"
                                        (timerChange)="onTimeNotification($event)"></bg-schedule-timer>
                                </span>
                                <span class="room-feature" *ngFor="let item of bingoItem.features">
                                    <bg-feature-icon [feature]="item"></bg-feature-icon>
                                </span>
                            </div>
                        </div>
                        <div class="fav-info-container">
                            <i
                                *ngIf="!isInGameClient && enableInfoIconOnRooms"
                                class="theme-info cozy-theme-info"
                                (click)="moreInfoClick($event, null)">
                            </i>
                            <bg-favourite
                                *ngIf="IsFavouriteEnabled"
                                class="favorite-icon"
                                [RoomId]="bingoItem?.id"
                                [isFavourite]="bingoItem?.isFavourite"
                                [favAddClass]="'favorite-added'"
                                [favIcon]="roomIconslist.columns.favIcon"
                                [favMarkedIcon]="roomIconslist.columns.favMarkedIcon"
                                [favRemClass]="'favorite-removed'">
                            </bg-favourite>
                        </div>
                    </div>
                    <div class="title-jackpot-container">
                        <div class="schedule-item__title">{{ bingoItem?.name }}</div>
                        <ng-container *ngTemplateOutlet="jackpot"></ng-container>
                        <div *ngIf="isFriendsRoom && friendsBingoCopyRightToggle" class="gala-friendsroom" [innerHTML]="friendsBingoCopyRights"></div>

                        <div class="linked-icon" *ngFor="let item of bingoItem.features">
                            <span *ngIf="item == 'linked_game'">
                                <i [ngClass]="roomIconslist.columns.linkedIcon"></i>
                            </span>
                        </div>
                    </div>
                    <div
                        class="schedule-item__body"
                        [ngClass]="{
                            'superlink-gala-rooms': bingoItem.isSuperLink,
                            'combined-body': bingoItem.isCombinedRoom && enableIconReplacement,
                            'sng': bingoItem.sit_n_go_enabled
                        }">
                        <ng-container *ngTemplateOutlet="players"></ng-container>
                        <ng-container *ngTemplateOutlet="prize"></ng-container>
                        <ng-container *ngTemplateOutlet="ticket"></ng-container>
                    </div>
                </div>
            </div>

            <ng-container *ngTemplateOutlet="progressBar"></ng-container>
            <!-- view === 'tiles' &&  -->
        </div>
        <div class="schedule-item-footer">
            <button
                *ngIf="!disableAllPrebuy && IsPreBuyEnabled && bingoItem.prebuy_enabled"
                [attr.disabled]="
                    !bingoItem.prebuy_enabled || (bingoItem.isSuperLink && !bingoItem.prebuy_enabled) || disablePreBuy || bingoItem.insurance
                        ? ''
                        : null
                "
                (click)="preBuy(bingoItem.id)"
                class="btn btn-dark prebuy-btn">
                {{ roomnavlist.columns.roomTabPreBuyButtonText }}
            </button>
            <button [attr.disabled]="isPlayButtonDisabled ? '' : null" class="btn btn-primary play-btn" (click)="gameLaunch(bingoItem, $event)">
                <span class="schedule-item__button-text text-uppercase">{{ roomnavlist.columns.playNowButtonText }}</span>
            </button>
        </div>
    </div>
</div>

<ng-template #jackpot>
    <div
        class="single-entry-count"
        *ngIf="bingoItem.isCombinedRoom"
        [ngStyle]="{ background: roomIconslist?.attributes?.singleEntryPointBgClr, color: roomIconslist?.attributes?.singleEntryPointClr }">
        <span>{{ bingoItem.gameCount }} {{ combinedRoomsCount }}</span>
    </div>
    <div
        *ngIf="(bingoItem.pjps_in_player_currency && bingoItem.pjps_in_player_currency.length > 0) || bingoItem.isCombinedRoom"
        class="schedule-item__body-item schedule-item__jackpot">
        <span class="schedule-item__icon">
            <span class="icon theme-bingo-star"></span>
        </span>
        <div class="jackpot-values" *ngIf="!bingoItem.isCombinedRoom">
            <span
                class="schedule-item__value"
                *ngFor="let pjp of bingoItem.pjps_in_player_currency"
                [ngClass]="bingoItem.pjps_in_player_currency.length > 1 ? 'pjp-fadeout' : ''"
                >{{ pjp.value.Cash | vnCurrency }}</span
            >
        </div>
    </div>
</ng-template>

<ng-template #prize>
    <div class="schedule-item__body-item schedule-item__body-item--ticket-price">
        <span class="schedule-item__icon">
            <span
                class="icon theme-bingo-gala-ticket"
                *ngIf="!bingoItem.isCombinedRoom || (bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrice)"></span
        ></span>
        <span *ngIf="!bingoItem.isCombinedRoom" class="schedule-item__value">{{ bingoItem.card_price_in_player_currency.Cash | vnCurrency }}</span>
        <span
            *ngIf="bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrice && enableIconReplacement"
            class="schedule-item__value combined-text"
            [ngClass]="{ sng: bingoItem.sit_n_go_enabled }"
            >{{ combinedPriceText }}</span
        >
    </div>
    <bg-feature-icon
        class="combined-room-icon"
        *ngIf="bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrice && !enableIconReplacement"
        [feature]="bingoItem.combinedRoomFeature"></bg-feature-icon>
</ng-template>

<ng-template #ticket>
    <div
        [ngClass]="{ 'for-merch-prize': isPhysicalPrize, 'for-bonus-prize': isBonusPrize }"
        class="schedule-item__body-item schedule-item__body-item--prize"
        *ngIf="!bingoItem.sit_n_go_enabled">
        <span
            class="schedule-item__icon"
            *ngIf="!bingoItem.isCombinedRoom || (bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrize && !bingoItem.sit_n_go_enabled)"
            ><span class="icon theme-bingo-gala-trophy"></span
        ></span>
        <span *ngIf="!bingoItem.isCombinedRoom && !isPhysicalPrize && !isBonusPrize && !bingoItem.sit_n_go_enabled" class="schedule-item__value">{{
            bingoItem.pot_in_player_currency.Cash | bgFloatTruncate: bingoItem.number_of_players : dynaconParams
        }}</span>
        <span *ngIf="!bingoItem.isCombinedRoom && isPhysicalPrize && !isBonusPrize && !bingoItem.sit_n_go_enabled" class="schedule-item__value"
            ><span class="merch">{{ physicalPrizeText }}</span></span
        >
        <span *ngIf="!bingoItem.isCombinedRoom && isBonusPrize && !bingoItem.sit_n_go_enabled" class="schedule-item__value"
            ><span class="bonusPrize">{{ bonusPrizeText }}</span></span
        >
        <span
            *ngIf="bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrize && enableIconReplacement && !bingoItem.sit_n_go_enabled"
            class="schedule-item__value combined-text"
            >{{ combinedPrizeText }}</span
        >
    </div>
    <bg-feature-icon
        class="combined-room-icon"
        *ngIf="bingoItem.isCombinedRoom && bingoItem.combinedRoomHasPrize && !enableIconReplacement"
        [feature]="bingoItem.combinedRoomFeature"></bg-feature-icon>
</ng-template>

<ng-template #players>
    <div class="schedule-item__body-item schedule-item__body-item--players" *ngIf="!bingoItem.sit_n_go_enabled">
        <span class="schedule-item__icon"><span class="icon theme-bingo-profile"></span></span>
        <span class="schedule-item__value">{{ bingoItem.number_of_players }}</span>
    </div>
</ng-template>

<ng-template #progressBar>
    <div class="progress-bar" *ngIf="IsProgressBar && !bingoItem.sit_n_go_enabled">
        <div class="progress-bar__content">
            <div class="progress-bar__bar" [@barAnimation]="getAnimationState()"></div>
        </div>
    </div>
</ng-template>
