import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CasinoCoreGamelaunchService } from '@casinocore/platform/core';
import {
    BingoConfig,
    BingoConfigProviderService,
    BingoHelperService,
    BingoTournamentsService,
    CasinoClientConfig,
    EventEmitService,
    FeaturesClientConfig,
    GameLaunchService,
    ModularCasinoClientConfig,
    SitecoreContent,
    TeaserClientConfig,
} from '@frontend/bingo/core';
import {
    DeviceService,
    HeaderService,
    LoginDialogService,
    MessageQueueService,
    NativeAppService,
    NavigationService,
    UserService,
} from '@frontend/vanilla/core';
//import { NguCarouselConfig } from '@ngu/carousel';
import { Subject, Subscription } from 'rxjs';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { GamesService } from '../casino-games-lobby/casinogames.service';
import { JackpotService } from '../casino-games-lobby/jackpot.service';

@Component({
    selector: 'bg-home',
    templateUrl: 'home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
    casinoLmtFeedFromSession: any;
    flattenedListOfGamesInCasinoLmtFeed: any;
    casinoLmtFeed: any;
    gameIconUrlTemplate: any;
    isInGameClient: boolean;
    configuredCasinoCategoryIds: any;
    recentlyPlayedGamesExist: boolean;
    recentlyPlayedGameIdList: any;
    recentlyPlayedGames: any;
    rpgPanelTitle: any;
    orderedCategoriesGameData: any;
    parallaxInput: any;
    // public carouselConfig: NguCarouselConfig;
    isTouch: boolean;
    showLoading: boolean = true;
    Global: any;
    enableCasinoWidget: boolean;
    casinolobbyWidgetUrl: any;
    showWidgetLoading: boolean = true;
    enableCasinoNpmGrid: boolean;
    modularCasinoEnabled: boolean;
    isNewsFeedNpmEnabled: boolean;
    newFeedWidgetData: any;
    loadSlotRaces: boolean;
    jsondata: any = {};
    categoryText: string;
    enableTeasersInRooms: boolean;
    enableHomeTeasers: boolean;
    showVerticalRoomsInHomePage: boolean;
    enableBingoNPM: boolean;
    displaySeeAllOnLobby: boolean;
    clientConfig: any;
    private unsubscribe: Subject<void> = new Subject();
    errorMessageSubscription: Subscription;
    bingoclientconfig: BingoConfig;
    bingoFeatureconfig: FeaturesClientConfig;
    casinoClientConfig: CasinoClientConfig;
    modularCasinoConfig: ModularCasinoClientConfig;
    teaserConfig: TeaserClientConfig;
    bingoTournamentsConfig: any;
    displayTournamentsOnTop: boolean;
    isBingoTournamentsEnbaled: boolean;
    enableBingoTournamentsWidget: boolean;
    contentSubscription: Subscription;
    content: any;
    messages: any;
    swiperOptionsHome: SwiperOptions = {
        modules: [Navigation, Pagination, Autoplay],
        loop: false,
        autoHeight: true,
        allowTouchMove: false,
        spaceBetween: 10,
        direction: 'horizontal',
        slidesPerView: 'auto',
        centeredSlides: false,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
            waitForTransition: false,
        },
        pagination: false,
        observer: false,
        rewind: false,
        slidesPerGroup: 1,
        keyboard: true,
        watchOverflow: true,
        mousewheel: false,
        scrollbar: false,
        preventClicks: false,
        preventClicksPropagation: false,
    };

    constructor(
        private casinoGameService: GamesService,
        private activatedRoute: ActivatedRoute,
        private gameLaunchService: GameLaunchService,
        private eventService: EventEmitService,
        // private bottomNavService: BottomNavService
        private jackpotService: JackpotService,
        // private sanitizer: DomSanitizer,
        private navigator: NavigationService,
        private messageQueue: MessageQueueService,
        private loginDialogService: LoginDialogService,
        private user: UserService,
        private nativeAppService: NativeAppService,
        private headerService: HeaderService,
        private deviceService: DeviceService,
        private activeRoute: ActivatedRoute,
        private configProviderService: BingoConfigProviderService,
        private gameLaunch: CasinoCoreGamelaunchService,
        private bingoTournamentsService: BingoTournamentsService,
        private bingoHelperService: BingoHelperService,
    ) {
        this.bingoclientconfig = this.configProviderService.provideBingoConfig();
        this.bingoFeatureconfig = this.configProviderService.provideFeaturesConfig();
        this.casinoClientConfig = this.configProviderService.provideCasinoClientConfig();
        this.modularCasinoConfig = this.configProviderService.provideModularCasinoClientConfig();
        this.teaserConfig = this.configProviderService.provideTeaserClientConfig();
        this.bingoTournamentsConfig = this.configProviderService.provideBingoTournamentsClientConfig();

        // this.carouselConfig = {
        //     grid: { xs: 3, sm: 4, md: 6, lg: 6, all: 0 },
        //     slide: 6,
        //     speed: 400,
        //     // interval: 500000,
        //     point: {
        //         visible: false,
        //         // pointStyles: `
        //         //     .ngxcarouselPoint {
        //         //         list-style-type: none;
        //         //         text-align: center;
        //         //         margin: 0;
        //         //         position: absolute;
        //         //         width: 100%;
        //         //         white-space: nowrap;
        //         //         overflow: auto;
        //         //         box-sizing: border-box;
        //         //     }
        //         //     .ngxcarouselPoint li {
        //         //     display: inline-block;
        //         //     border-radius: 50%;
        //         //     background: white;
        //         //     padding: 4px;
        //         //     margin: 0 3px;
        //         //     transition-timing-function: cubic-bezier(.17, .67, .83, .67);
        //         //     transition: .4s;
        //         //     }
        //         //     .ngxcarouselPoint li.active {
        //         //         background: #ffffff;
        //         //         transform: scale(1.5);
        //         //         }`
        //     },
        //     loop: false,
        //     touch: false
        // };
        this.eventService.getEventObservable().subscribe((event) => {
            if (event == 'teaserLoaded') {
                this.eventService.raiseEvent('dataLoaded');
                this.showLoading = false;
            }
            if (event == 'dataLoaded') {
                this.showLoading = false;
            }
        });
    }

    ngOnInit() {
        this.setNewsFeedWidget();
        this.messageEventPublish();
        this.enableTeasersInRooms = this.bingoFeatureconfig.enableTeasersInRooms;
        this.enableHomeTeasers = this.bingoFeatureconfig.enableHomeTeasers;
        this.enableBingoNPM = this.teaserConfig.enableBingoNpm;
        this.displaySeeAllOnLobby = this.bingoFeatureconfig.displaySeeAllOnLobby;
        this.displayTournamentsOnTop = this.bingoTournamentsConfig.displayTournamentsOnTop;
        this.isBingoTournamentsEnbaled = this.bingoHelperService.getConfigBasedOnInvokerProduct(this.bingoTournamentsConfig.enableBingoTournaments);
        this.enableBingoTournamentsWidget = this.bingoTournamentsConfig.enableBingoTournamentsWidget;
        this.showVerticalRoomsInHomePage =
            !!this.bingoFeatureconfig.showVerticalRoomsInHomePage &&
            (this.deviceService.isMobilePhone || (this.deviceService.isTablet && (window.innerWidth < 800 || document.body.offsetWidth < 800)));
        this.clientConfig = this.activeRoute.snapshot.data['config'];
        this.Global = this.clientConfig?.global;
        this.casinolobbyWidgetUrl =
            this.clientConfig?.pageConfig?.value?.casinoPage['url'] +
            this.casinoClientConfig?.casinoLobbyWidgetUrl?.replace('$category$', this.Global?.messages['casinoWidgetCategory']);
        if (this.nativeAppService.isNativeWrapper) {
            this.casinolobbyWidgetUrl += '&_nativeApp=' + this.nativeAppService.applicationName;
            //this.rpgymliframeurl += '&_nativeApp=' + this.nativeAppService.applicationName;
        } else {
            this.casinolobbyWidgetUrl += '&invokerProduct=' + this.bingoclientconfig.invokerProduct;
            //this.rpgymliframeurl += '&invokerProduct=bingo';
        }

        this.enableCasinoWidget = this.bingoFeatureconfig.enableCasinoWidget;
        if (this.clientConfig?.bingoParallax && this.clientConfig?.bingoParallax?.parallax) {
            this.parallaxInput = this.clientConfig?.bingoParallax?.parallax[0];
        }
        this.isNewsFeedNpmEnabled = this.modularCasinoConfig.isEnabled && this.modularCasinoConfig.enableNewsFeedNpm;
        this.headerService.whenReady.subscribe(() => {
            this.headerService.highlightProduct('home');
        });
        this.isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        this.rpgPanelTitle = this.Global?.messages['RecentlyPlayedGamesTitle'];
        this.configuredCasinoCategoryIds = this.bingoclientconfig['bingoHomePage'];
        let activeRoute: string = this.navigator.location.absUrl().substr(this.navigator.location.absUrl().lastIndexOf('/') + 1);
        if (activeRoute === 'bingo' || activeRoute === '' || activeRoute === 'en') {
            activeRoute = 'home';
        }
        this.categoryText = activeRoute.split('?')[0];
        if (localStorage.getItem('CasinoLmtFeed')) {
            this.casinoLmtFeedFromSession = JSON.parse(localStorage.getItem('CasinoLmtFeed')!);
            this.splitCategoriesFromFeed();
        } else {
            this.casinoGameService.allCategories().subscribe(
                (response: any) => {
                    localStorage.setItem('CasinoLmtFeed', JSON.stringify(response));
                    this.casinoLmtFeedFromSession = response;
                    this.splitCategoriesFromFeed();
                },
                (error) => {
                    if (error) this.showLoading = false;
                },
            );
        }

        if (this.activatedRoute.snapshot.queryParamMap.get('.box')) {
            this.isInGameClient = true;
        }
        this.headerService.whenReady.subscribe(() => {
            this.headerService.highlightProduct('bingo');
        });
        this.enableCasinoNpmGrid = this.modularCasinoConfig.enableCasinoNpmGrid;
        this.modularCasinoEnabled = this.modularCasinoConfig.isEnabled;
        const productName = this.modularCasinoConfig.enableCasinoNavCategories ? 'bingo' : '';
        this.jsondata = {
            lobbyType: this.modularCasinoConfig.casinoNpmDefaultlobbyType,
            categoryRoute: this.modularCasinoConfig.casinoNpmDefaultCategoryroute,
            seeAllProductLinkOverride: productName,
        };
        this.contentSubscription = this.bingoTournamentsService.content.subscribe((content: SitecoreContent) => {
            this.content = content;
            this.messages = content && content.textTranslations ? content.textTranslations.versionedList : {};
        });
    }
    @HostListener('window:message', ['$event']) receiveWidgetMessages(event: MessageEvent) {
        if (event.data.params && event.data.params.categoryid != 'rcp-widget') {
            if (event.data.eventName == 'LobbyLoaded') {
                this.showWidgetLoading = false;
                this.resizeIframe(event.data.params);
            } else if (event.data.eventName == 'GameLaunch') {
                this.launchGameFromWidget(event.data.params);
            } else if (event.data.eventName == 'Error') {
                this.handleErrorResponse(event.data.params);
            } else if (event.data.eventName == 'UserAction') {
                this.handleWidgetUserActions(event.data.params);
            }
        }
    }
    resizeIframe(data: any): void {
        const iframe = document.getElementById('CasinoLobbyWidget') as HTMLIFrameElement;
        iframe.height = data.height;
    }

    launchGameFromWidget(data: any): void {
        if (!this.user.isAuthenticated) {
            this.loginDialogService.open({ returnUrl: data.redirectUrl });
            return;
        } else this.navigator.goTo(data.redirectUrl, data.redirectOptions);
    }

    messageEventPublish() {
        this.errorMessageSubscription = this.gameLaunch.messageQueueObservable.subscribe((response: any) => {
            if (response && response.showMessageInBingo) {
                this.messageQueue.clear();
                this.messageQueue.addError(response.message);
            }
        });
    }

    handleErrorResponse(data: any): void {
        if (data.errorMessages) {
            this.messageQueue.clear();
            this.messageQueue.addMultiple(data.errorMessages);
        }
        if (data.redirectUrl) {
            this.navigator.goTo(data.redirectUrl);
        }
    }

    handleWidgetUserActions(data: any): void {
        if (data.promptLogin) {
            this.loginDialogService.open({ returnUrl: data.redirectUrl, loginMessageKey: data.loginMessageKey });
        } else if (data.redirectUrl) {
            this.navigator.goTo(data.redirectUrl, data.redirectOptions);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        if (this.errorMessageSubscription) {
            this.errorMessageSubscription.unsubscribe();
        }
    }

    splitCategoriesFromFeed() {
        this.jackpotService.jackpotFeedObservable.subscribe((jackpots) => {
            if (
                jackpots &&
                this.casinoLmtFeedFromSession &&
                this.casinoLmtFeedFromSession.categoryDataList &&
                this.casinoLmtFeedFromSession.categoryDataList.length > 0
            ) {
                this.casinoLmtFeedFromSession.categoryDataList.forEach((categories: any) => {
                    categories.categoryGameList.forEach((game: any) => {
                        if (game && game.gameId && jackpots.get(game.gameId)) {
                            game.jackpotValue = jackpots.get(game.gameId);
                        }
                    });
                });
            }
        });
        this.jackpotService.startFeed();
        if (this.casinoLmtFeedFromSession.categoryDataList) {
            this.flattenedListOfGamesInCasinoLmtFeed = this.casinoLmtFeedFromSession.categoryDataList.reduce(
                (accumulatedGameList: any, categories: any) => accumulatedGameList.concat(categories.categoryGameList),
                [],
            );
        }
        this.casinoLmtFeed = this.getUnqiueElementsFromFlattenedArray(this.flattenedListOfGamesInCasinoLmtFeed, (item: any) => item.gameId);
        this.gameIconUrlTemplate = this.casinoLmtFeedFromSession.gameIconTemplateUrl;
        if (this.configuredCasinoCategoryIds && this.casinoLmtFeedFromSession.categoryDataList) {
            this.orderedCategoriesGameData = this.casinoLmtFeedFromSession.categoryDataList.filter((categoryData: any) =>
                this.configuredCasinoCategoryIds.find((configuredOrder: any) => configuredOrder == categoryData.categoryId),
            );
            this.buildIconUrlsForEachGame(
                this.gameIconUrlTemplate,
                this.deepFlattenArray(this.orderedCategoriesGameData.map((x: any) => x.categoryGameList)),
            );
        }

        this.casinoGameService.recentlyplayedgames().subscribe(
            (response: any) => {
                if (response && response.length > 0) {
                    this.recentlyPlayedGameIdList = response;
                    this.recentlyPlayedGames = this.casinoLmtFeed.filter((currentGameItem: any) =>
                        this.recentlyPlayedGameIdList.some((someGameId: any) => currentGameItem.gameId === someGameId),
                    );
                    if (this.recentlyPlayedGames.length > 0) {
                        this.recentlyPlayedGamesExist = true;
                    }
                    this.buildIconUrlsForEachRecentlyPlayedGame(this.gameIconUrlTemplate, this.recentlyPlayedGames);
                }
            },
            (error) => {
                if (error) this.showLoading = false;
            },
        );
    }

    deepFlattenArray(array: any) {
        return array.reduce((acc: any, val: any) => (Array.isArray(val) ? acc.concat(this.deepFlattenArray(val)) : acc.concat(val)), []);
    }

    buildIconUrlsForEachRecentlyPlayedGame(iconUrlTemplate: string, feed: any) {
        for (const item of feed) {
            item.imageUrl = iconUrlTemplate.replace('{0}', item.iconStyle).replace('{1}', item.gameId) + '.jpg';
        }
    }

    gotToRooms() {
        this.eventService.raiseEvent({ navigateToUrl: '/bingo/rooms' });
        //this.navigation.goTo('/bingo/rooms');
    }

    buildIconUrlsForEachGame(iconUrlTemplate: string, feed: any) {
        for (const item of feed) {
            item.imageUrl = iconUrlTemplate.replace('{0}', item.iconStyle).replace('{1}', item.gameId) + '.jpg';
        }
    }

    launchGameFromHomePage(game: any) {
        this.gameLaunchService.initiateGameLaunch(
            game.gameName,
            game.provider,
            'Casino',
            '',
            'REGULAR',
            game.imageUrl,
            game.gameId,
            false,
            0,
            1,
            '',
            '',
            game?.themeid,
            null,
        );
    }

    getUnqiueElementsFromFlattenedArray(flattenedArray: any, selector: any) {
        if (flattenedArray) {
            return flattenedArray.filter(
                (e: any, i: any) =>
                    flattenedArray.findIndex((a: any) => {
                        if (selector) {
                            return selector(a) === selector(e);
                        }
                        return a === e;
                    }) === i,
            );
        }
        return flattenedArray;
    }
    setNewsFeedWidget() {
        this.newFeedWidgetData = {
            containerPath: this.modularCasinoConfig.newsFeedWidgetContainerPath,
        };
    }
}
