import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { RoomsModules } from '../rooms/rooms.module';
import { BingoWidgetComponent } from './bingo-widget.component';

@NgModule({
    imports: [RoomsModules, CommonModule],
    declarations: [BingoWidgetComponent],
    exports: [BingoWidgetComponent],
})
export class BingoWidgetModule {
    public static forRoot(): ModuleWithProviders<BingoWidgetModule> {
        return {
            ngModule: BingoWidgetModule,
        };
    }
}
