import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { BingoPlatformApiService } from '@frontend/bingo/core';
import { Observable } from 'rxjs';

@Injectable()
export class ConfigResolver implements Resolve<any> {
    constructor(private api: BingoPlatformApiService) {}
    resolve(): Observable<any> {
        const configValue = localStorage.getItem('config');
        const config = configValue != null && configValue != '' ? JSON.parse(configValue) : null;
        if (config != null) return config;
        else {
            return this.getRoomsDataConfig();
        }
    }
    getRoomsDataConfig(): Observable<any> {
        return this.api.get('BingoClientConfigs');
    }
}
