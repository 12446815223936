import { ModuleWithProviders, NgModule } from '@angular/core';

import { runOnAppInit } from '@frontend/vanilla/core';

import { HtmlBootstrapService } from './html-bootstrap.service';

@NgModule({
    imports: [],
})
export class HtmlModule {
    public static forRoot(): ModuleWithProviders<HtmlModule> {
        return {
            ngModule: HtmlModule,
            providers: [runOnAppInit(HtmlBootstrapService)],
        };
    }
}
