import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';

import { ToastMessageModule } from '../toast-message/toast-message.module';
import { FavouriteComponent } from './favourite.component';

//import { PlatformModule } from '@casinocore/platform';
@NgModule({
    declarations: [FavouriteComponent],
    exports: [FavouriteComponent],
    imports: [CommonModule, ToastMessageModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FavouriteModule {
    public static forRoot(): ModuleWithProviders<FavouriteModule> {
        return {
            ngModule: FavouriteModule,
        };
    }
}
