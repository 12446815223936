import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { CasinoCoreGamelaunchService } from '@casinocore/platform/core';
import {
    BingoConfig,
    BingoConfigProviderService,
    BingoWidgetService,
    CacheSettingsClientConfig,
    EventEmitService,
    FeaturesClientConfig,
    FreeBingoTicketService,
    GameLaunchService,
    RoomsInfoService,
    ScheduleService,
    TicketsResourceService,
} from '@frontend/bingo/core';
import {
    DeviceService,
    HeaderService,
    LoadingIndicatorService,
    MessageQueueService,
    NavigationService,
    Page,
    ProductHomepagesConfig,
    SessionStoreService,
    UserLoginEvent,
    UserService,
} from '@frontend/vanilla/core';
//import { NguCarouselConfig, NguCarouselStore } from '@ngu/carousel';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Keyboard, Navigation } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { FilterAndSortingService } from '../../filter-and-sorting/filter-and-sorting.service';
import { Games } from '../../models/games';
import { QuickInfoModalComponent } from '../../quick-info-modal/quick-info-modal.component';
import { TrackingModel } from '../../shared/components/Tracking/TrackingModel';
import { TrackingModelService } from '../../shared/components/Tracking/tracking-modal-service';
import { AlertMessageService } from '../../shared/components/alert-message/alert-message.service';
import { BallFilterPipe } from '../../shared/filters/balltypefilter';
import { LoginService } from '../../shared/services/login-Service';

@Component({
    selector: 'bg-roomslist-component',
    templateUrl: 'rooms-list.component.html',
    styleUrls: ['rooms-list.component.css'],
    providers: [BallFilterPipe, TrackingModelService],
})
export class RoomsListComponent implements OnInit, AfterViewInit, OnDestroy {
    initData: any;
    @Output() update: EventEmitter<any> = new EventEmitter<any>();
    @Input() showVerticalRoomsInHomePage: any;
    gameData: Games[] = [];
    favouriteInfo: any = [];
    gameDataView: any = [];
    isFeedEmpty: boolean = false;
    gameBallArr: any = [];
    selectedSortOption: any;
    appliedFilterList: any;
    selectedSortOptionSubscription: Subscription;
    appliedFilterListSubscription: Subscription;
    filteredResultListSubscription: Subscription;
    scheduleDataSubscription: Subscription;
    roomsHasValue: boolean;
    schedulepollstatus: boolean = false;
    schedulerefreshtime: number = 0;
    //public carouselConfig: NguCarouselConfig;
    isHome: boolean = false;
    isMobileView: boolean;
    isTouch: any;
    // selectedSortType: string = 'status.state_end_time';
    selectedSortType: string = '';
    bingoGameMappings: any;
    isDefaultSortEnabled: boolean = false;
    showLoading: boolean = true;
    schedulePolling: any;
    pinnedRoomId: number[] = [];
    sitNGoRoomIds: number[] = [];
    favFlag: boolean;
    eventEmitSubscription: any;
    selectedBallType: number = 0;
    _loadingIndicator: any;
    open_popup_box: any;
    activeRoomId: number;
    preBuyDate: string;
    prebuySlot: string;
    tempRoom: Games;
    isInGameClient: boolean;
    gridSize: number;
    prefeeredRoomName: string = '';
    interval: any;
    currentWidgetHeight: number;
    newWidgetHeight: number;
    maxHeightCount: number;
    trackingModel: TrackingModel;
    ingame_rooms_status: boolean = false;
    freeBingoTickets: [];
    isGlobalFreeTickets: boolean = false;
    bgRooms: any;
    Global: any;
    freeBalltypes: string[] = [];
    freeRooms: string[] = [];
    freeGames: number[] = [];
    playerSegmentValues: any;
    loadCarouselinsideWidget: boolean = true;
    insuranceRoomIds: any;
    isCozyUser: boolean;
    playerSegmentObs: Subscription;
    roomIdsForPrebuyData: number[] = [];
    startTimeRoomIdsMap: Map<number, any> = new Map<number, number>();
    RoomIdsPrebuyData: any;
    ticketRoundIdsAndDisplaytimeArray: any = [];
    roomIdsData: any = [];
    sitNGoIds: any = [];
    isFirstLoaded = true;
    quickInfoScrollY: number;
    preBuyMapWithFirstObjectRoundId: Map<number, any> = new Map<number, any>();
    enable2By1RoomTile: any;
    parallaxInput: any;
    isNextButtonVisible: boolean = true;
    isPreButtonVisible: boolean = true;
    isHomePage: boolean = false;
    storeNextButtonVisible: boolean;
    displaySeeAllOnLobby: boolean;
    cacheConfig: CacheSettingsClientConfig;
    featureConfig: FeaturesClientConfig;
    bingoclientconfig: BingoConfig;
    stopSchedulePollingSubscription: Subscription;
    schedulePollingCall: boolean = false;
    slides: number;
    isSwiperRooms: boolean = false;
    swiperOptionsRoomsList: SwiperOptions = {
        modules: [Navigation, Keyboard],
        loop: false,
        autoHeight: true,
        allowTouchMove: false,
        spaceBetween: 1,
        direction: 'horizontal',
        slidesPerView: 0,
        centeredSlides: false,
        autoplay: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: false,
        touchReleaseOnEdges: false,
        freeMode: false,
        cssMode: false,
        slidesPerGroup: 1,
        keyboard: true,
        watchOverflow: true,
        mousewheel: false,
        scrollbar: false,
        preventClicks: false,
        preventClicksPropagation: false,
        rewind: false,
    };

    constructor(
        private filterAndSortingService: FilterAndSortingService,
        private user: UserService,
        private messageQueueService: MessageQueueService,
        private productHomepagesConfig: ProductHomepagesConfig,
        private scheduleService: ScheduleService,
        private session: SessionStoreService,
        private eventService: EventEmitService,
        private headerService: HeaderService,
        private activeroute: ActivatedRoute,
        private loginService: LoginService,
        private loadingIndicatorService: LoadingIndicatorService,
        private roomsInfoService: RoomsInfoService,
        private navigator: NavigationService,
        private gameLaunchService: GameLaunchService,
        private bingoWidget: BingoWidgetService,
        private router: Router,
        private tracking: TrackingModelService,
        private deviceService: DeviceService,
        private ballfilter: BallFilterPipe,
        private page: Page,
        private ticketsResource: TicketsResourceService,
        private freeBingoTicketService: FreeBingoTicketService,
        private dialog: MatDialog,
        private configProviderService: BingoConfigProviderService,
        private alertMessageService: AlertMessageService,
        private gameLaunch: CasinoCoreGamelaunchService,
        private cdr: ChangeDetectorRef,
    ) {
        this.cacheConfig = this.configProviderService.provideCacheSettingsClientConfig();
        this.bingoclientconfig = this.configProviderService.provideBingoConfig();
        this.featureConfig = this.configProviderService.provideFeaturesConfig();
        this.schedulerefreshtime = this.bingoclientconfig['scheduleLobbyRefreshTimeFrame'];
        // this.carouselConfig = {
        //     grid: { xs: 3, sm: 3, md: 3, lg: 5, all: 0 },
        //     slide: 1,
        //     speed: 400,
        //     point: {
        //         visible: false,
        //     },
        //     loop: false,
        //     touch: false,
        // };
        this.appliedFilterListSubscription = this.filterAndSortingService.appliedFilterList.subscribe((value) => {
            this.appliedFilterList = value;
        });
        this.selectedSortOptionSubscription = this.filterAndSortingService.selectedSortOption.subscribe((value) => {
            this.selectedSortOption = value;
        });
        // this.pinnedRoomId = this.Global.messages.pinnedRoomIds.split(',');
        if (this.activeroute.snapshot['_routerState'].url.includes('menu/launchroom'))
            this.activeroute.params.subscribe((params: any) => {
                this.activeRoomId = params['roomid'];
            });
        else
            this.activeroute.queryParams.subscribe((queryParams: any) => {
                this.activeRoomId = queryParams['room_id'];
                this.preBuyDate = queryParams['date'] ? queryParams['date'] : '';
                this.prebuySlot = queryParams['game_slot'] ? queryParams['game_slot'] : '';
            });
        this.trackingModel = new TrackingModel();
    }
    ngOnInit() {
        this.messageQueueService.clear();
        this.isCozyUser = this.featureConfig['enableNewCozyLobby'];
        if (this.isCozyUser) {
            this.swiperOptionsRoomsList.slidesPerView = 3;
        } else {
            this.swiperOptionsRoomsList.slidesPerView = 5;
            if (window.innerWidth <= 1180 && window.innerWidth >= 780) {
                this.swiperOptionsRoomsList.slidesPerView = 3;
            }
        }
        // this.carouselConfig.grid.lg = this.featureConfig.bingoWidgetGridCount;
        this.displaySeeAllOnLobby = this.featureConfig.displaySeeAllOnLobby;
        if (this.loginService.getSessionBingoForcedUrl() != '' && this.loginService.getSessionBingoForcedUrl()?.includes('sessionbingo')) {
            this.loginService.clearnBingoforcedUrl();
            this.navigator.goTo('/bingo/sessionbingo');
        }
        if (this.loginService.getpackageBingoForcedUrl() != '' && this.loginService.getpackageBingoForcedUrl()?.includes('packagebingo')) {
            this.loginService.clearnBingoforcedUrl();
            this.navigator.goTo('/bingo/packagebingo');
        }
        this.isTouch = this.deviceService.isTouch;
        if (this.isTouch) {
            document.querySelector('.bingolobby-main')?.classList.add('isTouch');
        } else {
            //  document.querySelector('.bingolobby-main').classList.remove('isTouch');
        }
        // if (window.innerWidth >= 768) {
        //     this.isTouch=false;
        // }
        this.isDefaultSortEnabled = this.featureConfig['isAutoFeedSortEnabled'];
        this.enable2By1RoomTile = this.featureConfig['enable2By1RoomTile'];
        this.selectedSortType = this.isDefaultSortEnabled ? 'status.state_end_time' : '';
        this.bingoGameMappings = this.activeroute.snapshot.data['config']?.pageConfig?.value?.bingoGameMappings;
        //this.productHomepagesConfig.bingo;
        this.isMobileView = this.deviceService.isMobilePhone;
        this.bgRooms = this.activeroute.snapshot.data['config']?.rooms;
        this.Global = this.activeroute.snapshot.data['config']?.global;
        this.insuranceRoomIds = this.scheduleService.getInsuranceRoomIds();
        this.parallaxInput = this.activeroute.snapshot.data['config']?.bingoParallax?.parallax
            ? this.activeroute.snapshot.data['config']?.bingoParallax?.parallax[0]
            : null;
        this.activeRoute();
        if (!this.isHomePage) {
            this.headerService.whenReady.subscribe(() => {
                this.headerService.highlightProduct(null);
            });
        } else {
            if (this.featureConfig.enableOptimove && this.user.isAuthenticated) {
                this.loadCarouselinsideWidget = false;
                this.showCarouselForOptimove();
            }
        }
        this.initRoomsData();
        this.schedulepollstatus = this.bingoclientconfig['schedulePagePolling'];
        this.getPreferredBingoRoom();
        this.user.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            this.IsMenuUrl();
            this.initRoomsData();
            this.getPreferredBingoRoom();
            if (this.cacheConfig.preBuy.enableMemoryCache || this.cacheConfig.preBuy.enableDistributedCache) {
                this.scheduleService.triggerPreBuyDatesData();
            }
        });
        this.filteredResultListSubscription = this.filterAndSortingService.filteredResultList.subscribe((value) => {
            this.gameData = value;
            this.setRoomAttributes();
            if (this.featureConfig.enablePrivateRooms) {
                this.checkPrivateRoom();
            }
        });
        this.eventService.getEventObservable().subscribe((event) => {
            if (event == 'filterClicked') {
                this.selectedBallType = 0;
            }
        });
        if (this.router.url.indexOf('myfavourites') > -1) {
            this.favFlag = true;
            this.eventEmitSubscription = this.eventService.getEventObservable().subscribe((event) => {
                if (event.favoriteUpdated) {
                    this.initRoomsData();
                }
            });
        }
        this.activeroute.data.subscribe((routeData) => {
            let roomParam = '';
            if (window.location.href.includes('games-info') && !window.location.href.includes('schedule/games-info')) {
                roomParam = this.router.url.split('?')[0].split('/')[4];
            } else roomParam = routeData.roomId as string;
            if (roomParam != undefined) {
                if (roomParam.includes('-')) {
                    if (roomParam.split('-')[1].toLowerCase() == 'ball') {
                        this.getBallType(+roomParam.split('-')[0]);
                    }
                }
            }
        });
        this.IsMenuUrl();
        if (this.activeroute.snapshot.queryParamMap.get('.box')) {
            this.isInGameClient = true;
        }
        this.setGridsize();
        if (this.featureConfig.bingoPinningRoom) {
            this.assignMultiplePinnedRoomId();
        } else {
            this.assignPinnedRoomId();
        }
        // console.log(this.pinnedRoomId);

        this.getUserFreeBingoTickets();

        this.isQuickInfo();
    }

    isQuickInfo() {
        if (this.isFirstLoaded && window.location.pathname.indexOf('games-info') > -1 && !!this.gameData) {
            this.scheduleDataSubscription = this.scheduleService.scheduleDataObservable.subscribe((response: any) => {
                if (response && this.isFirstLoaded) {
                    this.isFirstLoaded = false;
                    setTimeout(() => {
                        const roomNameFromUrl = decodeURI(window.location.pathname.split('/').splice(-1)[0]);
                        const game: Games = this.gameData.filter((g: Games) => {
                            const gameInfo: any = this.roomsInfoService.getRoomInfo(g.id, this.bgRooms);
                            return gameInfo.title === roomNameFromUrl || g.name === roomNameFromUrl;
                        })[0];
                        if (game) {
                            this.moreInfoClick(null, game, true);
                            this.clearScheduleSubscription();
                        }
                    }, 300);
                }
            });
        }
    }

    moreInfoClick(event: Event | null, game: Games, isLaunchedFromUrl: boolean = false) {
        const roomData = this.roomsInfoService.getRoomInfo(game.id, this.bgRooms);
        const room = game;
        const roomnavlist: any = this.activeroute.snapshot.data['config']?.bingoCategories?.find((x: any) => x.categoryType == 'schedule');
        if (event) {
            event.stopImmediatePropagation();
            this.quickInfoScrollY = window.pageYOffset;
        }
        document.getElementsByTagName('html')[0].classList.add('quickinfo-open');
        const requiredModalData = {
            room,
            bingoItem: room,
            roomTitle: roomData.title.toLowerCase() == 'default' ? room.name : roomData.title,
            roomDescription:
                roomData.description ||
                'Win a lot on the Jack in a Pot slot! This cheeky game lets you win up to 2,000x your stake using Jack the leprechaun’s lucky features',
            src: room?.isCombinedRoom
                ? room.roomImage
                    ? room.roomImage
                    : this.roomsInfoService.getImage(room.combinedVariantName, this.bgRooms)
                : roomData.mobileImage.src,
            position: this.gameData.indexOf(room),
            gridSize: this.gridSize,
            cta: roomnavlist?.columns?.playNowButtonText,
            isLaunchedFromUrl,
            enableNewCozyLobby: true,
        };
        const dialogRef = this.dialog.open(QuickInfoModalComponent, {
            width: '790px',
            data: requiredModalData,
        });

        dialogRef.afterClosed().subscribe(() => {
            if (this.quickInfoScrollY) {
                window.scrollTo(0, this.quickInfoScrollY);
            }
            this.hideInfo();
        });
    }

    hideInfo(): void {
        document.getElementsByTagName('html')[0].classList.remove('quickinfo-open');
        this.clearScheduleSubscription();
    }

    clearScheduleSubscription() {
        if (this.scheduleDataSubscription) this.scheduleDataSubscription.unsubscribe();
    }

    locationFilter() {
        for (let i = 0; i < this.gameData.length; i++) {
            const roomid = this.gameData[i].id;
            this.bgRooms.forEach((data: any) => {
                if (roomid == data.roomId && data.disableRoom) {
                    this.gameData.splice(i, 1);
                    this.scheduleService.setlocationFilterRooms(data.roomId);
                    if (i > 0) i = i - 1;
                }
            });
        }
    }

    showCarouselForOptimove() {
        this.scheduleService.setPlayerSegmentationData();
        this.playerSegmentObs = this.scheduleService.playerSegmentDataObservable.subscribe((data: any) => {
            if (data) {
                this.loadCarouselinsideWidget = true;
            }
        });
    }

    getSitNGoRoomIds() {
        return this.gameData.reduce((ids: any, game: any) => {
            if (!ids.includes(game.id) && game.sit_n_go_enabled) {
                ids.push(game.id);
            }
            return ids;
        }, []);
    }

    assignSitNGoPinning() {
        this.sitNGoRoomIds = [];
        for (let i = 0; i < this.sitNGoIds.length; i++) {
            if (!this.sitNGoRoomIds.includes(Number(this.sitNGoIds[i]))) {
                this.sitNGoRoomIds.push(Number(this.sitNGoIds[i]));
            }
        }
    }

    assignPinnedRoomId() {
        this.bgRooms.forEach((bgRoom: any) => {
            const dateNow = new Date().getTime();
            const fromDate = new Date(bgRoom.fromTime).getTime();
            const toDate = new Date(bgRoom.toTime).getTime();
            if (dateNow - fromDate > 0 && toDate - dateNow > 0) {
                const fromTimeHours = new Date(bgRoom.fromTime).getHours();
                const fromTimeMinutes = new Date(bgRoom.fromTime).getMinutes();
                const toTimeHours = new Date(bgRoom.toTime).getHours();
                const toTimeMinutes = new Date(bgRoom.toTime).getMinutes();
                const hoursNow = new Date().getHours();
                const minutesNow = new Date().getMinutes();
                if (fromTimeHours < toTimeHours) {
                    if (
                        (hoursNow > fromTimeHours || (hoursNow === fromTimeHours && minutesNow > fromTimeMinutes)) &&
                        (toTimeHours > hoursNow || (toTimeHours === hoursNow && toTimeMinutes > minutesNow))
                    ) {
                        if (bgRoom.days) {
                            this.pinnedRoomAssign(bgRoom);
                        }
                    }
                } else {
                    if (
                        ((hoursNow > fromTimeHours || (hoursNow === fromTimeHours && minutesNow > fromTimeMinutes)) && hoursNow <= 23) ||
                        ((hoursNow < toTimeHours || (toTimeHours === hoursNow && toTimeMinutes > minutesNow)) && hoursNow >= 0)
                    ) {
                        if (bgRoom.days) {
                            this.pinnedRoomAssign(bgRoom);
                        }
                    }
                }
            }
        });
    }
    assignMultiplePinnedRoomId() {
        const day = new Date().getDay();
        this.bgRooms.forEach((bgRoom: any) => {
            if (bgRoom.pinningRoomList) {
                bgRoom.pinningRoomList.forEach((slotItem: any) => {
                    if (slotItem && slotItem.days.indexOf(day) > -1) {
                        this.pinningRoomItemData(slotItem, bgRoom);
                    }
                });
            }
        });
    }
    pinningRoomItemData(slotItem: any, bgRoom: any) {
        const dateNow = new Date().getTime();
        const fromDate = new Date(slotItem.fromTime).getTime();
        const toDate = new Date(slotItem.toTime).getTime();
        if (dateNow - fromDate > 0 && toDate - dateNow > 0) {
            const fromTimeHours = new Date(slotItem.fromTime).getHours();
            const fromTimeMinutes = new Date(slotItem.fromTime).getMinutes();
            const toTimeHours = new Date(slotItem.toTime).getHours();
            const toTimeMinutes = new Date(slotItem.toTime).getMinutes();
            const hoursNow = new Date().getHours();
            const minutesNow = new Date().getMinutes();
            if (fromTimeHours < toTimeHours) {
                if (
                    (hoursNow > fromTimeHours || (hoursNow === fromTimeHours && minutesNow > fromTimeMinutes)) &&
                    (toTimeHours > hoursNow || (toTimeHours === hoursNow && toTimeMinutes > minutesNow))
                ) {
                    if (slotItem.pinto1) {
                        this.pinnedRoomId.unshift(Number(bgRoom.roomId));
                    } else {
                        this.pinnedRoomId.push(Number(bgRoom.roomId));
                    }
                }
            } else {
                if (
                    ((hoursNow > fromTimeHours || (hoursNow === fromTimeHours && minutesNow > fromTimeMinutes)) && hoursNow <= 23) ||
                    ((hoursNow < toTimeHours || (toTimeHours === hoursNow && toTimeMinutes > minutesNow)) && hoursNow >= 0)
                ) {
                    if (slotItem.pinto1) {
                        this.pinnedRoomId.unshift(Number(bgRoom.roomId));
                    } else {
                        this.pinnedRoomId.push(Number(bgRoom.roomId));
                    }
                }
            }
        }
    }
    pinnedRoomAssign(bgRoom: any) {
        const weeks: string[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const Days: string[] = bgRoom.days.split(',');
        for (let i = 0; i < weeks.length; i++) {
            for (let j = 0; j < Days.length; j++) {
                if (weeks[i].toLowerCase() === Days[j].toLowerCase()) {
                    const dayNow = new Date().getDay();
                    if (i === dayNow) {
                        if (bgRoom.pinto1) {
                            this.pinnedRoomId.unshift(Number(bgRoom.roomId));
                        } else {
                            this.pinnedRoomId.push(Number(bgRoom.roomId));
                        }
                    }
                }
            }
        }
    }

    getPreferredBingoRoom() {
        this.scheduleService.playerSegmentDataObservable.subscribe((data: any) => {
            if (data != null && data.playerValueSegment != null) {
                this.playerSegmentValues = data.playerValueSegment;
                this.loadCarouselinsideWidget = true;
                if (data.playerValueSegment.preferredBingoRoom) this.prefeeredRoomName = data.playerValueSegment.preferredBingoRoom;
                this.setRoomAttributes();
                if (this.featureConfig.enablePrivateRooms) {
                    this.checkPrivateRoom();
                }
            }
        });
    }
    getUserFreeBingoTickets() {
        // New implementation
        this.freeBingoTicketService.freeBingoTicketsDataSubject.subscribe((ticketsData: any) => {
            this.freeBalltypes = [];
            this.freeRooms = [];
            this.isGlobalFreeTickets = false;
            this.roomIdsData = [];
            if (ticketsData && ticketsData.length > 0) {
                this.getPreBuyInfoForFreeBingoRooms(ticketsData);
            }
        });
    }
    getPreBuyInfoForFreeBingoRooms(ticketsData: any) {
        if (ticketsData && ticketsData.length > 0) {
            ticketsData.forEach((ticket: any) => {
                if (ticket.availableTickets > 0 && new Date(ticket.expiryDate).getTime() > new Date().getTime() && ticket.gameDtls?.length > 0) {
                    ticket.gameDtls.forEach((ticketDetail: any) => {
                        if (ticketDetail?.roundId != null && this.roomIdsData.indexOf(ticketDetail.roomId) == -1) {
                            this.roomIdsData.push(ticketDetail.roomId);
                        }
                    });
                }
            });
            if (this.roomIdsData.length > 0) {
                this.freeBingoTicketsRoundIdMap(ticketsData);
            } else {
                this.setFreeBingoTickets(ticketsData);
            }
        }
    }
    freeBingoTicketsRoundIdMap(ticketsData: any) {
        const from_time = Math.floor(new Date().getTime() / 1000);
        const end_time = Math.floor(new Date().getTime() / 1000 + this.featureConfig.preBuyTimeDuration * 60);
        this.ticketsResource.getPrebuyDataforConfigTime(this.roomIdsData, from_time, end_time).subscribe((data: any) => {
            if (data && data.result && data.result.schedule) {
                this.RoomIdsPrebuyData = data.result.schedule;
                for (let i = 0; i < this.roomIdsData.length; i++) {
                    const preBuyRoomData: any[] = this.RoomIdsPrebuyData[this.roomIdsData[i]];
                    if (preBuyRoomData && preBuyRoomData.length > 0) {
                        const obj = {
                            roomId: this.roomIdsData[i],
                            roundId: preBuyRoomData[0].round_id,
                        };
                        this.preBuyMapWithFirstObjectRoundId.set(this.roomIdsData[i], obj);
                    }
                }
                this.setFreeBingoTickets(ticketsData);
            } else {
                this.setFreeBingoTickets(ticketsData);
            }
        });
    }
    setFreeBingoTickets(ticketsData: any) {
        if (ticketsData && ticketsData.length > 0) {
            ticketsData.forEach((ticket: any) => {
                if (ticket.availableTickets > 0 && new Date(ticket.expiryDate).getTime() > new Date().getTime()) {
                    if (ticket.gameDtls == null) this.isGlobalFreeTickets = true;
                    else {
                        ticket.gameDtls.forEach((ticketDetail: any) => {
                            if (
                                ticketDetail &&
                                ticketDetail.roundId == null &&
                                (ticketDetail.roomId == null || ticketDetail.roomId == 0) &&
                                ticketDetail.gameTypeId != null &&
                                this.freeBalltypes.indexOf(ticketDetail.gameTypeId.toString()) == -1
                            ) {
                                this.freeBalltypes.push(ticketDetail.gameTypeId.toString());
                            } else if (
                                ticketDetail &&
                                ticketDetail.roundId == null &&
                                (ticketDetail.roomId != null || ticketDetail.roomId != 0) &&
                                this.freeBalltypes.indexOf(ticketDetail.gameTypeId.toString()) == -1 &&
                                this.freeRooms.indexOf(ticketDetail.roomId.toString()) == -1
                            ) {
                                this.freeRooms.push(ticketDetail.roomId.toString());
                            } else if (ticketDetail && ticketDetail.roundId != null) {
                                const preBuyObj = this.preBuyMapWithFirstObjectRoundId.get(ticketDetail.roomId);
                                if (ticketDetail.roundId.length == ticketDetail.displayTimeInEpoch.length) {
                                    for (let i = 0; i < ticketDetail.roundId.length; i++) {
                                        const displayTimeInEpoch = new Date(ticketDetail.displayTimeInEpoch[i]).getTime();
                                        if (
                                            preBuyObj &&
                                            preBuyObj.roundId == ticketDetail.roundId[i] &&
                                            displayTimeInEpoch >= new Date().getTime() &&
                                            this.freeRooms.indexOf(ticketDetail.roomId.toString()) == -1
                                        ) {
                                            this.freeRooms.push(ticketDetail.roomId.toString());
                                        }
                                    }
                                } else {
                                    for (let i = 0; i < ticketDetail.roundId.length; i++) {
                                        const displayTimeInEpoch = new Date(ticketDetail.displayTimeInEpoch[0]).getTime();
                                        if (
                                            preBuyObj &&
                                            preBuyObj.roundId == ticketDetail.roundId[i] &&
                                            displayTimeInEpoch >= new Date().getTime() &&
                                            this.freeRooms.indexOf(ticketDetail.roomId.toString()) == -1
                                        ) {
                                            this.freeRooms.push(ticketDetail.roomId.toString());
                                        }
                                    }
                                }
                            }
                        });
                    }
                }
            });
            this.setRoomAttributes();
            if (this.featureConfig.enablePrivateRooms) {
                this.checkPrivateRoom();
            }
        }
    }

    setRoomAttributes() {
        if (this.gameData) {
            const checktoLoadCarousel = this.user.isAuthenticated && this.featureConfig.enableOptimove && this.isHomePage && this.playerSegmentValues;
            this.locationFilter();
            for (let i = 0; i < this.gameData.length; i++) {
                let removeItem: boolean = false;
                this.gameData[i].isPreferredRoom = this.gameData[i].feedname == this.prefeeredRoomName;
                if (this.gameData[i].features.indexOf('free_tickets') == -1) {
                    if (this.isGlobalFreeTickets) this.gameData[i].features.push('free_tickets');
                    else if (this.freeBalltypes && this.freeBalltypes.indexOf(this.gameData[i].gvc_game_type_id) != -1)
                        this.gameData[i].features.push('free_tickets');
                    else if (this.freeRooms && this.freeRooms.indexOf(this.gameData[i].id) != -1) this.gameData[i].features.push('free_tickets');
                }
                if (checktoLoadCarousel) {
                    const siteCoreRoom = this.roomsInfoService.getRoomInfo(this.gameData[i].id, this.bgRooms);
                    const isLockedRoom = !this.scheduleService.segmentationRules(siteCoreRoom, this.playerSegmentValues);
                    if (isLockedRoom && !siteCoreRoom.display) {
                        //this.gameData.splice(i, 1);
                        removeItem = true;
                    }
                }
                if (this.featureConfig.enablePrivateRooms && this.gameData[i].game_tags && this.gameData[i].game_tags.length > 0) {
                    if (this.gameData[i].game_tags.indexOf('newbie') > -1 || this.gameData[i].game_tags.indexOf('private') > -1) {
                        if (!this.user.isAuthenticated && this.gameData[i].game_tags.indexOf('prelogin-display') > -1) {
                            removeItem = false;
                        } else {
                            if (!this.gameData[i].private_room) {
                                if (this.gameData[i].game_tags.indexOf('postlogin-display') < 0) {
                                    //this.gameData.splice(i, 1);
                                    removeItem = true;
                                }
                            }
                        }
                    }
                }

                if (this.featureConfig.enablePrivateRooms && !this.user.isAuthenticated) {
                    if (this.gameData[i].game_tags && this.gameData[i].game_tags.length > 0) {
                        if (
                            this.gameData[i].game_tags.indexOf('newbie') > -1 ||
                            (this.gameData[i].game_tags.indexOf('private') > -1 && this.gameData[i].game_tags.indexOf('prelogin-display') < 0)
                        ) {
                            removeItem = true;
                        }
                    }
                }
                if (removeItem) {
                    this.gameData.splice(i, 1);
                    i = i - 1;
                }
            }
            if (this.gameData.length > this.featureConfig.bingoWidgetGridCount) {
                if (this.storeNextButtonVisible) {
                    this.isNextButtonVisible = true;
                } else {
                    this.isNextButtonVisible = false;
                }
            }
            this.update.emit(this.gameData);
        }
    }
    checkPrivateRoom() {
        for (let i = 0; i < this.gameData.length; i++) {
            const bingoItem = this.gameData[i];
            if (bingoItem.game_tags && bingoItem.game_tags.length > 0) {
                bingoItem.displayPrivateRoom = false;
                bingoItem.privateRoom = false;
                bingoItem.game_tags.forEach((item: string) => {
                    if (item == 'private' || item == 'newbie') {
                        if (!bingoItem.private_room) bingoItem.privateRoom = true;
                    }
                    if (item == 'postlogin-display') {
                        bingoItem.displayPrivateRoom = true;
                    }
                    if (item == 'prelogin-display' && !this.user.isAuthenticated) {
                        bingoItem.displayPrivateRoom = true;
                        if (this.isCozyUser) bingoItem.privateRoom = true;
                    }
                });
            }
        }
    }

    @HostListener('window:resize', [])
    onResize() {
        this.setGridsize();
    }
    setGridsize() {
        if (this.deviceService.isMobile) this.gridSize = 1;
        else if (this.deviceService.isTablet && window.innerWidth <= 768) this.gridSize = 3;
        else if (this.deviceService.isTablet) this.gridSize = 4;
        else if (window.innerWidth < 1201) this.gridSize = 4;
        else this.gridSize = 5;
    }
    ReloadFeed() {
        this.isSwiperRooms = false;
        let timeOut = 0;
        const curentTime = new Date().getTime();
        if (this.gameData && this.gameData.length != 0) {
            const games = this.gameData.filter((g: any) => {
                return g.status.state_end_time != null && new Date(g.status.state_end_time * 1000).getTime() > curentTime;
            });
            const f_game = Math.min.apply(
                Math,
                games.map((g: any) => {
                    return g.status.state_end_time;
                }),
            );
            if (f_game) timeOut = Math.max(0, new Date(f_game * 1000).getTime() - curentTime);
        }
        if (this.schedulepollstatus) {
            if (timeOut > this.schedulerefreshtime || timeOut == 0) timeOut = this.schedulerefreshtime;
        }
        if (timeOut > 0)
            this.schedulePolling = setTimeout(() => {
                this.initRoomsDataWithFilters();
                this.scheduleService.setPlayerSegmentationData();
                this.freeBingoTicketService.setUserFreeBingoTickets();
            }, timeOut);
        this.cdr.detectChanges();
        this.isSwiperRooms = true;
    }
    IsMenuUrl() {
        if (this.activeroute.snapshot['_routerState'].url.includes('menu')) {
            //loader indication
            if (!this.loginService.validateUser(this.activeroute.snapshot['_routerState'].url)) {
                return;
            }
            this._loadingIndicator = this.loadingIndicatorService.start();
            this.open_popup_box = setInterval(() => this.checkPrebuy(), 1000);
        }
    }

    activeRoute() {
        //let activeRoute: string = this.navigation.location.path();
        const HomeView = this.activeroute.snapshot.data['Home'] || this.activeroute?.snapshot?.routeConfig?.path?.includes('bingowidget/cozybingo');
        if (HomeView) {
            this.isHomePage = true;
            this.isHome = this.showVerticalRoomsInHomePage ? false : this.isHomePage;
        }
        const iframe_ingame_status = this.activeroute.snapshot.queryParamMap.get('ingame');
        if (iframe_ingame_status !== null && iframe_ingame_status !== undefined) {
            if (iframe_ingame_status) {
                this.ingame_rooms_status = true;
            }
        }
    }
    postWidgetHeight(): void {
        setTimeout(() => {
            this.postHeight();
        }, 500);
    }
    postHeight(): void {
        if (this.bingoWidget.isBingoWidget()) {
            const widget = document.getElementById('bingoWidget');
            if (widget) {
                this.currentWidgetHeight = widget.scrollHeight;
                const params = {
                    height: this.currentWidgetHeight,
                    userStatus: true,
                    isBingoWidgetCozyFlow: !this.isCozyUser,
                };
                if (this.gameData && this.gameData.length > 0) this.bingoWidget.postMessage('BingowidgetLoaded', params);
            }
        }
    }

    checkPrebuy(): any {
        if (this.gameData && this.gameData[0] != null) {
            clearInterval(this.open_popup_box);
            let result = '';
            let position = 0;
            const roomId = this.activeRoomId ? this.activeRoomId : this.gameData[0].id;
            let gameLaunchApproved: boolean = true;
            if (this.activeroute.snapshot['_routerState'].url.includes('menu/launchroom')) {
                this.tempRoom = this.gameData.filter((g: Games) => {
                    return g.id === roomId;
                })[0];
                position = this.gameData.indexOf(this.tempRoom);
                if (this.tempRoom == undefined || (this.tempRoom && this.tempRoom.disableGamelaunchOnGreyOut)) {
                    gameLaunchApproved = false;
                }
            }
            let lobbyUrl = this.activeroute.snapshot.data['config']?.pageConfig?.value?.lobbyUrl?.url;
            if (!this.activeroute.snapshot['_routerState'].url.includes('menu/prebuy')) {
                lobbyUrl =
                    lobbyUrl +
                    (this.activeroute.snapshot['_routerState'].url.indexOf('?') > -1
                        ? '?' + this.activeroute.snapshot['_routerState'].url.split('?')[1]
                        : '');
            }
            lobbyUrl = encodeURIComponent(lobbyUrl);
            const lockedRoomIds: any = this.scheduleService.lockedRoomIdsArray;
            const locationFilterIds: any = this.scheduleService.getlocationFilterRooms();
            for (const val in lockedRoomIds) {
                if (lockedRoomIds[val] == roomId) {
                    gameLaunchApproved = false;
                }
            }
            for (const val in locationFilterIds) {
                if (locationFilterIds[val] == roomId) {
                    gameLaunchApproved = false;
                }
            }
            if (!gameLaunchApproved && !this.activeroute.snapshot['_routerState'].url.includes('menu/mytickets')) {
                let apiUrl: string = '';
                apiUrl = this.productHomepagesConfig.bingo + '/bingo/rooms';
                this.navigator.goTo(apiUrl);
                clearInterval(this.open_popup_box);
                this._loadingIndicator.done();
                clearInterval(this.open_popup_box);
                // this.messageQueueService.clear();
                // this.messageQueueService.addError(this.Global.messages['gameLaunchErrorMessage']);
                this.alertMessageService.info('checkmark_kick', this.Global.messages['invalidRoomIdErrorMessage']);
            } else {
                if (this.activeroute.snapshot['_routerState'].url.includes('menu/mytickets'))
                    result =
                        this.activeroute.snapshot.data['config']?.pageConfig?.value?.bingoHomePage?.url +
                        '/bingo/forcedprebuy' +
                        '?selected_room_id=' +
                        roomId +
                        '&tabSelectedIndex=' +
                        1 +
                        '&lobbyUrl=' +
                        lobbyUrl;
                else if (this.activeroute.snapshot['_routerState'].url.includes('menu/prebuy'))
                    result =
                        this.activeroute.snapshot.data['config']?.pageConfig?.value?.bingoHomePage?.url +
                        '/bingo/forcedprebuy' +
                        '?selected_room_id=' +
                        roomId +
                        '&tabSelectedIndex=' +
                        0 +
                        '&lobbyUrl=' +
                        lobbyUrl +
                        '&date=' +
                        this.preBuyDate +
                        '&gameslot=' +
                        this.prebuySlot;
                else if (this.activeroute.snapshot['_routerState'].url.includes('menu/launchroom')) {
                    if (this.navigator.location.search.has('returnUrl')) {
                        lobbyUrl = this.navigator.location.search.get('returnUrl');
                    }
                    this.gameLaunchService.initiateGameLaunch(
                        this.tempRoom.name,
                        'Bingo',
                        'Bingo',
                        this.tempRoom.bingo_type,
                        'REGULAR',
                        this.roomsInfoService.getImage(this.tempRoom.id, this.bgRooms),
                        this.tempRoom.id,
                        this.tempRoom.isFavourite,
                        position,
                        1,
                        lobbyUrl,
                        '',
                        this.tempRoom.themeid,
                        null,
                    );
                }

                this._loadingIndicator.done();
                if (result != '') {
                    this.navigator.goTo(result);
                }
                clearInterval(this.open_popup_box);
            }
        }
    }
    getBallType(val: number) {
        this.selectedBallType = val;
    }

    trackRoomsData(_index: number, gameData: Games) {
        return gameData.name + gameData.status.state_end_time;
    }

    initRoomsData() {
        let stopSchedulepollingCall = (window as any).Disable_SchedulePolling ? (window as any).Disable_SchedulePolling : false;
        stopSchedulepollingCall = this.schedulePollingCall ? this.schedulePollingCall : stopSchedulepollingCall;
        if (this.schedulePolling) clearInterval(this.schedulePolling);
        if (stopSchedulepollingCall) {
            this.ReloadFeed();
        } else {
            this.scheduleService.getGameData((response: any) => {
                if (response && response.result && response.result.games_list) {
                    this.initData = response;
                    this.session.set('ScheduleFeed', this.initData);
                    this.getGameDetails();
                    this.postHeight();
                    this.eventService.raiseEvent('dataLoaded');
                    this.showLoading = false;
                    this.ReloadFeed();
                    // if (this.bingoWidget.isBingoWidget) {
                    //     this.postWidgetHeight();
                    // }
                }
            });
        }
    }

    initRoomsDataWithFilters() {
        let stopSchedulepollingCall = (window as any).Disable_SchedulePolling ? (window as any).Disable_SchedulePolling : false;
        stopSchedulepollingCall = this.schedulePollingCall ? this.schedulePollingCall : stopSchedulepollingCall;
        if (this.schedulePolling) clearInterval(this.schedulePolling);
        if (stopSchedulepollingCall) {
            this.ReloadFeed();
        } else {
            this.scheduleService.getGameData((response: any) => {
                this.initData = response;
                this.session.set('ScheduleFeed', this.initData);
                this.getGameDetails();
                if (this.appliedFilterList) {
                    this.gameData = this.filterAndSortingService.filterItemsByCatagory(this.appliedFilterList, this.gameData);
                }
                this.ReloadFeed();
            });
        }
    }
    RoomMouseEnter(data: any) {
        setTimeout(() => {
            data.hover = true;
        }, 100);
    }

    getGameDetails() {
        const forcedGameLaunchErrorCode = this.session.get('GameLaunchErrorCode');
        if (forcedGameLaunchErrorCode && forcedGameLaunchErrorCode != '') {
            let errorMessage = this.Global.messages.forcedGameLaunchErrorCode;
            if (!errorMessage) {
                errorMessage = this.Global.messages['gameLaunchErrorMessage'];
            }
            this.messageQueueService.addError(errorMessage);
            this.session.remove('GameLaunchErrorCode');
        }
        if (this.initData == null || this.initData.result == null) {
            this.isFeedEmpty = true;
            this.messageQueueService.clear();
            this.messageQueueService.addError(this.Global.errors['scheduleDataFetchError']);
        } else {
            //Get Games Data from Web API
            this.gameData = this.initData['result']['games_list'];
            this.gameData = this.ballfilter.transform(this.gameData, this.selectedBallType);
            // this.gameData = null;
            if (this.gameData && this.gameData.length == 0) {
                this.isFeedEmpty = true;
                this.messageQueueService.clear();
                this.messageQueueService.addError(this.Global.messages['RoomDataUnavailable']);
                return true;
            }
            if (this.isFeedEmpty && this.gameData && this.gameData.length > 0) {
                this.isFeedEmpty = false;
                this.messageQueueService.clear();
            }
            if (this.user.isAuthenticated) {
                this.favouriteInfo = this.initData['result']['favouriteRooms'];
            }
            if (this.user.isAuthenticated && this.favouriteInfo && this.favouriteInfo.length > 0) {
                this.gameData.forEach((bingoItem: Games) => {
                    if (bingoItem.feature_details && bingoItem.feature_details.multi_price) {
                        if (bingoItem.feature_details.multi_price?.default_multi_price) {
                            bingoItem.card_price_in_player_currency.Cash = bingoItem.feature_details.multi_price.default_multi_price;
                            bingoItem.pot_in_player_currency.Cash = bingoItem.feature_details.multi_price?.winning_amount.filter(function (e: any) {
                                return e.price == bingoItem.feature_details.multi_price.default_multi_price;
                            })[0]?.winning_amount?.Cash;
                        } else {
                            bingoItem.card_price_in_player_currency.Cash = bingoItem.feature_details.multi_price?.card_costs[0];
                            bingoItem.pot_in_player_currency.Cash = bingoItem.feature_details.multi_price?.winning_amount[0]?.winning_amount?.Cash;
                        }
                    }
                    if (!bingoItem.isCombinedRoom) {
                        bingoItem.isFavourite = this.favouriteInfo.indexOf(bingoItem.id) > -1;
                    }
                    bingoItem.hover = false;
                    if (this.favFlag) {
                        this.gameData = this.gameData.filter((game) => {
                            return game.isFavourite == true;
                        });
                    }
                    // if (bingoItem.features.indexOf('multi_price') > -1) this.roomTracking(bingoItem);
                });
            } else {
                this.gameData.forEach((a: any) => {
                    if (a.feature_details && a.feature_details.multi_price) {
                        if (a.feature_details.multi_price?.default_multi_price) {
                            a.card_price_in_player_currency.Cash = a.feature_details.multi_price.default_multi_price;
                            a.pot_in_player_currency.Cash = a.feature_details.multi_price?.winning_amount.filter(function (e: any) {
                                return e.price == a.feature_details.multi_price.default_multi_price;
                            })[0]?.winning_amount?.Cash;
                        } else {
                            a.card_price_in_player_currency.Cash = a.feature_details.multi_price?.card_costs[0];
                            a.pot_in_player_currency.Cash = a.feature_details.multi_price?.winning_amount[0]?.winning_amount?.Cash;
                        }
                    }
                    a.isFavourite = false;
                    a.hover = false;
                    // if (a.features.indexOf('multi_price') > -1) this.roomTracking(a);
                });
                if (this.favFlag) {
                    this.gameData = [];
                }
            }
            if (this.favFlag && this.gameData.length == 0) {
                this.messageQueueService.clear();
                this.messageQueueService.addInfo(this.Global.messages['noFavouriteItems']);
                return true;
            }
        }
        this.sitNGoIds = this.getSitNGoRoomIds();
        this.assignSitNGoPinning();
        this.setRoomAttributes();
        this.assignMultipleStickerRoomId();
        if (this.featureConfig.enablePrivateRooms) {
            this.checkPrivateRoom();
        }
        this.roomsHasValue = this.gameData && this.gameData.length > 0;
        return false;
    }

    // roomTracking(room: Games) {
    //     this.trackingModel.eventName = 'Event.Tracking';
    //     this.trackingModel.LabelEvent = 'Games';
    //     this.trackingModel.ActionEvent = 'Displayed';
    //     this.trackingModel.PositionEvent = room.name;
    //     this.trackingModel.LocationEvent = this.isHomePage ? 'Home' : this.isInGameClient ? 'InGameClient' : 'Lobby';
    //     this.trackingModel.EventDetails = this.isHomePage ? 'Home' : this.isInGameClient ? 'InGameClient' : 'Rooms';
    //     this.tracking.submitTracking(this.trackingModel);
    // }

    // onmoveFn(event: NguCarouselStore) {
    //     this.isPreButtonVisible = event.isFirst;
    //     this.isNextButtonVisible = event.isLast;
    //     this.storeNextButtonVisible = event.isLast;
    // }

    ngAfterViewInit() {
        setTimeout(() => {
            if (window.location.pathname.includes('-ball') && this.featureConfig.enableScrollForRoomFilters) {
                const ele: HTMLElement = document.querySelector('#bingoWidget')!;
                window.scrollTo({
                    top: ele.offsetTop - 40,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }, this.featureConfig.timeOutForRoomsFilter);
    }
    ngOnDestroy() {
        if (this.filteredResultListSubscription) {
            this.filteredResultListSubscription.unsubscribe();
        }
        if (this.selectedSortOptionSubscription) {
            this.selectedSortOptionSubscription.unsubscribe();
        }
        if (this.appliedFilterListSubscription) {
            this.appliedFilterListSubscription.unsubscribe();
        }
        if (this.eventEmitSubscription) {
            this.eventEmitSubscription.unsubscribe();
        }
        if (this.schedulePolling) clearInterval(this.schedulePolling);
        this.clearScheduleSubscription();
    }
    getSortType(value: string) {
        this.selectedSortType = value;
    }
    gotToRooms() {
        this.eventService.raiseEvent({ navigateToUrl: '/bingo/rooms' });
        //this.navigation.goTo('/bingo/rooms');
    }

    assignMultipleStickerRoomId() {
        const day = new Date().getDay();
        this.gameData.forEach((game: any) => {
            if (this.featureConfig.enableStickerSchedule) {
                game.enableStickerForRoom = false;
                const bgRoomData = this.bgRooms.filter((e: any) => {
                    return e.roomId == game.id;
                });
                if (bgRoomData && bgRoomData.length > 0) {
                    const stickerRoomList = bgRoomData[0].stickerRoomList;
                    stickerRoomList.forEach((slotItem: any) => {
                        if (slotItem && slotItem.days && slotItem.days.indexOf(day) > -1) {
                            this.stickerRoomItemData(slotItem, bgRoomData, game);
                        }
                    });
                }
            } else {
                game.enableStickerForRoom = true;
            }
        });
    }

    dateConversion(date: any) {
        const convertedDate = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
        );
        return convertedDate;
    }

    stickerRoomItemData(slotItem: any, bgRoom: any, game: any) {
        const dateNow = new Date().getTime() / 1000;
        let fromDate = new Date(new Date(slotItem.fromTime));
        fromDate = this.dateConversion(fromDate);
        let toDate = new Date(new Date(slotItem.toTime));
        toDate = this.dateConversion(toDate);
        const from_time = fromDate.getTime() / 1000;
        const to_time = toDate.getTime() / 1000;
        if (dateNow - from_time > 0 && to_time - dateNow > 0) {
            if (slotItem.stickerEnable) {
                game.enableStickerForRoom = true;
            }
        }
    }
    @HostListener('window:message', ['$event']) handleCallback(event: MessageEvent): void {
        if (event?.data && event?.data?.stopSchedulePolling == true) {
            this.schedulePollingCall = true;
            if (this.schedulePolling) {
                clearInterval(this.schedulePolling);
            }
        } else if (event?.data && event?.data?.stopSchedulePolling == false) {
            this.schedulePollingCall = false;
            this.initRoomsData();
        }
    }
}
