import { NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { CasinoPlatformLoaderModule } from '@casinocore/loader';
import { STORE_PREFIX } from '@frontend/vanilla/core';
import { LoginDurationComponent } from '@frontend/vanilla/features/login-duration';
import { GlobalSearchModule } from '@globalsearch/productstub';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { BingoWidgetModule } from './BingoWidget/bingo-widget.module';
import { appRoutes } from './app.routes';
import { CasinoCategoryModule } from './casino-category/casino-category.module';
import { ForceRedirectModule } from './force-redirect/force-redirect.module';
import { GameIframeLauncherModules } from './game-Iframe-launcher/game-Iframe-launcher.module';
import { HomeModule } from './home/home.module';
import { HtmlModule } from './html/html.module';
import { MainModule } from './main/main.module';
import { MyFavouriteModule } from './my-favourite/my-favourite.module';
import { HammerConfig } from './myHammer.config';
import { ParallaxAnimationModule } from './parallax-animation/parallax-animation.module';
import { ConfigResolver } from './resolver/config-resolver.component';
import { RootServicesModule } from './shared/root-services.module';
import { TicketsModule } from './tickets/tickets.module';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatProgressBarModule,
        RouterModule.forRoot(appRoutes),
        HomeModule.forRoot(),
        HtmlModule.forRoot(),
        MainModule,
        MyFavouriteModule.forRoot(),
        ForceRedirectModule.forRoot(),
        TicketsModule,
        RootServicesModule,
        GlobalSearchModule.forRoot(),
        BingoWidgetModule.forRoot(),
        LazyLoadImageModule,
        GameIframeLauncherModules.forRoot(),
        ParallaxAnimationModule.forRoot(),
        CasinoCategoryModule.forRoot(),
        LoginDurationComponent,
        HammerModule,
        CasinoPlatformLoaderModule.forRoot(),
        StoreModule.forRoot(
            {},
            {
                runtimeChecks: {
                    strictStateImmutability: false,
                    strictActionImmutability: false,
                    strictStateSerializability: false,
                    strictActionSerializability: false,
                },
            },
        ),
        EffectsModule.forRoot([]),
    ],
    providers: [
        ConfigResolver,
        { provide: STORE_PREFIX, useValue: 'bingo.' },
        { provide: MatDialogRef, useValue: {} },
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
        { provide: MAT_DIALOG_DATA, useValue: [] },
    ],
    //bootstrap: [AppComponent],
    declarations: [],
    exports: [],
})
export class AppModule {}
