import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BingoConfigProviderService, GameLaunchService, SuperLinksClientConfig } from '@frontend/bingo/core';

@Component({
    selector: 'bg-super-link-popup',
    templateUrl: './super-link-popup.component.html',
})
export class SuperLinkPopupComponent implements OnInit {
    superLinkText: string;
    superLinksClientConfig: SuperLinksClientConfig;
    constructor(
        public dialogRef: MatDialogRef<SuperLinkPopupComponent>,
        private gameLaunchService: GameLaunchService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private configProviderService: BingoConfigProviderService,
    ) {
        this.superLinksClientConfig = this.configProviderService.provideSuperLinksClientConfig();
    }

    ngOnInit(): void {
        this.superLinkText = this.superLinksClientConfig.superlinkText;
    }
    closeModal() {
        document.getElementsByTagName('html')[0]?.classList?.remove('superLinkPopup-open');
        this.dialogRef.close();
    }

    launchGame() {
        this.closeModal();
        if (this.data.bingoItem && !this.data.bingoItem.prebuy_enabled && this.data.bingoItem.isSuperLink) {
            this.gameLaunchService.initiateGameLaunch(
                this.data.bingoItem?.name,
                'Bingo',
                'Bingo',
                this.data.bingoItem?.bingo_type,
                'REGULAR',
                this.data.src,
                this.data.bingoItem?.id,
                this.data.bingoItem?.isFavourite,
                this.data.position,
                this.data.gridSize,
                '',
                '',
                this.data.bingoItem.themeid,
            );
        }
    }
}
