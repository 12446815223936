import { Injectable } from '@angular/core';

import { BingoPlatformApiService } from '@frontend/bingo/core';
import { BehaviorSubject, Observable, map, shareReplay } from 'rxjs';

import { RootServicesModule } from '../shared/root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class TeaserService {
    teaserSiteCoresData: any;
    teaserFeed: any;
    teaserSiteCoreData: Observable<any>;
    optimizedTeasersData: Observable<any> | undefined | null; //to be used for both normal and optimized teasers
    optimizedMiniTeasersData: Observable<any> | undefined | null;
    public mercuryBgImage: any;
    optMiniTeasersMap: Map<string, any> = new Map<string, any>();
    private optMiniTeasersMapPublisher = new BehaviorSubject<boolean>(false);
    optMiniTeasersMapObservable: Observable<boolean> = this.optMiniTeasersMapPublisher.asObservable();

    constructor(private api: BingoPlatformApiService) {}

    getTeaserData(isOptimizedTeaserEnabled: any = false): Observable<any> {
        if (isOptimizedTeaserEnabled) {
            if (!this.optimizedTeasersData) {
                this.optimizedTeasersData = this.api.get('getOptimizedTeaser').pipe(
                    map((response: any) => response),
                    shareReplay(1),
                );
            }
            return this.optimizedTeasersData;
            //return this.api.get('getOptimizedTeaser');
        } else {
            if (!this.optimizedTeasersData) {
                this.optimizedTeasersData = this.api.get('NPMteaser').pipe(
                    map((response: any) => response),
                    shareReplay(1),
                );
            }
            return this.optimizedTeasersData;
        }
    }
    getTeaserDataFromSitecore(): Observable<any> {
        if (!this.teaserSiteCoreData) {
            this.teaserSiteCoreData = this.api.get('getTeaserDataFromSitecore').pipe(
                map((response: any) => response),
                shareReplay(1),
            );
        }
        return this.teaserSiteCoreData;
        //return this.api.get('getTeaserDataFromSitecore');
    }
    getFilterOptmizedTeasers(teaserData: any, teaserConfig: any, embeddedPosition: any, isDefaultCateogory: any, filterdTeasersSiteCoreData: any) {
        const maxRenderedTeasers = teaserConfig.maxRenderedTeasers;
        const maxTeasersToBeRendered: number = maxRenderedTeasers > 0 ? maxRenderedTeasers : 999;
        const filteredOptiTeaserData: any = [];
        const teaserResponse: any = teaserData;
        const displaymode = this.getDisplayMode(isDefaultCateogory, embeddedPosition);
        if (teaserResponse) {
            const optimizedHeaderTeasers: any = teaserResponse.optimizedTeaserContents;
            //let activeCategoryfromLmt = this.categoryManager.getLMTMap(this.lobbyModel.activeCategory);
            if (
                filterdTeasersSiteCoreData &&
                filterdTeasersSiteCoreData.showTeasers &&
                filterdTeasersSiteCoreData.optimizedTeasers &&
                filterdTeasersSiteCoreData.optimizedTeasers.length > 0 &&
                //&& activeCategoryfromLmt && activeCategoryfromLmt.categoryid
                optimizedHeaderTeasers &&
                optimizedHeaderTeasers.length > 0 &&
                displaymode != null
            ) {
                for (let i = 0; i < filterdTeasersSiteCoreData.optimizedTeasers.length; i++) {
                    const optimizedHeader: any = filterdTeasersSiteCoreData.optimizedTeasers[i];
                    const filteredTeaser: any = optimizedHeaderTeasers.filter((ot: any) => ot.data.metadata.id.parent.path === optimizedHeader.path);
                    if (filteredTeaser && filteredTeaser.length > 0) {
                        filteredTeaser.forEach((teaserItem: any) => {
                            if (displaymode == teaserItem.displayMode && filteredOptiTeaserData.length < maxTeasersToBeRendered) {
                                filteredOptiTeaserData.push(teaserItem);
                            }
                        });
                    }
                }
            }
        }
        return filteredOptiTeaserData;
    }
    getNormalTeasersFilteredData(teasersData: any, isDefaultCateogory: any, embeddedPosition: any, filterdTeasersSiteCoreData: any) {
        let filteredTeasers: any = [];
        teasersData.forEach((headerTeaser: any) => {
            const index = filterdTeasersSiteCoreData.teasers.indexOf(
                filterdTeasersSiteCoreData.teasers.filter((categoryTeaser: any) => categoryTeaser.itemName === headerTeaser.teaserId)[0],
            );
            if (index > -1) {
                filteredTeasers.push(headerTeaser);
            }
        });
        const displayMode = this.getdisplayModeNormalTeasers(isDefaultCateogory, embeddedPosition);
        if (displayMode != null) {
            if (displayMode == 'embedded') {
                filteredTeasers = filteredTeasers.filter((x: any) => x.isEmbeddedTeaser);
            } else {
                filteredTeasers = filteredTeasers.filter((x: any) => !x.isEmbeddedTeaser);
            }
        }
        filteredTeasers = displayMode == null ? [] : filteredTeasers;

        return filteredTeasers;
    }
    getDisplayMode(isDefaultCateogory: any, embeddedPosition: any) {
        let displaymode = null;
        if (isDefaultCateogory) {
            if (embeddedPosition == 'Mainteasers') displaymode = 'Top';
        } else {
            if (embeddedPosition == 'Mainteasers') {
                displaymode = 'SubNavigation';
            } else {
                displaymode = embeddedPosition;
            }
        }
        return displaymode;
    }
    getdisplayModeNormalTeasers(isDefaultCateogory: any, embeddedPosition: any) {
        let displaymode = null;
        if (isDefaultCateogory) {
            if (embeddedPosition == 'Mainteasers') displaymode = 'Top';
        } else {
            if (embeddedPosition == 'Mainteasers') displaymode = 'Top';
            else displaymode = 'embedded';
        }
        return displaymode;
    }
    fetchOptMiniTeasers(optMiniTeaserPaths: Array<string>) {
        if (optMiniTeaserPaths && optMiniTeaserPaths.length > 0) {
            if (!this.optimizedMiniTeasersData) {
                this.optimizedMiniTeasersData = this.api.get('GetOptMiniTeaserData?request=' + optMiniTeaserPaths).pipe(
                    map((response: any) => response),
                    shareReplay(1),
                );
                this.optimizedMiniTeasersData.subscribe((response: any) => {
                    if (this.optMiniTeasersMap && this.optMiniTeasersMap.size > 0) {
                        this.optMiniTeasersMap.clear();
                    }
                    response.teasers.forEach((item: any) => {
                        this.optMiniTeasersMap.set(item.path, item.contents);
                    });
                    this.mercuryBgImage = response.mercuryBgImage;
                    this.optMiniTeasersMapPublisher.next(true);
                });
            } else {
                this.optMiniTeasersMapPublisher.next(true);
            }
        }
    }

    getOptMiniTeasersMap() {
        return this.optMiniTeasersMap;
    }

    getOptMiniTeasers(path: string) {
        return this.optMiniTeasersMap.get(path);
    }
}
