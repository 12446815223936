import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'bg-flip-clock',
    templateUrl: 'flip-clock.component.html',
    styleUrls: ['flip-clock.component.css'],
})
export class FlipClockComponent implements OnInit, OnChanges, OnDestroy {
    @Input() amount: any;
    @Input() currency: any;
    tickerValue: string;
    majorItems: any = [];
    minorItems: any = [];
    intervalId: any;
    public file_srcs: string[] = [];
    public debug_size_before: string[] = [];
    public debug_size_after: string[] = [];
    constructor(private changeDetectorRef: ChangeDetectorRef) {}
    ngOnInit() {
        this.tickerValue = this.amount;
    }
    ngOnChanges() {
        clearInterval(this.intervalId);
        this.tickerValue = this.amount;
        this.teaserTickerCode();
    }
    ngOnDestroy() {
        clearInterval(this.intervalId);
    }
    updateTicker(tickerValue: any) {
        this.majorItems = [];
        this.minorItems = [];
        let j = 0;
        const str: string = tickerValue.toString();
        const index: number = str.indexOf('.');
        for (let i = index; i > 0; i--) {
            if (i % 3 == 0 && j !== 0) {
                this.majorItems.push({
                    isFlipVisible: false,
                    value: ',',
                });
            }

            this.majorItems.push({
                isFlipVisible: false,
                value: str.slice(j, j + 1),
            });
            j = j + 1;
        }
        for (let i = index + 1; i < str.length; i++) {
            this.minorItems.push({
                isFlipVisible: false,
                value: str.slice(i, i + 1),
            });
        }
    }
    // tickerCode() {
    //     setInterval(() => {
    //         this.tickerValue = (parseFloat(this.tickerValue) + 0.02).toFixed(2).toString();
    //         this.updateTicker(this.tickerValue);
    //         this.minorItems[1].isFlipVisible = true;
    //     }, 5000);
    // }
    teaserTickerCode() {
        this.intervalId = setInterval(() => {
            this.tickerValue = (parseFloat(this.tickerValue) + 0.01).toFixed(2);
            this.updateTicker(this.tickerValue);
            // this.minorItems[1].isFlipVisible = true;
        }, 1500);
    }
    fileChange(input: any) {
        const filePath = input.value;
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        if (!allowedExtensions.exec(filePath)) {
            alert('Please upload file having extensions .jpeg/.jpg/.png/.gif only.');
            input.value = '';
            return false;
        } else {
            this.readFiles(input.files);
        }
        return;
    }
    readFile(file: any, reader: any, callback: any) {
        // Set a callback funtion to fire after the file is fully loaded
        reader.onload = () => {
            // callback with the results
            callback(reader.result);
        };
        // Read the file
        reader.readAsDataURL(file);
    }
    readFiles(files: any, index = 0) {
        // Create the file reader
        const reader = new FileReader();

        // If there is a file
        if (index in files) {
            // Start reading this file
            this.readFile(files[index], reader, (result: any) => {
                // Create an img element and add the image file data to it
                const img = document.createElement('img');
                img.src = result;
                this.resize(img, 150, 150, (resized_jpeg: any, before: any, after: any) => {
                    this.debug_size_before.push(before);
                    this.debug_size_after.push(after);
                    this.file_srcs.pop();
                    this.file_srcs.push(resized_jpeg);
                    // this.uploadprofilepic = { "patimage": this.file_srcs[0] }
                    // console.log("image url " + this.file_srcs[0]);

                    // Read the next file;
                    this.readFiles(files, index + 1);
                });
            });
        } else {
            // When all files are done This forces a change detection
            this.changeDetectorRef.detectChanges();
        }
    }
    resize(img: any, MAX_WIDTH: number, MAX_HEIGHT: number, callback: any) {
        // this.isImageExist = true;
        // This will wait until the img is loaded before calling this function
        return (img.onload = () => {
            // console.log("img loaded");
            // Get the images current width and height
            let width = img.width;
            let height = img.height;

            // Set the WxH to fit the Max values (but maintain proportions)
            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }

            // create a canvas object
            const canvas = document.createElement('canvas');

            // Set the canvas to the new calculated dimensions
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');

            ctx!.drawImage(img, 0, 0, width, height);

            // Get this encoded as a jpeg
            // IMPORTANT: 'jpeg' NOT 'jpg'
            const dataUrl = canvas.toDataURL('image/jpeg');

            // callback with the results
            callback(dataUrl, img.src.length, dataUrl.length);
        });
    }
}
