import { Injectable } from '@angular/core';

import { BingoConfigProviderService, TagManagerClientConfig } from '@frontend/bingo/core';
import { TagManagerService, TrackingService, WindowRef } from '@frontend/vanilla/core';

import { RootServicesModule } from '../root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class TagManagerRendererService {
    private isGoogleTagManagerLoaded: boolean = false;
    tagManagerClientConfig: TagManagerClientConfig;

    constructor(
        private tagManagerService: TagManagerService,
        private vanillaTrackingService: TrackingService,
        private configProviderService: BingoConfigProviderService,
        private windowRef: WindowRef,
    ) {}

    renderGTMTag(): void {
        this.tagManagerClientConfig = this.configProviderService.provideTagManagerClientConfig();
        if (this.tagManagerClientConfig && this.tagManagerClientConfig.triggerGoogleTagManagerClientInjection && !this.isGoogleTagManagerLoaded) {
            // Fire App Loaded Event for use as a trigger in GTM - Should be the first event in DataLayer
            this.vanillaTrackingService.triggerEvent('Vanilla_Portal_App_Loaded', { 'component.CategoryEvent': '' });

            //Load GTM with a delay
            this.windowRef.nativeWindow.setTimeout(() => {
                this.loadGTM();
            }, this.tagManagerClientConfig.googleTagManagerInjectionDelay);
        }
    }

    private loadGTM(): void {
        // Load GTM Script
        this.tagManagerService.whenReady.subscribe(() => {
            this.tagManagerService.load('GoogleTagManagerRenderer');
        });
        this.isGoogleTagManagerLoaded = true;
    }
}
