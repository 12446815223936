import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { BingoConfigProviderService, FeaturesClientConfig } from '@frontend/bingo/core';
import { NavigationService, Page, ProductHomepagesConfig } from '@frontend/vanilla/core';

import { RootServicesModule } from '../root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class PrebuyGuardService implements CanActivate {
    global: any;
    constructor(
        private productHomepagesConfig: ProductHomepagesConfig,
        private configProviderService: BingoConfigProviderService,
        private navigator: NavigationService,
        private page: Page,
    ) {}

    canActivate(): boolean {
        const featuresConfig: FeaturesClientConfig = this.configProviderService.provideFeaturesConfig();
        if (featuresConfig.disableAllPrebuy) {
            let apiUrl: string = '';
            apiUrl = this.productHomepagesConfig.bingo + '/bingo/rooms';
            this.navigator.goTo(apiUrl);
            return false;
        }
        return true;
    }
}
