import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BingoConfigProviderService, FeaturesClientConfig, ScheduleService } from '@frontend/bingo/core';
import { NavigationService, Page, ProductHomepagesConfig } from '@frontend/vanilla/core';

import { PrebuyService } from '../shared/services/pre-buy.service';
import { AlertMessageService } from './../shared/components/alert-message/alert-message.service';

@Component({
    selector: 'bg-pre-buy-redirect',
    template: '',
})
export class PrebuyRedirectComponent {
    roomId: any;
    tabIndex: number;
    lobbyUrl: string;
    selectedDate: string;
    selectedGameSlot: string;
    global: any;
    featureConfig: FeaturesClientConfig;

    constructor(
        private route: ActivatedRoute,
        private prebuyService: PrebuyService,
        private scheduleService: ScheduleService,
        private activeRoute: ActivatedRoute,
        private productHomepagesConfig: ProductHomepagesConfig,
        private navigator: NavigationService,
        private page: Page,
        private configProviderService: BingoConfigProviderService,
        private alertMessageService: AlertMessageService,
    ) {
        this.featureConfig = this.configProviderService.provideFeaturesConfig();
        this.route.queryParams.subscribe((queryParams: any) => {
            this.roomId = queryParams['selected_room_id'];
            this.tabIndex = queryParams['tabSelectedIndex'];
            this.lobbyUrl = queryParams['lobbyUrl'];
            this.selectedDate = queryParams['date'] ? queryParams['date'] : '';
            this.selectedGameSlot = queryParams['gameslot'] ? queryParams['gameslot'] : '';
        });
        if (this.tabIndex != 1 && this.featureConfig.disableAllPrebuy) {
            this.global = this.activeRoute.snapshot.data['config']?.global;
            if (this.global.messages['disableAllPrebuyMessage']) {
                this.alertMessageService.error('checkmark_kick', this.global.messages['disableAllPrebuyMessage']);
            }
            setTimeout(() => {
                let apiUrl: string = '';
                apiUrl = this.productHomepagesConfig.bingo + '/bingo/rooms';
                this.navigator.goTo(apiUrl);
            }, 3000);
        } else {
            this.scheduleService.getGameData((response: any) => {
                this.prebuyService.prebuy(
                    response.result.games_list,
                    this.roomId,
                    this.tabIndex,
                    this.lobbyUrl,
                    this.selectedDate,
                    this.selectedGameSlot,
                );
            });
        }
    }
}
