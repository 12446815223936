//import { VanillaCoreModule } from '@frontend/vanilla/core';
import { CommonModule } from '@angular/common';
//import { VanillaCommonModule } from '@frontend/vanilla/common';
// Angular Imports
import { ModuleWithProviders, NgModule } from '@angular/core';

import { CasinoPlatformLoaderModule } from '@casinocore/loader';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
// This Module's Components
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';

import { FlipClockComponent } from './flip-clock/flip-clock.component';
import { JpCounterComponent } from './jp-counter/jp-counter.component';
import { TeasersNpmComponent } from './teasers-npm.component';
import { TeasersComponent } from './teasers.component';

@NgModule({
    imports: [
        CommonModule,
        //VanillaCoreModule,
        //VanillaCommonModule,
        SwiperComponent,
        CasinoPlatformLoaderModule,
        TrustAsHtmlPipe,
    ],
    declarations: [TeasersComponent, JpCounterComponent, FlipClockComponent, TeasersNpmComponent],
    exports: [TeasersComponent, JpCounterComponent, FlipClockComponent, TeasersNpmComponent],
})
export class TeasersModule {
    public static forRoot(): ModuleWithProviders<TeasersModule> {
        return {
            ngModule: TeasersModule,
        };
    }
}
