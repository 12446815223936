import { Component, HostListener, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BingoConfigProviderService, BingoWidgetService, EventEmitService, FeaturesClientConfig, ScheduleService } from '@frontend/bingo/core';
import { CookieService, DeviceService, UserService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

// import { PrebuyService } from '../shared/services/pre-buy.service';

@Component({
    selector: 'bg-bingo-widget',
    templateUrl: 'bingo-widget.component.html',
})
export class BingoWidgetComponent implements OnInit, OnChanges, OnDestroy {
    Global: any;
    url: string;
    bingoWidth: number;
    isBingoWidget: boolean;
    loadCarouselinsideWidget: boolean = true;
    playerSegmentObs: Subscription;
    enableNewCozyScheduleUI: boolean;
    gameListCount: number;
    isTouch: boolean;
    featuresclientconfig: FeaturesClientConfig;
    feature: FeaturesClientConfig;
    clientConfig: any;
    constructor(
        private bingoWidget: BingoWidgetService,
        private eventEmitService: EventEmitService,
        private cookieService: CookieService,
        private scheduleService: ScheduleService,
        private user: UserService,
        private deviceService: DeviceService,
        private route: ActivatedRoute,
        private configProviderService: BingoConfigProviderService,
    ) {
        this.featuresclientconfig = this.configProviderService.provideFeaturesConfig();
        this.feature = this.configProviderService.provideFeaturesConfig();
        // private prebuyService: PrebuyService) {
    }
    ngOnInit() {
        this.isTouch = this.deviceService.isTouch;
        this.enableNewCozyScheduleUI = this.featuresclientconfig['enableNewCozyLobby'];
        this.bingoWidth = document.body.offsetWidth || window.innerWidth;
        this.clientConfig = this.route.snapshot.data['config'];
        this.Global = this.clientConfig?.global;
        this.bingoWidget.setIsWidget();
        if (this.feature.enableOptimove && this.user.isAuthenticated) {
            this.loadCarouselinsideWidget = false;
            this.showCarouselForOptimove();
        }
    }

    goToRooms() {
        //this.eventService.raiseEvent({ 'navigateToUrl': '/bingo/rooms' });
        const forceUrl: string = this.clientConfig?.pageConfig?.value?.bingoHomePage.url + '/bingo/rooms';
        this.bingoWidget.postMessage('redirectUrl_bingo', { url: forceUrl });
    }

    showCarouselForOptimove() {
        this.scheduleService.setPlayerSegmentationData();
        this.playerSegmentObs = this.scheduleService.playerSegmentDataObservable.subscribe((data: any) => {
            if (data) {
                this.loadCarouselinsideWidget = true;
            }
        });
    }

    onResize() {
        this.bingoWidth = document.body.offsetWidth || window.innerWidth;
    }

    @HostListener('window:message', ['$event']) receiveWidgetMessages(event: MessageEvent) {
        if (event.data.params) {
            if (event.data.eventName === 'resizeNotificationMessage') {
                this.bingoWidth = event.data.params.width;
            } else if (event.data.eventName == 'favupdated') {
                this.eventEmitService.raiseEvent({
                    favoriteUpdatedFromWidget: { roomName: event.data.params.roomid, isFavourite: event.data.params.isFavourite },
                });
            }
        }
    }

    isBingoWidgetDisplay() {
        this.isBingoWidget = this.bingoWidget.isBingoWidget();
    }

    setgameCount(event: any) {
        this.gameListCount = event.length;
    }

    ngOnChanges() {
        this.isBingoWidgetDisplay();
    }

    ngOnDestroy() {
        if (this.playerSegmentObs) {
            this.playerSegmentObs.unsubscribe();
        }
    }
}
