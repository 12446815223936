import { Pipe } from '@angular/core';

// tslint:disable-next-line:use-pipe-transform-interface
@Pipe({
    name: 'ellipsis',
})
export class EllipsisPipe {
    transform(url: any, gameData: any) {
        return url.replace('{0}', gameData.iconStyle).replace('{1}', gameData.gameId) + '.jpg';
    }
}
