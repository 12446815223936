<div *ngIf="loadPortalIframeGameLaunch">
    <iframe
        id="embedBingoGameIframe"
        [src]="gameRedirectUrl"
        allowfullscreen="true"
        style="width: 100%; height: 100vh"
        width="100%"
        frameborder="0"
        title=""
        scrolling="yes"></iframe>
</div>
<div *ngIf="!loadPortalIframeGameLaunch" [ngClass]="{ 'lobby-fulltakeover-bg-clr': isLobbyBgclr }">
    <bg-playing-now-component *ngIf="this.isMobile && showNavigation"></bg-playing-now-component>
    <!-- <div id="myModal" class="modal" *ngIf="isOverlay">
    <div class="modal-content">
            <bg-embedded-game-view></bg-embedded-game-view>
    </div>
</div> -->
    <div class="bingonav">
        <bg-teasers
            *ngIf="enableTeasers && !enableBingoNPM && isRoomsPage && !isInGameClient && displayTeasersAboveNav"
            [categoryName]="categoryText"></bg-teasers>
        <bg-teasers-npm
            *ngIf="enableTeasers && enableBingoNPM && isRoomsPage && !isInGameClient && displayTeasersAboveNav"
            [categoryName]="categoryText"
            [embeddedPosition]="'Mainteasers'"></bg-teasers-npm>

        <bg-navigation *ngIf="showNavigation && showTopNavigation && (!isInGameClient || enableNavigationInIframe)"></bg-navigation>

        <bg-teasers
            *ngIf="enableTeasers && !enableBingoNPM && isRoomsPage && !isInGameClient && !displayTeasersAboveNav"
            [categoryName]="categoryText"></bg-teasers>
        <bg-teasers-npm
            *ngIf="enableTeasers && enableBingoNPM && isRoomsPage && !isInGameClient && !displayTeasersAboveNav"
            [categoryName]="categoryText"
            [embeddedPosition]="'Mainteasers'"></bg-teasers-npm>

        <bg-sub-navigation
            *ngIf="showNavigation && bingoFeature.enableSubNavigation && isMobile && showSubNavigation"
            [title]="categoryName"></bg-sub-navigation>
        <bg-promotions-banner *ngIf="isEnabledBingoPromotions" [categoryName]="categoryName"></bg-promotions-banner>
        <vn-message-panel></vn-message-panel>
        <bg-alert-message></bg-alert-message>
        <bgl-confirm-message></bgl-confirm-message>

        <bg-teasers-npm
            *ngIf="enableTeasers && enableBingoNPM && isRoomsPage && !isInGameClient && (teaserOptimization || showEmbeddedTeasersAboveGames)"
            [categoryName]="categoryText"
            [embeddedPosition]="'EmbeddedAboveGames'"></bg-teasers-npm>
        <router-outlet (activate)="onNavigationChange('')"></router-outlet>
        <bg-teasers-npm
            *ngIf="enableTeasers && enableBingoNPM && isRoomsPage && !isInGameClient && (teaserOptimization || !showEmbeddedTeasersAboveGames)"
            [categoryName]="categoryText"
            [embeddedPosition]="'EmbeddedBelowGames'"></bg-teasers-npm>
        <div style="display: inline-table" *ngIf="!isInGameClient">
            <bg-seoboxes *ngIf="seoCategoryName" [categoryName]="seoCategoryName" [timestamp]="timestamp"></bg-seoboxes>
        </div>
    </div>

    <div *ngIf="casinoPane">
        <bg-casino-games-lobby></bg-casino-games-lobby>
    </div>
    <!-- <cc-rp-fav-widget-wrapper></cc-rp-fav-widget-wrapper> -->
    <!-- <ng-container [bgCasinoModuleLoad]="'cc-rp-fav-widget-wrapper'"></ng-container> -->
    <div class="rpFavWidget" *ngIf="isModularCasinoEnabled && isRPFavNpmEnabled">
        <ng-container [bgCasinoModuleLoad]="'RpFavWidgetWrapperComponent'"></ng-container>
    </div>
    <div *ngIf="isLobbyBgclr" class="lobby-bg" [style.background-image]="'url(' + lobbyStaticBackgroundImage + ')'"></div>
</div>
