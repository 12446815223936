<div *ngIf="showCasinoPane && !isMobileView && !isInGameClient" class="fixed-footer" [ngClass]="'casino-lobby'">
    <div class="game-lobby">
        <div class="ngx-tabset">
            <mat-tab-group class="lobby-navigation-bar" (onSelect)="PaneCategoryChange()">
                <div>
                    <button (click)="onResize()" (window:resize)="onResize()" class="icon-theme-left">
                        <i *ngIf="!isActive" Class="theme-up"></i>
                        <i *ngIf="isActive" Class="theme-down"></i>
                    </button>
                </div>
                <!-- <div *ngIf="categories.categoryDataList"> -->
                <div>
                    <mat-tab *ngFor="let categorie of categories.categoryDataList; let i = index" title="{{ categorie.categoryTitle }}">
                        <!-- <ngu-carousel #myCarousel [inputs]="carouselOne" [dataSource]="categorie.categoryGameList">
                            <ngu-item  class="game" *nguCarouselDef="let game ; let i = index">
                                <div class="game-icon" (click)="getGame(game,i+1)" >
                                    <div class="jackpart-value">{{game?.jackpotValue}}</div> -->
                        <!-- <img attr.data-src ="{{iconUrl |  ellipsis}}{{game.iconStyle}}/{{game.gameId}}.jpg"> -->
                        <!-- <img [lazyLoad] = "iconUrl | ellipsis: game">
                                </div>
                            </ngu-item>
                            <button NguCarouselPrev class="theme-left button-left " [ngClass]="{'enable-btn':categories?.categoryDataList[i]?.categoryGameList?.length < 8}" [hidden]='myCarousel.isFirst'></button>
                            <button NguCarouselNext class="theme-right button-right " [ngClass]="{'enable-btn':categories?.categoryDataList[i]?.categoryGameList?.length < 8}"[hidden]='myCarousel.isLast'></button>
                        </ngu-carousel> -->
                        <vn-swiper #myCarousel [swiperOptions]="swiperOptionsCasinoGames">
                            <div class="game" *ngFor="let data of categorie.categoryGameList">
                                <div class="game-icon" (click)="getGame(data, i + 1)">
                                    <div class="jackpart-value">{{ data?.jackpotValue }}</div>
                                    <img [lazyLoad]="iconUrl | ellipsis: data" alt="Game icons" />
                                </div>
                            </div>
                            <!-- <button NguCarouselPrev class="theme-left button-left " [ngClass]="{'enable-btn':categories?.categoryDataList[i]?.categoryGameList?.length < 8}" [hidden]='myCarousel.isFirst'></button>
                            <button NguCarouselNext class="theme-right button-right " [ngClass]="{'enable-btn':categories?.categoryDataList[i]?.categoryGameList?.length < 8}"[hidden]='myCarousel.isLast'></button> -->
                        </vn-swiper>
                    </mat-tab>
                </div>
                <!-- </div> -->
            </mat-tab-group>
        </div>
    </div>
</div>
