<div
    class="parallax-backgroundImage"
    *ngIf="parallaxData.backgroundLayer1"
    [ngStyle]="{ 'background-image': 'url(' + (parallaxData.backgroundLayer1.src | sitecore_image_resize) + ')' }"></div>

<div
    cwdjParallax
    *ngIf="parallaxData.backgroundLayer2"
    [config]="{ parallaxInitVal: 0 }"
    class="parallax-section"
    id="parallax-image1"
    [ngStyle]="{ 'background-image': 'url(' + (parallaxData.backgroundLayer2.src | sitecore_image_resize) + ')' }"></div>

<div
    cwdjParallax
    *ngIf="parallaxData.backgroundLayer3"
    [config]="{ parallaxInitVal: 0, parallaxRatio: -0.2, ratio: -0.2 }"
    class="parallax-blurImage parallax-section"
    id="parallax-image2"
    [ngStyle]="{ 'background-image': 'url(' + (parallaxData.backgroundLayer3.src | sitecore_image_resize) + ')' }"></div>
