//import { VanillaCoreModule } from '@frontend/vanilla/core';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';

import { DslPipe } from '@frontend/vanilla/shared/browser';

import { FilterAndSortingComponent } from '../filter-and-sorting/filter-and-sorting.component';
import { PlayingNowComponent } from '../playing-now/playing-now.component';
import { FacetsModule } from '../shared/components/facets/facets.module';
//import { VanillaCommonModule } from '@frontend/vanilla/common';
import { ClientgametimeComponent } from './clientgametime/clientgametime.component';
// import { LabelHostModule } from '@frontend/vanilla/core';
import { NavigationComponent } from './navigation.component';

@NgModule({
    imports: [
        //VanillaCoreModule,
        CommonModule,
        // LabelHostModule,
        FacetsModule,
        //VanillaCommonModule,
        MatSlideToggleModule,
        DslPipe,
        RouterModule,
    ],
    declarations: [NavigationComponent, FilterAndSortingComponent, PlayingNowComponent, ClientgametimeComponent],
    exports: [NavigationComponent, FilterAndSortingComponent, PlayingNowComponent],
})
export class NavigationModule {
    public static forRoot(): ModuleWithProviders<NavigationModule> {
        return {
            ngModule: NavigationModule,
        };
    }
}
