import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { CwdjParallaxModule } from './cwdj-parallax/cwdj-parallax.module';
//import { VanillaCoreModule } from '@frontend/vanilla/core';
import { ParallaxAnimationComponent } from './parallax-animation.component';

@NgModule({
    //imports: [CommonModule, VanillaCoreModule, CwdjParallaxModule, SharedModule],
    imports: [CommonModule, CwdjParallaxModule, SharedModule],
    declarations: [ParallaxAnimationComponent],
    exports: [ParallaxAnimationComponent],
})
export class ParallaxAnimationModule {
    public static forRoot(): ModuleWithProviders<ParallaxAnimationModule> {
        return {
            ngModule: ParallaxAnimationModule,
        };
    }
}
