<div [ngClass]="{ 'room-component': !isHome, 'home-borgata': showVerticalRoomsInHomePage }">
    <div class="bingolobby-main" id="bingoWidget" #bingoWidget>
        <ng-container *ngIf="isHomePage && showVerticalRoomsInHomePage">
            <div class="roomslist forb">
                <h1 class="rooms-tab" [innerHTML]="Global?.messages?.bingoroom"></h1>
                <div class="see-all" [innerHTML]="Global?.messages?.seeAll" *ngIf="displaySeeAllOnLobby" (click)="gotToRooms()"></div>
            </div>
        </ng-container>
        <div class="lobby-min-height" [hidden]="!showLoading" *ngIf="!isHome">
            <!-- <div class="padding-skeleton">
        </div> -->
            <div>
                <div class="bg-roomslist-skeleton sk-bingo-container">
                    <div class="sk-cards-wrapper">
                        <div *ngFor="let number of [0, 1, 2, 3, 4, 5, 6, 7]" class="sk-card">
                            <div class="sk-card-image"></div>
                            <div class="sk-info-wrap">
                                <div class="sk-title-section">
                                    <div class="sk-circle"></div>
                                    <div class="sk-title"></div>
                                </div>
                                <div class="sk-card-details"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bingorooms-data" [hidden]="showLoading" [ngClass]="{ 'cozy-wraper-container': isCozyUser }">
            <div *ngIf="!isHome">
                <vn-message-panel></vn-message-panel>
            </div>
            <div class="bingo-rooms" [ngClass]="isInGameClient ? 'game-client-view' : ''">
                <!-- this is for touch-devices & saparate rooms tab -->
                <ng-container *ngIf="roomsHasValue && !isHome && isTouch && !showLoading">
                    <div
                        *ngFor="
                            let data of gameData
                                | ballfilter: selectedBallType
                                | sortfilter: selectedSortType : 1 : pinnedRoomId : insuranceRoomIds : sitNGoRoomIds
                                | gameListSort: selectedSortOption : pinnedRoomId;
                            trackBy: trackRoomsData;
                            let i = index
                        "
                        (click)="RoomMouseEnter(data)"
                        (mouseleave)="data.hover = false">
                        <div *ngIf="!data.isHidden">
                            <bg-rooms-item-component
                                [showVerticalRoomsInHomePage]="showVerticalRoomsInHomePage"
                                *ngIf="!isCozyUser"
                                [bingoItem]="data"
                                [position]="i"
                                [gridSize]="gridSize"
                                [GameData]="gameData"></bg-rooms-item-component>
                            <bg-game-item-cozy-component
                                *ngIf="isCozyUser"
                                [bingoItem]="data"
                                [position]="i"
                                [gridSize]="gridSize"
                                [GameData]="gameData"></bg-game-item-cozy-component>
                        </div>
                    </div>
                </ng-container>
                <!-- this is for home page  rooms touch-devices -->
                <div class="touch-bingorooms-data" *ngIf="roomsHasValue && isTouch && isHome">
                    <div class="bingorooms-data">
                        <div class="bingo-rooms">
                            <ng-container>
                                <div
                                    *ngFor="
                                        let data of gameData
                                            | ballfilter: selectedBallType
                                            | sortfilter: selectedSortType : 1 : pinnedRoomId : insuranceRoomIds : sitNGoRoomIds
                                            | gameListSort: selectedSortOption : pinnedRoomId;
                                        trackBy: trackRoomsData;
                                        let i = index
                                    "
                                    (click)="RoomMouseEnter(data)"
                                    (mouseleave)="data.hover = false">
                                    <div *ngIf="!data.isHidden">
                                        <bg-rooms-item-component
                                            [showVerticalRoomsInHomePage]="showVerticalRoomsInHomePage"
                                            *ngIf="!isCozyUser"
                                            [bingoItem]="data"
                                            [position]="i"
                                            [gridSize]="gridSize"
                                            [GameData]="gameData"></bg-rooms-item-component>
                                        <bg-game-item-cozy-component
                                            *ngIf="isCozyUser"
                                            [bingoItem]="data"
                                            [position]="i"
                                            [gridSize]="gridSize"
                                            [GameData]="gameData">
                                        </bg-game-item-cozy-component>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="roomsHasValue && !isHome && !isTouch && !showLoading && !enable2By1RoomTile">
                    <div
                        *ngFor="
                            let data of gameData
                                | ballfilter: selectedBallType
                                | sortfilter: selectedSortType : 1 : pinnedRoomId : insuranceRoomIds : sitNGoRoomIds
                                | gameListSort: selectedSortOption : pinnedRoomId;
                            trackBy: trackRoomsData;
                            let i = index
                        "
                        (mouseover)="data.hover = true"
                        (mouseleave)="data.hover = false">
                        <div *ngIf="!data.isHidden">
                            <bg-rooms-item-component
                                [showVerticalRoomsInHomePage]="showVerticalRoomsInHomePage"
                                *ngIf="!isCozyUser"
                                [bingoItem]="data"
                                [position]="i"
                                [gridSize]="gridSize"
                                [GameData]="gameData"></bg-rooms-item-component>
                            <bg-game-item-cozy-component
                                *ngIf="isCozyUser"
                                [bingoItem]="data"
                                [position]="i"
                                [gridSize]="gridSize"
                                [GameData]="gameData"></bg-game-item-cozy-component>
                        </div>
                    </div>
                </ng-container>
                <!-- this is for homE page rooms  non-touch-devices -->
                <div *ngIf="roomsHasValue && isHome && loadCarouselinsideWidget && !isTouch && !enable2By1RoomTile && isSwiperRooms">
                    <vn-swiper #roomsListCarousel [swiperOptions]="swiperOptionsRoomsList">
                        <!-- <ngu-carousel #roomsListCarousel [inputs]="carouselConfig"
                        [dataSource]="gameData | ballfilter  : selectedBallType | hiddenfilter | ballfilter : selectedBallType| sortfilter : selectedSortType : 1:pinnedRoomId: insuranceRoomIds | gameListSort : selectedSortOption: pinnedRoomId" (onMove)="onmoveFn($event)"> -->
                        <div
                            class="swiper-slide"
                            *ngFor="
                                let data of gameData
                                    | ballfilter: selectedBallType
                                    | hiddenfilter
                                    | bingoTournamentFilter
                                    | ballfilter: selectedBallType
                                    | sortfilter: selectedSortType : 1 : pinnedRoomId : insuranceRoomIds : sitNGoRoomIds
                                    | gameListSort: selectedSortOption : pinnedRoomId;
                                let i = index
                            "
                            (mouseover)="data.hover = true"
                            (mouseleave)="data.hover = false">
                            <bg-rooms-item-component
                                [showVerticalRoomsInHomePage]="showVerticalRoomsInHomePage"
                                *ngIf="!isCozyUser"
                                [bingoItem]="data"
                                [position]="i"
                                [gridSize]="gridSize"
                                [GameData]="gameData"></bg-rooms-item-component>
                            <bg-game-item-cozy-component
                                *ngIf="isCozyUser"
                                [bingoItem]="data"
                                [position]="i"
                                [gridSize]="gridSize"
                                [GameData]="gameData"></bg-game-item-cozy-component>
                        </div>
                        <!-- <button NguCarouselPrev class="theme-left home-room-button-left"
                        [hidden]='isPreButtonVisible'></button>
                    <button NguCarouselNext class="theme-right home-room-button-right"
                        [hidden]='isNextButtonVisible'></button> -->
                    </vn-swiper>
                </div>
                <div class="non-carousel" *ngIf="!isTouch && enable2By1RoomTile">
                    <div
                        *ngFor="
                            let data of gameData
                                | ballfilter: selectedBallType
                                | sortfilter: selectedSortType : 1 : pinnedRoomId : insuranceRoomIds : sitNGoRoomIds
                                | gameListSort: selectedSortOption : pinnedRoomId;
                            let i = index
                        "
                        (mouseover)="data.hover = true"
                        (mouseleave)="data.hover = false">
                        <bg-rooms-item-component
                            [showVerticalRoomsInHomePage]="showVerticalRoomsInHomePage"
                            [bingoItem]="data"
                            [position]="i"
                            [gridSize]="gridSize"
                            [GameData]="gameData">
                        </bg-rooms-item-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <bg-casino-games-lobby></bg-casino-games-lobby> -->
