import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { LoginNavigationService, UserService } from '@frontend/vanilla/core';

import { RootServicesModule } from '../root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class AuthGuardService implements CanActivate {
    constructor(
        private user: UserService,
        private navigator: LoginNavigationService,
        public router: Router,
    ) {}

    canActivate(): boolean {
        if (!this.user.isAuthenticated) {
            this.navigator.goToLogin({ appendReferrer: true });
            return false;
        }
        return true;
    }
}
