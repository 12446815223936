import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

//import { ContentModule } from '@frontend/vanilla/features/content';
import { PageMatrixComponent } from '@frontend/vanilla/features/content';

//import { VanillaCoreModule } from '@frontend/vanilla/core';
import { SeoboxesComponent } from './seoboxes.component';

@NgModule({
    imports: [
        CommonModule,
        //VanillaCoreModule,
        //ContentModule
        PageMatrixComponent,
    ],
    declarations: [SeoboxesComponent],
    exports: [SeoboxesComponent],
})
export class SeoboxesModule {
    public static forRoot(): ModuleWithProviders<SeoboxesModule> {
        return {
            ngModule: SeoboxesModule,
        };
    }
}
