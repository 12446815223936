import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
//import { VanillaCoreModule } from '@frontend/vanilla/core';
import { RouterModule } from '@angular/router';

// import { EmbeddedGameViewModule } from '../shared/embedded-game-view';
//import{ PlatformModule } from '@casinocore/platform';
import { CasinoPlatformLoaderModule } from '@casinocore/loader';
import { ConfirmMessageModule } from '@frontend/bingo/core';

import { MessagePanelComponent } from '../../../../vanilla/lib/features/message-panel/src/message-panel.component';
import { CasinoGamesLobbyModule } from '../casino-games-lobby/casino-games-lobby.module';
import { NavigationModule } from '../navigation/navigation.module';
import { SubNavigationModule } from '../navigation/subnavigation/sub-navigation.module';
import { PromotionsBannerModule } from '../promotions-banner/promotionsBanner.module';
import { AlertMessageModule } from '../shared/components/alert-message/alert-message.module';
import { ToastMessageModule } from '../shared/components/toast-message/toast-message.module';
import { SharedModule } from '../shared/shared.module';
import { TeasersModule } from '../teasers/teasers.module';
import { SeoboxesModule } from './../seoboxes/seoboxes.module';
import { MainComponent } from './main.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        //VanillaCoreModule,
        ToastMessageModule,
        NavigationModule,
        CasinoGamesLobbyModule,
        ConfirmMessageModule,
        SeoboxesModule,
        AlertMessageModule,
        SubNavigationModule,
        SharedModule,
        CasinoPlatformLoaderModule,
        TeasersModule,
        PromotionsBannerModule,
        MessagePanelComponent,
    ],
    declarations: [MainComponent],
    exports: [MainComponent],
})
export class MainModule {}
