<!--<div class="opt-container">
<div (click)="close()">X</div>
<h2>{{modelData.title}}</h2>
<p>{{modelData.description}}</p>
<div (click)="continue()">Continue</div>
</div>-->

<div class="opt-container">
    <div (click)="close()" class="opt-close theme-ex"></div>
    <i class="theme-info"></i>
    <h2>{{ modelData.title }}</h2>
    <p>{{ modelData.description }}</p>
    <div (click)="continue()" class="btn btn-primary opt-continue">Continue</div>
</div>
