import { AfterViewInit, Component, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BingoConfigProviderService, EventEmitService, FeaturesClientConfig, GameLaunchService } from '@frontend/bingo/core';
import {
    DeviceService,
    Logger,
    NativeAppService,
    NavigationService,
    TrackingService,
    UserLoginEvent,
    UserService,
    WindowRef,
} from '@frontend/vanilla/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { filter } from 'rxjs/operators';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { GameService } from './game.service';
import { TeaserJackpotService } from './teaser-jackpots.service';

@Component({
    selector: 'bg-teasers',
    templateUrl: 'teasers.component.html',
    //styleUrls: ['teasers.component.css']
})
export class TeasersComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @ViewChild('teasersSwiper') swiperSlideShow!: SwiperComponent;
    @Input() fromNative: boolean;
    @Input() isLand: boolean;
    @Input() categoryName: string;
    mode: string = 'teaser';
    EnableJackpotTickerTeasers: any;
    standardWidths: any = [320, 480, 640, 800, 1024, 1280];
    teaserWidth: any;
    teaserHeight: any;
    elementWidth: any;
    elementHeight: any;
    jackpot_image: any;
    index: any = 0;
    eventId: any = null;
    slideEventId: any = null;
    eventClickId: any;
    isNewSportsNative: any;
    isLandNative: any;
    jpTitle: any = 24;
    jpContainer: any = 37;
    jpCta: any = 48;
    //fixed heights for jackpot elements as per req.
    jackpot: any = { amount: '' };
    teasers: any;
    extract: any;
    embedded: any;
    moreMessage: any;
    moreInfoMessage: any;
    isScreenshotMode: any;
    useMD5: any;
    isLandscape: any;
    teaserLandscape: any;
    isiOS: any;
    useMD3: any;
    m2PubSub: any;
    isTeaserMode: any;
    showPromoInfo: any;
    margin: any;
    NativeApi: any;
    isTouch: boolean;
    teasersMainClass: string = 'teasers';
    teaserLaunchRealtiveUri: string = '/casino/launchng/';
    jackpotData: any;
    jackpotcurrency: any;
    jackpotvalue: any;
    headerTeasers: any;
    enableTeasers: boolean;
    teasersSource: any;
    TeaserMode: any;
    arrows: boolean = false;
    dots = {
        clickable: true,
    };
    loop: boolean = true;
    oneTeaser: boolean = false;
    autoplay = {
        delay: 5000,
        disableOnInteraction: false,
    };
    bingocta: any;
    enablebingoplay: boolean;
    isInGameClient: boolean;
    Global: any;
    featureConfig: FeaturesClientConfig;
    teasersconfigloaded: any;
    swiperOptionsTeasers: SwiperOptions = {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        autoHeight: true,
        allowTouchMove: false,
        spaceBetween: 1,
        direction: 'horizontal',
        slidesPerView: 'auto',
        centeredSlides: false,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
            waitForTransition: false,
        },
        pagination: {
            type: 'bullets',
            clickable: true,
            el: '.swiper-pagination',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        observer: true,
        rewind: true,
        slidesPerGroup: 1,
        keyboard: true,
        watchOverflow: true,
        mousewheel: false,
        scrollbar: false,
        preventClicks: false,
        preventClicksPropagation: false,
    };
    constructor(
        private windowRef: WindowRef,
        private Navigator: NavigationService,
        private tracking: TrackingService,
        private NativeApplication: NativeAppService,
        private logger: Logger,
        private gameService: GameService,
        private jackpotService: TeaserJackpotService,
        private eventService: EventEmitService,
        private gameLaunchService: GameLaunchService,
        private activeroute: ActivatedRoute,
        private user: UserService,
        private configProviderService: BingoConfigProviderService,
        private deviceService: DeviceService,
    ) {
        this.featureConfig = this.configProviderService.provideFeaturesConfig();
        // this.EnableJackpotTickerTeasers = this.Global.enableJackpotTickerTeasers;
        this.EnableJackpotTickerTeasers = true;
        this.Navigator = Navigator;
    }
    ngOnInit() {
        if (this.activeroute.snapshot.queryParamMap.get('.box') || this.activeroute.snapshot.queryParamMap.get('hideHeader') == 'true') {
            this.isInGameClient = true;
        }
        this.enablebingoplay = this.featureConfig.enablePlayBingoButtonOnTeasers;
        this.getTeasers();
        this.isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        this.extract = false;
        this.isNewSportsNative =
            this.NativeApplication.isNative && (this.NativeApplication.product === 'SPORTSBOOK' || this.NativeApplication.product === 'BETTING');
        this.extract = true;
        this.embedded = this.mode === 'embedded';
        this.Global = this.activeroute.snapshot.data['config']?.global;
        this.TeaserMode = this.Global.messages.TeaserMode;
        this.moreMessage = this.Global.messages.More || 'More';
        this.moreInfoMessage = this.Global.messages.MoreInfo || 'More info';
        this.isScreenshotMode = this.mode === 'screenshot';
        this.isTeaserMode = this.mode === 'teaser' || this.mode === 'teaserlist';
        this.useMD5 = true;
        this.showPromoInfo = true;
        this.isLandscape = this.isInLandscape();
        this.teaserLandscape = this.elementWidth >= 480;
        this.isiOS = /(iPad|iPhone|iPod)/g.test(window.navigator.userAgent);
        this.JackpotFeed();
        this.user.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            this.getTeasers();
        });
    }
    ngAfterViewInit() {
        // if (this.swiperSlideShow && this.swiperSlideShow.swiperRef) {
        //     this.swiperSlideShow.swiperRef.autoplay.start();
        // }
        setTimeout(() => {
            if (this.swiperSlideShow) {
                this.swiperSlideShow.swiper.on('slideChange', (event: any) => {
                    this.onTeaserIndexChanged(event);
                });
            }
        }, 200);
    }
    getTeasers() {
        this.jackpotService.getTeaserData().subscribe((response: any) => {
            if (response.length == 0) {
                this.enableTeasers = false;
            } else {
                this.enableTeasers = true;
                this.teasersSource = response.teasers;
                this.bingocta = response.playBingoButton;
                //this.bingoctaLink = this.bingoctaLink.linkText;
                this.initializeTeasers();
                this.updatedInfo();
                if (this.fromNative) {
                    this.addRotateEvent(this.elementWidth, 500);
                    this.addSliderEvent(0, 550);
                }
            }
            this.eventService.raiseEvent('teaserLoaded');
        });
    }
    ngOnChanges() {
        if (this.activeroute.snapshot.queryParamMap.get('.box') || this.activeroute.snapshot.queryParamMap.get('hideHeader') == 'true') {
            this.isInGameClient = true;
        }
        if (this.teasersSource) {
            this.initializeTeasers();
            this.updatedInfo();
            if (this.fromNative) {
                this.addRotateEvent(this.elementWidth, 500);
                this.addSliderEvent(0, 550);
            }
        }
    }
    ngOnDestroy() {
        if (this.isLandscape) {
            document.documentElement.classList.remove('landscape');
        }
        if (this.NativeApi) {
            this.NativeApi.clearEvent(this.eventId);
            this.NativeApi.clearEvent(this.slideEventId);
            this.NativeApi.clearEvent(this.eventClickId);
        }
    }
    public closest(array: any, num: any) {
        return array.reduce((a: any, b: any) => {
            return Math.abs(a - num) < Math.abs(b - num) ? a : b;
        });
    }
    public isInLandscape() {
        if (this.useMD5) {
            return !this.isPortraitMD5();
        } else {
            if (this.fromNative) {
                return this.isLand;
            } else {
                if (this.useMD3 && !this.isScreenshotMode) {
                    return document.documentElement.offsetWidth > window.document.body.offsetHeight;
                } else {
                    return false;
                }
            }
        }
    }
    public isPortraitMD5() {
        let elementWidth;
        if (this.fromNative) {
            if (this.isLandNative) {
                if (this.isiOS) {
                    elementWidth = window.screen.height;
                } else {
                    elementWidth = window.screen.width > window.screen.height ? window.screen.width : window.screen.height;
                }
            } else {
                elementWidth = window.screen.width;
            }
            return elementWidth < 480;
        } else {
            elementWidth = window.document.documentElement.offsetWidth;
        }
        return elementWidth < 480;
    }
    public getHeight() {
        let index: number = 0;
        if (this.teasers && this.teasers.length > 0) {
            if (index === 0 || isNaN(index)) {
                index = this.teasers[0].image ? this.teasers[0].image.width / this.teasers[0].image.height : 0;
            }
        }
        // md5 type teasers don't use rotation
        if (this.useMD5) {
            if (this.fromNative) {
                if (this.isLandNative) {
                    if (this.isiOS) {
                        this.elementWidth = window.screen.height;
                    } else {
                        this.elementWidth = window.screen.width > window.screen.height ? window.screen.width : window.screen.height;
                    }
                } else {
                    this.elementWidth = window.screen.width;
                }
            } else {
                this.elementWidth = document.documentElement.offsetWidth;
            }
            let margin = 0;
            if (1024 <= this.elementWidth && !this.fromNative) {
                margin = this.elementWidth * 0.01 * 7.2 + 8;
            }
            this.elementWidth = this.elementWidth - margin * 2;
            const ctaButtonHeight = 48;
            let termsHeight = 45;
            const titleHeight = 30;
            if (480 <= this.elementWidth && this.elementWidth < 667) {
                termsHeight = titleHeight;
            }
            const teaserCtaAndTermsHeight = ctaButtonHeight + termsHeight + titleHeight;
            if (this.isPortraitMD5()) {
                this.teaserHeight = (this.elementWidth / this.index.toFixed(2) + teaserCtaAndTermsHeight - 1).toFixed(0);
            } else {
                this.teaserHeight = (this.elementWidth / 2 / this.index.toFixed(2) + termsHeight - 1).toFixed(0);
            }
            return this.teaserHeight;
        }
        if (this.isLandscape) {
            if (this.fromNative) {
                this.elementWidth = window.screen.width > window.screen.height ? window.screen.width : window.screen.height;
            } else {
                this.elementWidth =
                    document.documentElement.offsetWidth > document.documentElement.offsetHeight
                        ? document.documentElement.offsetWidth
                        : document.documentElement.offsetHeight;
            }
            this.teaserHeight = (this.elementWidth / 2 / this.index.toFixed(2) - 1).toFixed(0);
        } else {
            if (this.fromNative) {
                this.elementWidth = window.screen.width < window.screen.height ? window.screen.width : window.screen.height;
            } else {
                this.elementWidth =
                    document.documentElement.offsetWidth < document.documentElement.offsetHeight
                        ? document.documentElement.offsetWidth
                        : document.documentElement.offsetHeight;
            }
            this.teaserHeight = (this.elementWidth / this.index.toFixed(2) - 1).toFixed(0);
        }
        return this.teaserHeight;
    }
    // populates image url with teasersize
    public extendImageData(teasers: any) {
        teasers.forEach((teaser: any) => {
            if (teaser.image && teaser.image.src) {
                // teaser.image.src = (teaser.image.src.indexOf('?p=width') === -1) ? teaser.image.src + '?p=width' + teaserSize : teaser.image.src;
                if (this.index === 0 || isNaN(this.index)) {
                    this.index = teaser.image.width / teaser.image.height;
                }
            }
        });
    }
    // set style for teaser-container and teasers items
    public styleProperties() {
        if (this.useMD3 || this.useMD5) {
            if (!this.isLandscape)
                return {
                    'height': '274px',
                    'padding-bottom': '274px',
                };
            else
                return {
                    'height': this.teaserHeight + 'px',
                    'padding-bottom': this.teaserHeight + 'px',
                };
        }
        return '';
    }
    public jpStyleProperties(teaser: any) {
        if (this.elementWidth >= 400) {
            return {
                'height': this.teaserHeight + 'px',
                'padding-bottom': this.teaserHeight + 'px',
            };
        } else {
            return {
                'background-color': teaser.backgroundColor,
                'height': this.teaserHeight + 'px',
                'padding-bottom': this.teaserHeight + 'px',
            };
        }
    }
    public jackpotImageStyle(teaser: any) {
        let jackpot_image;
        const padding = parseInt((this.teaserHeight * 0.01).toFixed());
        if (!this.teaserLandscape) {
            if (teaser.ctaLink) {
                //if cta button is present
                jackpot_image = (this.teaserHeight - (this.jpCta + this.jpContainer + this.jpTitle + padding * 3)).toFixed(); //35%
            } else {
                jackpot_image = (this.teaserHeight - (this.jpContainer + this.jpTitle + padding * 3)).toFixed(); //35%
            }
            if (this.EnableJackpotTickerTeasers)
                return {
                    height: '145px',
                    width: 'auto',
                    margin: '0px 113px',
                };
            else
                return {
                    'height': this.jackpot_image + 'px',
                    'width': this.jackpot_image + 'px',
                    'margin-left': window.screen.width / 2 - this.jackpot_image / 2 + 'px',
                    'margin-top': padding + 'px',
                    'position': 'absolute',
                };
        } else {
            jackpot_image = parseInt((this.teaserHeight * 0.58).toFixed()); //35%
            return {
                'height': jackpot_image + 'px',
                'width': jackpot_image + 'px',
                'margin-left': window.screen.width / 5 - jackpot_image / 2 + 'px',
                'margin-top': padding + 'px',
                'position': 'absolute',
                // 'bottom': '50px'
                // 'left': '0'
            };
        }
    }
    public jackpotContainerStyle(teaser: any) {
        let padding, jackpotTop;
        if (!this.teaserLandscape) {
            padding = parseInt((this.teaserHeight * 0.01).toFixed());
            // jackpotTop = parseInt((teaserHeight * 0.48).toFixed()) + (padding * 2);
            if (teaser.ctaLink) {
                //if cta button is present
                jackpotTop = (this.teaserHeight - (this.jpCta + this.jpContainer + this.jpTitle + padding * 3)).toFixed(); //35%
            } else {
                jackpotTop = (this.teaserHeight - (this.jpContainer + this.jpTitle + padding * 2)).toFixed(); //35%
            }
            return {
                'margin-top': jackpotTop + 'px',
                'margin-bottom': padding + 'px',
            };
        } else {
            const jackpotlength = teaser.jackpotName.length;
            padding = parseInt((this.teaserHeight * 0.02).toFixed());
            jackpotTop = parseInt((this.teaserHeight * 0.58).toFixed()) + padding * 2;
            const margin_left = (window.screen.width / 5 - (jackpotlength * 12) / 4).toFixed();
            return {
                'padding-top': jackpotTop + 'px',
                'padding-bottom': padding + 'px',
                'left': margin_left + 'px',
                'position': 'absolute',
                'text-align': 'center',
                // 'bottom': '0px'
            };
        }
    }
    public jackpotTitle(teaser: any) {
        return {
            'color': teaser.attributes.titleColor,
            'font-size': teaser.attributes.titleFontSize + 'px',
            'font-weight': teaser.attributes.titleFontWeight,
        };
    }
    public jackpotCTA(teaser: any) {
        return {
            'color': teaser.attributes.ctaColor,
            'background-color': teaser.attributes.ctaBackgroundColor,
            'font-size': teaser.attributes.btnTextFontSize,
        };
    }
    public showJPBackgroundImage(teaser: any) {
        if (teaser.showBackgroundImage && this.elementWidth >= 400) {
            // $('.jackpot-teaser').css('background-color', teaser.backgroundColor);
            return true;
        } else {
            return false;
        }
    }
    public marginProperties() {
        if (!this.isLandscape) {
            if (this.useMD5) {
                return {
                    'height': '272px',
                    'margin-left': +this.margin + 'px',
                    'margin-right': +this.margin + 'px',
                };
            } else if (this.useMD3) {
                return {
                    'height': this.teaserHeight + 'px',
                    'padding-bottom': this.teaserHeight + 'px',
                };
            }
        } else {
            if (this.useMD5) {
                return {
                    'height': this.teaserHeight + 'px',
                    'padding-bottom': this.teaserHeight + 'px',
                    'margin-left': +this.margin + 'px',
                    'margin-right': +this.margin + 'px',
                };
            } else if (this.useMD3) {
                return {
                    'height': this.teaserHeight + 'px',
                    'padding-bottom': this.teaserHeight + 'px',
                };
            }
        }
        return '';
    }
    public initializeTeasers() {
        this.isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        //  this.useMD3 = this.casinoConfig.teasers.showMD3Teasers;
        // we alwayse use MD3 or MD5 fro Native
        if ((!this.useMD3 || !this.useMD5) && this.fromNative) {
            if (!this.useMD5) {
                this.useMD3 = true;
            } else {
                this.useMD5 = true;
            }
        }
        this.teasers = this.teasersSource;
        this.arrows = this.deviceService.isTouch || this.teasers?.length == 1 ? false : true;
        this.loop = this.teasers?.length == 1 ? false : true;
        this.oneTeaser = this.teasers?.length == 1 ? true : false;
        // if (this.teasers && this.teasers.length > 1) {
        //     this.arrows = true;
        //     this.loop = true;
        // }
        // else{
        //     this.arrows = false;
        //     this.loop=false;
        // }
        const allTeasers: any = [];
        if (this.teasers) {
            this.teasers.forEach((t: any) => {
                // if (t.title.length >= 30) {
                //     t.title = t.title.substring(0, 20) + '...';
                // }
                if (!t.teaserMode) {
                    t.teaserMode = '';
                }
                if (t.teaserMode.toUpperCase() === 'MOBILE' && this.isTouch) {
                    allTeasers.push(t);
                } else if (t.teaserMode.toUpperCase() === 'DESKTOP' && !this.isTouch) {
                    allTeasers.push(t);
                } else if (t.teaserMode == '' || t.teaserMode.toUpperCase() === 'BOTH') {
                    allTeasers.push(t);
                }
            });
        }
        this.teasers = [];
        this.teasers = allTeasers;
        this.isLandscape = this.isInLandscape();
        // populate teasers with data
        if (this.teasers && this.teasers.length) {
            // get width and height of screen - important!
            this.elementWidth = screen.width;
            this.elementHeight = screen.height;
            let teaserMaxWidth;
            if (this.useMD3) {
                // load smaller image
                teaserMaxWidth = this.elementWidth > this.elementHeight ? this.elementHeight : this.elementWidth;
            } else if (this.useMD5) {
                teaserMaxWidth = this.isPortraitMD5() ? this.elementWidth : this.elementWidth / 2;
            } else {
                teaserMaxWidth = this.elementWidth > this.elementHeight ? this.elementWidth : this.elementHeight;
            }
            this.teaserWidth = this.getMaxClosest(this.standardWidths, teaserMaxWidth);
            // this.extendImageData(this.teasers, this.teaserWidth);
            this.extendImageData(this.teasers);
            this.extendData(this.teasers);
            // this.refreshTeaserJackpots();
            this.getHeight();
            setTimeout(() => {
                this.swiperOptionsTeasers.navigation = this.isTouch || this.teasers?.length == 1 ? false : true;
                this.swiperOptionsTeasers.loop = this.teasers?.length > 1 ? true : false;
                this.swiperOptionsTeasers.autoplay = this.teasers?.length > 1 ? true : false;
                this.swiperOptionsTeasers.loop = this.teasers?.length > 1 ? true : false;
                this.swiperOptionsTeasers.autoplay = this.teasers?.length > 1 ? true : false;
            }, 100);
        }
    }
    public getMaxClosest(array: any, teaserMaxWidth: any) {
        let closestValue = this.closest(array, teaserMaxWidth);
        if (closestValue < teaserMaxWidth && array[array.length - 1] !== closestValue) {
            const i = array.indexOf(closestValue);
            closestValue = array[i + 1] ? array[i + 1] : closestValue;
        }
        return closestValue;
    }
    public track(where: any, what: any, isUrl: any) {
        this.tracking.updateDataLayer({
            pageName: 'mcTeasers',
            c15: '?source=' + where + '&' + (isUrl ? 'href=' : 'gameId=') + what,
        });
    }
    public trackGameLaunch(gameLaunchInfo: any, isUrl: boolean) {
        let gameId,
            gameData,
            gameType = '';
        if (isUrl) {
            const launchUrlIndex: number = gameLaunchInfo.indexOf(this.teaserLaunchRealtiveUri);
            if (launchUrlIndex > 0) {
                gameId = gameLaunchInfo.substr(launchUrlIndex + this.teaserLaunchRealtiveUri.length);
                gameData = this.gameService.getGameDataByGameId(gameId);
                gameType = gameData == null ? '' : gameData.launchType;
                this.tracking.updateDataLayer({
                    'component.Game.position': 1,
                    'component.Game.section': 'Teaser',
                    'component.gameName': gameId,
                    'component.gameType': gameType,
                });
            }
        } else {
            this.tracking.updateDataLayer({
                'component.Game.position': 1,
                'component.Game.section': '',
                'component.gameName': gameLaunchInfo,
                'component.gameType': gameType,
            });
        }
    }
    // update some values
    public extendData(teasers: any) {
        this.jackpotService.fetchJackpotValues().subscribe((response: any) => {
            this.jackpot = Array.from(Object.keys(response), (k) => response[k]);
            teasers.forEach((teaser: any) => {
                const test = this.jackpot.find((g: any) => g.jackpotGroupName === teaser.jackpotName);
                if (test) {
                    teaser.jackpotTickValue = parseFloat(test.value);
                    teaser.jackpotValue = test.amount;
                    teaser.jpCurSymbol = test.amount.substring(0, 1);
                }
            });
        });
    }
    public JackpotFeed() {
        // this.jackpotHandle = this.m2PubSub.subscribe('Jackpot.feedUpdate', () => {
        //  this.jackpot.startFeed();
        // this.refreshTeaserJackpots();
        //   });
    }
    // public refreshTeaserJackpots() {
    //     this.teasers.forEach((teaser: any) => {
    // if (teaser.jackpotName) {
    //       let jackpotsCache = this.jackpot.getJackpotData();
    //     angular.forEach(jackpotsCache, (jackpot: any) => {
    //         if (jackpot.jackpotGroupName === teaser.jackpotName) {
    //             if (teaser.jackpotValue !== jackpot.amount) {
    //                 teaser.jackpotTickValue = parseFloat(jackpot.value);
    //                 teaser.jackpotValue = jackpot.amount;
    //                 teaser.jpCurSymbol = jackpot.amount.substring(0, 1);
    //             }
    //         }
    //     });
    // }
    // });
    // }
    public updatedInfo() {
        this.isLandscape = this.isInLandscape();
        this.getHeight();
        try {
            // portrait or landscape
            if (this.isLandscape) {
                document.documentElement.classList.add('landscape');
                if (this.teaserHeight > 0) {
                    if (this.fromNative) {
                        this.windowRef.nativeWindow.document.body.style.height = 'auto';
                        // (this.windowRef.nativeWindow.document.body.style('body')).css('height', 'auto');
                        // let bodyElement: HTMLelement = window.document.
                        // (window.document.getElementsByTagName('body')).('height', 'auto');
                    }
                }
            } else {
                document.documentElement.classList.remove('landscape');
                if (this.fromNative) {
                    this.windowRef.nativeWindow.document.body.style.height = 'auto';
                    // (this.windowRef.nativeWindow.document.body.style('body')).css('height', 'auto');
                }
            }
        } catch (err) {
            this.logger.error('Exception: ' + err);
        }
        this.styleProperties();
        this.marginProperties();
        this.teaserLandscape = this.elementWidth >= 480;
    }

    //--------------------for Native Casino-------------------------------------------
    public addSliderEvent(index: number, timer: number) {
        setTimeout(() => {
            //bwinex://?event=promo&action=update&contentid=x
            if (this.fromNative && this.NativeApi) {
                if (this.slideEventId || this.slideEventId !== null) {
                    this.NativeApi.clearEvent(this.slideEventId);
                }
                const teaserInfo = 'contentid=' + index;
                this.slideEventId = this.NativeApi.raiseEvent('promo', 'update', teaserInfo);
            }
        }, timer);
    }
    public addRotateEvent(elementWidth: any, timer: any) {
        setTimeout(() => {
            if (this.fromNative && this.NativeApi) {
                if (this.eventId !== null) {
                    this.NativeApi.clearEvent(this.eventId);
                }
                const size = 'width=' + elementWidth + '&height=' + this.teaserHeight;
                this.eventId = this.NativeApi.raiseEvent('promo', 'size', size);
            }
        }, timer);
    }
    public teaserClick(event: any, teaser: any) {
        event.stopPropagation();
        if (this.fromNative || this.isNewSportsNative) {
            let link = undefined;
            if (teaser.teaserNativeLink) {
                link = teaser.teaserNativeLink.url;
            }
            if (link !== undefined) {
                this.track('tp', link, true);
                this.trackGameLaunch(link, true);
                const options = { skipLoadingIndicator: false };
                if (link.substr(link.length - 4, 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                const infiIndex = link.indexOf('?event=promo');
                if (infiIndex === -1) {
                    // this.Navigator.goTo(link, options);
                    this.Navigator.goTo(link);
                } else {
                    if (this.NativeApi) {
                        this.eventClickId = this.NativeApi.raiseTeaserEvent(this.eventClickId, link.substr(infiIndex));
                    }
                }
            }
        } else {
            if (teaser.gameId) {
                this.m2PubSub.publish('Teaser.specialClick', {
                    gameId: teaser.gameId,
                });
                this.track('t', teaser.gameId, true);
                this.trackGameLaunch(teaser.gameId, false);
            } else if (teaser.teaserLink) {
                this.track('tp', teaser.teaserLink.url, true);
                this.trackGameLaunch(teaser.teaserLink.url, true);
                const options: any = { skipLoadingIndicator: false };
                if (teaser.teaserLink.url.substr(teaser.teaserLink.url.length - 4, 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                this.Navigator.goTo(teaser.teaserLink.url, options);
            }
        }
    }

    public ctaClick(event: any, teaser: any, bannerPos: number) {
        let activeRoute: string = this.Navigator.location.absUrl().substr(this.Navigator.location.absUrl().lastIndexOf('/') + 1);
        if (activeRoute === 'bingo' || activeRoute === '' || activeRoute === 'en') {
            activeRoute = 'home';
        }
        event.stopPropagation();
        this.tracking.triggerEvent('Event.Clicks', {
            'component.CategoryEvent': 'Teaser_Banners',
            'component.LabelEvent': 'Bingo _' + unescape(teaser.teaserId),
            //   'component.LabelEvent': (this.casinoLobbyService.getProductForGivenLobbyType(this.lobbyType) ? this.casinoLobbyService.getProductForGivenLobbyType(this.lobbyType).toUpperCase() : '') + '_' + unescape(teaser.teaserId),
            'component.ActionEvent': 'CTA Click',
            'component.PositionEvent': 'Teaser_' + bannerPos,
            'component.LocationEvent': this.categoryName ? this.categoryName : activeRoute.split('?')[0],
            'component.EventDetails': 'CTA Name_' + teaser.ctaTitle,
            'component.URLClicked': teaser.ctaLink.url ? teaser.ctaLink.url : '',
        });
        if (this.fromNative || this.isNewSportsNative) {
            let link = undefined;
            if (teaser.ctaNativeLink) {
                link = teaser.ctaNativeLink.url;
            }
            if (link !== undefined) {
                this.track('tp', link, true);
                this.trackGameLaunch(link, true);
                const options = { skipLoadingIndicator: false };
                if (link.substr(link.length - 4, 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                const infiIndex = link.indexOf('?event=promo');
                if (infiIndex === -1) {
                    this.Navigator.goTo(link);
                } else {
                    if (this.NativeApi) {
                        this.eventClickId = this.NativeApi.raiseTeaserEvent(this.eventClickId, link.substr(infiIndex));
                    }
                }
            }
        } else if (teaser.ctaLink) {
            this.track('tp', teaser.ctaLink.url, true);
            this.trackGameLaunch(teaser.ctaLink.url, true);
            const options = { skipLoadingIndicator: false };
            if (teaser.ctaLink.url.substr(teaser.ctaLink.url.length - 4, 4).toLowerCase() === '.apk') {
                options.skipLoadingIndicator = true;
            }
            this.Navigator.goTo(teaser.ctaLink.url);
            if (this.isTouch) {
                this.Navigator.goTo(teaser.ctaLink.url);
            }
        }
    }
    onTeaserIndexChanged(swiperObj: any) {
        const index = swiperObj?.realIndex;
        if (this.teasers.length > 0) {
            // event.stopPropagation();
            const item: any = this.teasers[index];
            if (item != undefined && item != null) {
                const activeRoute: string = this.Navigator.location.absUrl().substr(this.Navigator.location.absUrl().lastIndexOf('/') + 1);
                this.tracking.triggerEvent('contentView', {
                    'component.CategoryEvent': 'Teaser_Banners',
                    'component.LabelEvent': 'Bingo _' + unescape(item.teaserId),
                    'component.ActionEvent': 'load',
                    'component.PositionEvent': 'Teaser_' + (index + 1),
                    'component.LocationEvent': this.categoryName ? this.categoryName : activeRoute.split('?')[0], // this.teaserLocation + '_Teaser' + (this.casinoLobbyService.getCategoryName(this.lobbyType, this.teaserCategory) != '' ? '_' : '') + this.casinoLobbyService.getCategoryName(this.lobbyType, this.teaserCategory),
                    'component.EventDetails': unescape(item.teaserId),
                    'component.URLClicked': 'not applicable',
                });
            }
        }
    }
    //     teaser.ctaLink.url = 'https://www.google.com/';
    // if(this.isTouch){
    // this.Navigator.navigate(teaser.ctaLink.url, options);
    // }
    // else{
    // let gameData: any = {
    // url:teaser.ctaLink.url
    // };
    // gameService.setOverlayVisibility(true);
    // gameService.gameDataInfo(gameData);
    // }
    public termsClick(event: any, teaser: any) {
        event.stopPropagation();
        if (this.fromNative || this.isNewSportsNative) {
            let link = undefined;
            if (teaser.termsNativeLink) {
                link = teaser.termsNativeLink.url;
            }
            if (link !== undefined) {
                this.track('tp', link, true);
                const options = { skipLoadingIndicator: false };
                if (link.substr(link.length - 4, 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                const infiIndex = link.indexOf('?event=promo');
                if (infiIndex === -1) {
                    this.Navigator.goTo(link);
                } else {
                    if (this.NativeApi) {
                        this.eventClickId = this.NativeApi.raiseTeaserEvent(this.eventClickId, link.substr(infiIndex));
                    }
                }
            }
        } else if (teaser.termsLink) {
            this.track('tp', teaser.termsLink.url, true);
            const options = { skipLoadingIndicator: false };
            if (teaser.termsLink.url.substr(teaser.termsLink.url.length - 4, 4).toLowerCase() === '.apk') {
                options.skipLoadingIndicator = true;
            }
            this.Navigator.goTo(teaser.termsLink.url);
        }
    }
    mouseHover(hover: boolean) {
        if (hover) {
            this.swiperOptionsTeasers.autoplay = false;
        } else {
            this.swiperOptionsTeasers.autoplay = true;
        }
    }
    //to do : add host listener
    @HostListener('window:resize') resize() {
        if (!this.fromNative) {
            if (this.windowRef.nativeWindow.document.activeElement!.nodeType == Node.TEXT_NODE && !this.isLandscape) {
                // Keyboard is visible
                return;
            }
            this.updatedInfo();
        }
    }
    // $window.addEventListener('resize', () => {
    // if (!this.fromNative) {
    // if ($document[0].activeElement.type === 'text' && !this.isLandscape) {
    // // Keyboard is visible
    // return;
    // }
    // this.updatedInfo();
    // }
    // });
    // fix teaser position on Casino Native App
    @HostListener('window:touchmove', ['$event']) touchmove(e: any) {
        if (this.fromNative) {
            e.preventDefault();
        }
    }
    jackpotSubTitle(teaser: any) {
        return {
            'color': teaser.attributes.subtitleColor,
            'font-size': teaser.attributes.subTitleFontSize + 'px',
            'font-weight': teaser.attributes.subTitleFontWeight,
        };
    }
    playbingoTracking(playlink: any) {
        this.tracking.triggerEvent('Event.Clicks', {
            ' component.CategoryEvent': 'Play Bingo Button',
            'component.LabelEvent': 'Play Bingo Now',
            'component.ActionEvent': 'Play Bingo Button - Displayed',
            'component.PositionEvent': 1,
            'component.LocationEvent': 'Bingo Lobby',
            'component.EventDetails': 'CTA to Launch Bingo',
            'component.URLClicked': playlink.url,
        });
    }
    playbingoclick(playlink: any) {
        this.playbingoTracking(playlink);
        this.gameLaunchService.initiateGameLaunch(
            playlink.htmlAttributes.gameName,
            '',
            playlink.htmlAttributes.gameType,
            playlink.htmlAttributes.ballType,
            playlink.htmlAttributes.launchType,
            playlink.htmlAttributes.gameIcon,
            playlink.htmlAttributes.gameId,
            playlink.htmlAttributes.favstatus,
            0,
            1,
            playlink.htmlAttributes.originUrl,
            '',
            playlink.htmlAttributes.themeid,
        );
    }
}
