import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, DoCheck, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import {
    BingoConfig,
    BingoConfigProviderService,
    BingoWidgetService,
    EventEmitService,
    FeaturesClientConfig,
    GameLaunchService,
    RoomsInfoService,
    ScheduleService,
    SingleEntryPointClientConfig,
    SuperLinksClientConfig,
} from '@frontend/bingo/core';
import { DeviceService, UserService } from '@frontend/vanilla/core';
import { SuperLinkPopupComponent } from 'packages/bingo/app/src/super-link-popup/super-link-popup.component';
import { Subscription } from 'rxjs';

import { DesignSystemEnablerService } from '../../../../frontend-lib/core/src/shared/services/ds-enabler.service';
//import { NavigationService, ProductHomepagesConfig } from '@frontend/vanilla/core';
import { Games } from '../../models/games';
import { ModelDefault, TrackingModel } from '../../shared/components/Tracking/TrackingModel';
import { TrackingModelService } from '../../shared/components/Tracking/tracking-modal-service';
import { PrebuyService } from '../../shared/services/pre-buy.service';
import { LockedRoomCardComponent } from './room-locked-card/room-locked-card.component';

@Component({
    selector: 'bg-rooms-item-component',
    templateUrl: 'rooms-item.component.html',
    styleUrls: ['rooms-item.component.css'],
    animations: [
        trigger('barAnimation', [
            state(
                'initial',
                style({
                    transform: '{{initial_state}}',
                }),
                { params: { initial_state: 'scaleX(1)' } },
            ),
            state(
                'game-on',
                style({
                    transform: 'scaleX(0)',
                }),
            ),
            transition('initial => game-on', [animate('{{timing}}')], {
                params: { timing: '10s' },
            }),
        ]),
    ],
})
export class RoomsItemComponent implements OnInit, OnChanges, DoCheck, AfterViewInit, OnDestroy {
    initData: any;
    sub: any;
    @Input() showVerticalRoomsInHomePage: any;
    @Input() bingoItem: Games;
    @Input() GameData: Games;
    @Input() position: number;
    @Input() gridSize: number;
    @Output() hide: EventEmitter<boolean> = new EventEmitter<boolean>();

    isMobileView: boolean;
    thumbImageSrc: string;
    iconImageSrc: string;
    IsPreBuyEnabled: boolean;
    isLegacy: boolean = false;
    disableAllPrebuy: boolean;
    isTabletView: boolean;
    ingame_rooms_status: boolean = false;
    IsFavouriteEnabled: boolean;
    cartegoriesList: any;
    roomIconslist: any;
    roomnavlist: any;
    combinedRoomsCount: any;
    isInGameClient: boolean = false;
    bingoGameMappings: any;
    isHome: boolean = false;
    jpAnimationInerval: any;
    eventSubscription: Subscription;
    friendsRoomIds: number[];
    isFriendsRoom: boolean;
    isFabGrabRoom: boolean;
    friendsBingoCopyRights: string;
    isPhysicalPrize: boolean;
    physicalPrizeText: string;
    isBonusPrize: boolean;
    bonusPrizeText: string;
    IsProgressBar: boolean;
    lobbyUrl: any;
    bgRooms: any = [];
    globalClientConfig: any;
    playerSegmentationObs: Subscription;
    roomLockedText: string = 'Room Locked';
    moreInfoText: string = 'More Info';
    innerWidth: number = 0;
    featureType: any;
    showIconImage: boolean = false;
    isGrayedOut: boolean;
    isPlayButtonDisabled: boolean = false;
    disablePreBuy: boolean;
    playingSoonText: string;
    greyoutInterval: any;
    privateRoom: boolean = false;
    displayPrivateRoom: boolean = false;
    dynaconPlayerCount: any;
    enableIconReplacement: boolean;
    combinedPriceText: string = '';
    combinedPrizeText: string = '';
    dynaconParams: any;
    bingoconfig: BingoConfig;
    featureConfig: FeaturesClientConfig;
    singleEntryPointConfig: SingleEntryPointClientConfig;
    superLinksClientConfig: SuperLinksClientConfig;
    private isTimeBarActive: boolean = false;
    // isPlayButtonDisabled: boolean = false;
    private isAnimationStateSet: boolean = false;
    private cachedAnimationState: object;
    private time: any;
    private superLinkPopupScrollY: number;
    trackingModel: TrackingModel;

    constructor(
        private activeroute: ActivatedRoute,
        private deviceService: DeviceService,
        private designSystemEnablerService: DesignSystemEnablerService,
        private elem: ElementRef,
        private gameLaunchService: GameLaunchService,
        private eventService: EventEmitService,
        private roomsInfoService: RoomsInfoService,
        private prebuyService: PrebuyService,
        private bingoWidget: BingoWidgetService,
        private user: UserService,
        private scheduleService: ScheduleService,
        private configProviderService: BingoConfigProviderService,
        private dialog: MatDialog,
        private router: Router,
        private tracking: TrackingModelService,
    ) {
        this.featureConfig = this.configProviderService.provideFeaturesConfig();
        this.bingoconfig = this.configProviderService.provideBingoConfig();
        this.superLinksClientConfig = this.configProviderService.provideSuperLinksClientConfig();
        this.singleEntryPointConfig = this.configProviderService.provideSingleEntryPointClientConfig();
        if (this.activeroute.snapshot.queryParamMap.get('hideHeader') == 'true') {
            this.isInGameClient = true;
        }
        this.eventSubscription = this.eventService.getEventObservable().subscribe((event) => {
            if (this.bingoItem && event.favoriteUpdated && event.favoriteUpdated.split(',').indexOf(this.bingoItem.id) != -1) {
                this.bingoItem.isFavourite = !this.bingoItem.isFavourite;
            }
        });
        this.trackingModel = new TrackingModel();
    }

    ngOnInit() {
        this.isLegacy = this.designSystemEnablerService.isEnabled();
        this.activeRoute();
        this.dynaconParams = {
            dynaconPlayerCount: this.featureConfig?.playerCount,
            potPlayerCurrencyDigits: this.featureConfig?.potPlayerCurrencyDigits,
        };
        this.cartegoriesList = this.activeroute.snapshot.data['config']?.bingoCategories;
        this.roomnavlist = this.cartegoriesList?.find((x: any) => x.categoryType == 'schedule');
        this.roomIconslist = this.cartegoriesList?.find((x: any) => x.categoryType == 'rooms');
        this.showIconImage = this.featureConfig.showIconImage;
        if (this.bingoItem?.isCombinedRoom) {
            const globalSEPTexts = this.activeroute.snapshot.data['config']?.globalSEPTexts;
            this.enableIconReplacement = this.singleEntryPointConfig?.enableIconReplacement;
            if (this.enableIconReplacement && globalSEPTexts) {
                this.combinedPriceText =
                    globalSEPTexts[String(this.bingoItem?.combinedVariantName + '_' + globalSEPTexts?.variantAppendingPriceText)];
                this.combinedPrizeText =
                    globalSEPTexts[String(this.bingoItem?.combinedVariantName + '_' + globalSEPTexts?.variantAppendingPrizeText)];
            }
            this.thumbImageSrc = this.bingoItem.roomImage
                ? this.bingoItem.roomImage
                : this.roomsInfoService.getImage(this.bingoItem.combinedVariantName, this.bgRooms);
            this.iconImageSrc = this.showIconImage ? this.roomsInfoService.getIconImage(this.bingoItem.combinedVariantName, this.bgRooms) : '';
        } else {
            this.thumbImageSrc = this.roomsInfoService.getImage(this.bingoItem.id, this.bgRooms);
            this.iconImageSrc = this.showIconImage ? this.roomsInfoService.getIconImage(this.bingoItem.id, this.bgRooms) : '';
        }
        this.combinedRoomsCount = this.globalClientConfig?.messages.CombinedRoomsCount;
        this.IsPreBuyEnabled = this.featureConfig.isPreBuyEnabled;
        this.disableAllPrebuy = this.featureConfig.disableAllPrebuy;
        this.IsFavouriteEnabled = this.featureConfig.isFavouriteEnabled;
        this.showIconImage = this.featureConfig.showIconImage;
        this.IsProgressBar = this.featureConfig.enableProgressBar;
        this.isTabletView = this.deviceService.isTablet;
        this.isMobileView = this.deviceService.isMobilePhone;
        this.innerWidth = window.innerWidth;
        this.friendsRoomIds = [];
        this.isGrayedOut =
            this.bingoItem.lobby_inactive == null && this.bingoItem.lobby_inactive == false
                ? false
                : this.bingoItem.lobby_inactive == true
                  ? true
                  : false; // grayedOut room check
        this.playingSoonText = this.globalClientConfig.messages.playingSoon;
        let fabGrabRoomIds = [];
        if (this.globalClientConfig.messages.friendsRoomIds) {
            this.friendsRoomIds = this.globalClientConfig.messages.friendsRoomIds.split(',');
            if (this.friendsRoomIds.length > 0 && this.friendsRoomIds.indexOf(this.bingoItem.id) > -1) {
                this.friendsBingoCopyRights = this.roomsInfoService.getFriendsCRContent(this.bingoItem.id, this.bgRooms);
                this.isFriendsRoom = true;
            }
        }

        if (this.globalClientConfig.messages.fabGrabRoomIds) {
            fabGrabRoomIds = this.globalClientConfig.messages.fabGrabRoomIds.split(',');
            if (fabGrabRoomIds.length > 0 && fabGrabRoomIds.indexOf(this.bingoItem.id) > -1) {
                this.isFabGrabRoom = true;
            }
        }

        if (this.isHome) {
            this.isMobileView = false;
        }
        this.bingoGameMappings = this.activeroute.snapshot.data['config']?.pageConfig?.value?.bingoGameMappings;

        const iframe_ingame_status = this.activeroute.snapshot.queryParamMap.get('ingame');
        if (iframe_ingame_status !== null && iframe_ingame_status !== undefined) {
            if (iframe_ingame_status) {
                this.ingame_rooms_status = true;
            }
        }

        if (this.bingoItem.features.indexOf('physical_prize') > -1) {
            this.isPhysicalPrize = true;
            this.physicalPrizeText = this.globalClientConfig.messages.physicalPrizeText;
        }
        if (this.featureConfig.enableBonusImplementation && this.bingoItem.bonus_info) {
            this.isBonusPrize = true;
            this.bonusPrizeText = this.globalClientConfig.messages.bonusPrizeText;
        }
        this.time = 60;
        // this.enablePrivateRooms = this.featureConfig.enablePrivateRooms;
        this.bingoItem.displayRoom = true;
        //if (this.featureConfig.enableOptimove) {
        this.segmentation();
        //}
        // if (this.featureConfig.enablePrivateRooms) {
        //     this.checkPrivateRoom();
        // }
        this.setIntervalForGrayedout();
    }

    setIntervalForGrayedout() {
        if (this.greyoutInterval) {
            clearInterval(this.greyoutInterval);
            this.grayedOutCheck();
        } else {
            this.grayedOutCheck();
        }
    }

    grayedOutCheck() {
        const currTime = new Date().getTime();
        const prebuy_process = this.bingoItem.prebuy_process == null ? 0 : this.bingoItem.prebuy_process;
        const time = this.bingoItem.status.state_end_time * 1000 - currTime - Number(prebuy_process) * this.featureConfig.grayedOutConvertion + 1000;
        if (this.isGrayedOut) {
            this.greyoutConditionCheck();
            this.greyoutInterval = setInterval(() => {
                this.greyoutConditionCheck();
            }, time);
        }
    }

    segmentation() {
        const siteCoreRoom = this.roomsInfoService.getRoomInfo(this.bingoItem.id, this.bgRooms);
        this.bingoItem.displayRoom = true;
        if (!this.bingoconfig.enableSchedulePostMethod) {
            if (this.featureConfig.enableOptimove && this.user.isAuthenticated) {
                let isLockedRoom: boolean;
                this.playerSegmentationObs = this.scheduleService.playerSegmentDataObservable.subscribe((data: any) => {
                    if (data != null && data.playerValueSegment != null) {
                        const playerValueSegment = data.playerValueSegment;
                        isLockedRoom = !this.scheduleService.segmentationRules(siteCoreRoom, playerValueSegment);
                        if (isLockedRoom) {
                            this.bingoItem.displayRoom = siteCoreRoom.display;
                            this.scheduleService.storeLockedRooms(siteCoreRoom.roomId);
                        } else {
                            this.bingoItem.displayRoom = true;
                        }
                        this.bingoItem.isRoomLocked = isLockedRoom;
                        if (siteCoreRoom && siteCoreRoom.lockRoomInLogout) {
                            this.bingoItem.lockRoomInLogoutStage = false;
                        }
                    }
                });
            } else {
                if (siteCoreRoom && siteCoreRoom.lockRoomInLogout) {
                    this.bingoItem.lockRoomInLogoutStage = true;
                }
            }
        }
        this.bingoItem.roomLockTitle = siteCoreRoom.errorMessageTitle;
        this.bingoItem.roomLockDescription = siteCoreRoom.errorMessageDescription;
        this.bingoItem.optimoveLabel = siteCoreRoom.label;
    }

    roomLockedMoreInfo(bingoItem: any) {
        const modelData: any = {
            title: bingoItem.roomLockTitle,
            description: bingoItem.roomLockDescription,
        };
        this.dialog.open(LockedRoomCardComponent, {
            data: modelData,
            panelClass: 'moreinfo-dialog',
        });
        //sitecore image
    }

    renderRoomClass() {
        let roomitemClass;
        roomitemClass = this.isFabGrabRoom
            ? this.bingoItem.isCombinedRoom
                ? 'roomcozy-combined friends-room fabgrab-room'
                : 'friends-room fabgrab-room'
            : this.isFriendsRoom
              ? this.bingoItem.isCombinedRoom
                  ? 'roomcozy-combined friends-room'
                  : 'friends-room'
              : this.bingoItem.isCombinedRoom
                ? 'roomcozy-combined'
                : '';
        if (
            this.bingoItem.isRoomLocked ||
            this.bingoItem.lockRoomInLogoutStage ||
            (this.bingoItem.displayPrivateRoom && this.bingoItem.privateRoom)
        ) {
            roomitemClass = this.isGrayedOut ? roomitemClass + ' card-greyout roomlack' : roomitemClass + ' roomlack';
        } else {
            roomitemClass = this.isGrayedOut ? roomitemClass + ' card-greyout' : roomitemClass;
        }
        return roomitemClass;
    }

    activeRoute() {
        const HomeView = this.activeroute.snapshot.data['Home'] || this.activeroute?.snapshot?.routeConfig?.path?.includes('bingowidget/cozybingo');
        if (HomeView) {
            this.isHome = true;
            if (this.showVerticalRoomsInHomePage) this.isHome = false;
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.fadeAnimation();
            // this.gameIconReduction('card');
        }, 500);
    }

    fadeAnimation(): void {
        const fadeviewparent = this.elem.nativeElement.querySelectorAll('.jackpot-values');
        for (let i = 0; i < fadeviewparent.length; i++) {
            this.FadeInOut(fadeviewparent[i].children);
        }
    }

    FadeInOut(viewelements: any) {
        let count = -1;
        if (viewelements.length > 1) {
            this.jpAnimationInerval = setInterval(() => {
                const reviewLength = viewelements.length - 1;
                if (count < reviewLength) {
                    count++;
                } else {
                    count = 0;
                }
                for (let i = 0; i <= reviewLength; i++) {
                    viewelements[i].classList.add('pjp-fadeout');
                    viewelements[i].classList.remove('pjp-fadein');
                    viewelements[count].classList.add('pjp-fadein');
                    viewelements[count].classList.remove('pjp-fadeout');
                    viewelements[i].classList.remove('pjp-values');
                    viewelements[count].classList.remove('pjp-values');
                }
            }, 3000);
        }
    }

    onTimeNotification(time: number) {
        this.time = +time;
        if (!this.isTimeBarActive && time != 0 && time <= this.featureConfig.progressbarTime) {
            this.isTimeBarActive = true;
        } else {
            this.isTimeBarActive = false;
        }
        // if (time <= 0) {
        //     this.isPlayButtonDisabled = true;
        // }
    }
    getAnimationState() {
        if (!this.time) {
            return;
        }

        if (this.isAnimationStateSet && this.isTimeBarActive) {
            this.cachedAnimationState = {
                value: 'game-on',
                params: { timing: `${this.time}s` },
            };
        }

        if (!this.isAnimationStateSet) {
            let temp;

            if (this.time >= 10) {
                temp = 'scaleX(1)';
            } else if (this.time < 10) {
                temp = `scaleX(${this.time / 10})`;
            }
            this.isAnimationStateSet = true;
            this.cachedAnimationState = {
                value: 'initial',
                params: { initial_state: temp },
            };
        }
        return this.cachedAnimationState;
    }
    preBuy(roomId: any) {
        const roomData = this.scheduleService.getFeatureTypesbyRoomId(roomId);
        if (roomData && !roomData.isHidden && !roomData.isCombinedRoom) {
            this.getPreBuy(roomId);
        } else {
            roomId = this.globalClientConfig.messages?.PreBuyBonusId ? this.globalClientConfig.messages?.PreBuyBonusId : roomId;
            const roomData = this.scheduleService.getFeatureTypesbyRoomId(roomId);
            if (roomData && (roomData.isCombinedRoom || roomData.isHidden)) {
                this.getPreBuy(roomId);
            } else {
                this.scheduleService.getGameData((response: any) => {
                    if (response) {
                        const roomData = this.scheduleService.getFeatureTypesbyRoomId(roomId);
                        if (roomData && (roomData.isCombinedRoom || roomData.isHidden)) {
                            this.getPreBuy(roomId);
                        }
                    }
                });
            }
        }
        this.trackEvent(roomData);
    }

    getPreBuy(roomId: any) {
        if (this.bingoWidget.isBingoWidget()) {
            const forceUrl = this.prebuyService.prebuy(this.GameData, roomId, 0, '');
            if (!this.user.isAuthenticated) {
                this.bingoWidget.postMessage('UserAction', {
                    promptLogin: true,
                    redirectUrl: forceUrl,
                    loginMessageKey: 'GameLaunch',
                });
                return;
            } else {
                this.bingoWidget.postMessage('redirectUrl_bingo', {
                    url: forceUrl,
                });
                return;
            }
        }
        this.prebuyService.prebuy(this.GameData, roomId, 0, '');
    }

    gameLaunch(data: any, event: Event) {
        if (
            this.superLinksClientConfig.enableSuperLinkPopup &&
            !data.prebuy_enabled &&
            data.isSuperLink &&
            !(data?.superLink?.parentRoomId == null) &&
            !this.isInGameClient
        ) {
            this.superLinkChildPlay(event);
        } else {
            this.gameLaunchService.initiateGameLaunch(
                data.name,
                'Bingo',
                'Bingo',
                data.bingo_type,
                'REGULAR',
                this.roomsInfoService.getImage(data.id, this.bgRooms),
                data.id,
                data.isFavourite,
                this.position,
                this.gridSize,
                '',
                '',
                data.themeid,
            );
        }
    }
    superLinkChildPlay(event: Event) {
        const roomData = this.roomsInfoService.getRoomInfo(this.bingoItem.id, this.bgRooms);
        const parentRoom = this.scheduleService.getFeatureTypesbyRoomId(this.bingoItem.superLink?.parentRoomId);
        this.bingoItem.childPlayDescription = this.bingoItem.childPlayDescription
            ?.replace('{0}', this.bingoItem.name)
            .replace('{1}', parentRoom?.name)
            .replace('{2}', parentRoom?.number_of_players);
        if (event) {
            event.stopImmediatePropagation();
            this.superLinkPopupScrollY = window.pageYOffset;
        }
        document.getElementsByTagName('html')[0].classList.add('superLinkPopup-open');
        const requiredModalData = {
            bingoItem: this.bingoItem,
            position: this.position,
            gridSize: this.gridSize,
            src: this.bingoItem.roomImage ? this.bingoItem.roomImage : roomData?.mobileImage?.src,
        };
        const dialogRef = this.dialog.open(SuperLinkPopupComponent, {
            width: '790px',
            data: requiredModalData,
        });

        dialogRef.afterClosed().subscribe(() => {
            if (this.superLinkPopupScrollY) {
                window.scrollTo(0, this.superLinkPopupScrollY);
            }
            this.hidePopup();
        });
    }

    hidePopup(): void {
        document.getElementsByTagName('html')[0]?.classList?.remove('superLinkPopup-open');
        this.hide.emit(false);
    }
    greyoutConditionCheck() {
        if (this.bingoItem.status.state_end_time) {
            this.isPlayButtonDisabled = true;
            this.bingoItem.disableGamelaunchOnGreyOut = true;
            this.disablePreBuy = false;
            this.bingoItem.prebuy_process = this.bingoItem.prebuy_process == null ? 0 : this.bingoItem.prebuy_process;
            const sumVal =
                this.bingoItem.status.state_end_time * 1000 - Number(this.bingoItem.prebuy_process) * this.featureConfig.grayedOutConvertion;
            const currTime = new Date().getTime();
            if (currTime >= sumVal && currTime < this.bingoItem.status.state_end_time * 1000) {
                this.isPlayButtonDisabled = false;
                this.bingoItem.disableGamelaunchOnGreyOut = false;
                this.disablePreBuy = true;
            }
        }
    }
    checkPrivateRoom() {
        if (this.bingoItem.game_tags && this.bingoItem.game_tags.length > 0) {
            this.bingoItem.game_tags.forEach((item: string) => {
                if (item == 'private' || item == 'newbie') {
                    if (!this.bingoItem.private_room) this.privateRoom = true;
                }
                if (item == 'postlogin-display') {
                    this.displayPrivateRoom = true;
                }
                if (item == 'prelogin-display' && !this.user.isAuthenticated) {
                    this.displayPrivateRoom = true;
                }
            });
        }
    }
    private gameIconReduction(gameClass: string) {
        if (document.querySelector('.click-interaction')) {
            const qiHoverDiv: any = document.querySelectorAll('.' + gameClass);
            if (qiHoverDiv.length > 0) {
                for (let i = 0; i < qiHoverDiv.length; i++) {
                    qiHoverDiv[i].addEventListener('touchstart', function () {
                        qiHoverDiv[i].style.transform = 'scale(0.98)';
                    });
                    qiHoverDiv[i].addEventListener('touchend', function () {
                        qiHoverDiv[i].style.transform = 'scale(1)';
                    });
                }
            }
        }
    }
    ngOnChanges() {
        if (this.bgRooms.length == 0 || !this.globalClientConfig) {
            this.bgRooms = this.activeroute.snapshot.data['config'].rooms;
            this.globalClientConfig = this.activeroute.snapshot.data['config'].global;
        }
        this.bingoItem.displayRoom = true;
        //  if (this.featureConfig.enableOptimove) {
        this.segmentation();
        //}
    }
    ngDoCheck() {
        this.setIntervalForGrayedout();
    }

    ngOnDestroy() {
        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
        }
        if (this.playerSegmentationObs) {
            this.playerSegmentationObs.unsubscribe();
        }

        if (this.jpAnimationInerval) clearInterval(this.jpAnimationInerval);
        if (this.greyoutInterval) clearInterval(this.greyoutInterval);
    }

    trackEvent(roomData: any) {
        let LabelEvent: any = this.router.url.split('?')[0].split('/')[3] || 'home';
        const value: any = LabelEvent.replace('/en/bingo/', '');
        this.trackingModel.eventName = 'Event.Tracking';
        this.trackingModel.CategoryEvent = 'bingo';
        this.trackingModel.LabelEvent = value;
        this.trackingModel.ActionEvent = 'click';
        this.trackingModel.PositionEvent = ModelDefault.NA;
        this.trackingModel.LocationEvent = roomData?.name;
        this.trackingModel.EventDetails = 'pre-buy';
        this.trackingModel.URLClicked = ModelDefault.NA;
        this.tracking.submitTracking(this.trackingModel);
    }
}
