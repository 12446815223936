<div class="filter-sort-main">
    <div class="filter-sort-body">
        <div class="filter-by-category">
            <div class="filter-by-header">{{ _filterByCategoryText }}</div>
            <div class="filter-by-list">
                <div
                    class="button-item"
                    *ngFor="let filterItem of filterList"
                    [class]="filterItem.category_class"
                    [ngClass]="{ selected: filterItem.selected }"
                    (click)="addClass(filterItem)">
                    {{
                        (filterItem.Type == 'bingo_type'
                            ? filterItem.category + ' ' + _ballText
                            : filterItem.Type == 'ALL'
                              ? _allText
                              : filterItem.category
                        ) | uppercase
                    }}
                </div>
            </div>
        </div>
        <div class="sort-main">
            <div class="sort-by-header">{{ _SortByText }}</div>
            <div class="sort-by-list">
                <div class="sort-item" *ngFor="let sortItem of SortList">
                    <label class="sort-item-radio-button">
                        <input
                            type="radio"
                            [checked]="this.selectedSortOption && sortItem.sortKey === this.selectedSortOption.sortKey"
                            class="radio-buton"
                            name="radiogroup"
                            (click)="selectedSortOption = sortItem" />
                        <span class="sort-name">{{ sortItem.sortBy | uppercase }}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="button-result" (click)="applyFilterAndSortingSelection()">
        <div>{{ _showResultsText }}</div>
    </div>
</div>
