import { Component, OnInit } from '@angular/core';

import { BingoConfigProviderService, ThunderBiteClientConfig } from '@frontend/bingo/core';

@Component({
    selector: 'bg-game-iframe-launcher-component',
    templateUrl: 'game-Iframe-launcher.component.html',
})
export class GameIframeLauncherComponent implements OnInit {
    jsonData: any;
    isThunderBiteEnabled: boolean;
    thunderBiteClientConfig: ThunderBiteClientConfig;
    constructor(private configProviderService: BingoConfigProviderService) {}
    ngOnInit() {
        this.thunderBiteClientConfig = this.configProviderService.provideThunderBiteClientConfig();
        this.isThunderBiteEnabled = this.thunderBiteClientConfig?.isThunderBiteEnabled;
        this.jsonData = {
            gameIdPath: this.thunderBiteClientConfig?.gameIdPath,
            thunderBiteIframeHeight: this.thunderBiteClientConfig?.thunderBiteIframeHeight,
        };
    }
}
