import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EventEmitService, GameLaunchService, ScheduleService } from '@frontend/bingo/core';
import { CookieService, LoginDialogService, UserService } from '@frontend/vanilla/core';

import { BingoFavouriteService } from '../shared/components/favourite/bingofavourite.service';

@Component({
    selector: 'bg-quick-info-modal',
    templateUrl: 'quick-info-modal.component.html',
})
export class QuickInfoModalComponent implements OnInit, OnDestroy {
    public hotIcon = 'https://casinogames.galaspins.com/htmllobby/images/newlmticons/square/hotjackpot.png';
    public favouriteStyle: string = 'theme-heart-solid';
    public unFavouriteStyle: string = 'theme-heart-outline';
    public isFavourite = false;
    public isLaunchedFromUrl: boolean;
    private eventEmitSubscription: any;
    public gameClick: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<QuickInfoModalComponent>,
        private gameLaunchService: GameLaunchService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private user: UserService,
        private cookie: CookieService,
        private loginDialogService: LoginDialogService,
        private eventEmitService: EventEmitService,
        private bingoFavouriteService: BingoFavouriteService,
        private scheduleService: ScheduleService,
    ) {}

    ngOnInit() {
        this.data.isLaunchedFromUrl || this.appendQuickInfoQueryParameterToUrl();
        this.eventEmitSubscription = this.eventEmitService.getEventObservable().subscribe((event) => {
            if (event.favoriteUpdatedFromWidget) {
                if (this.data.room.roomName == event.favoriteUpdatedFromWidget.roomName) {
                    this.data.room.isFavourite = event.favoriteUpdatedFromWidget.isFavourite;
                    //this.bingoFavouriteService.removeFavourite(event.favoriteUpdatedFromWidget);
                }
            }
        });
    }

    closeModal() {
        document.getElementsByTagName('html')[0].classList.remove('quickinfo-open');
        this.removeQuickInfoQueryParameterFromUrl();
        this.dialogRef.close({ data: this.gameClick });
    }

    launchGame() {
        this.gameClick = true;
        this.closeModal();
        if (!(!this.data.bingoItem.prebuy_enabled && this.data.bingoItem.isSuperLink)) {
            if (this.data.enableNewCozyLobby) {
                this.gameLaunchService.initiateGameLaunch(
                    this.data.room.name,
                    'Bingo',
                    'Bingo',
                    this.data.room.balls,
                    'REGULAR',
                    this.data.room.image,
                    this.data.room.id,
                    this.data.room.isFavourite,
                    this.data.position,
                    this.data.gridSize,
                    '',
                    '',
                    this.data.room.themeid,
                    null,
                );
            } else {
                this.gameLaunchService.initiateGameLaunch(
                    this.data.room.roomId,
                    'Bingovf',
                    'Bingovf',
                    this.data.room.balls,
                    'room',
                    this.data.room.image,
                    this.data.room.gameCode ? this.data.room.gameCode : this.data.room.roomName,
                    false,
                    this.data.position,
                    this.data.gridSize,
                    '',
                    '',
                    this.data.room.themeid,
                );
            }
        }
    }

    favoriteClick(event: Event, room: any) {
        event.stopPropagation();
        let roomId: any = this.data.enableNewCozyLobby ? room.id : room.roomName;
        if (this.user.isAuthenticated) {
            if (this.data.enableNewCozyLobby) {
                const roomIdsArray = this.scheduleService.getRoomsbyRoomId(room.id);
                if (roomIdsArray.length > 0) {
                    roomId = roomIdsArray.reduce((c: any, value: any) => (!c.includes(value) ? c.concat(value) : c), []).join(',');
                }
            }
            if (room.isFavourite) {
                room.isFavourite = this.data.enableNewCozyLobby ? room.isFavourite : false;
                this.bingoFavouriteService.toggleFavourite(roomId, true, '', '');
            } else {
                this.data.room.isFavourite = this.data.enableNewCozyLobby ? room.isFavourite : true;
                this.bingoFavouriteService.toggleFavourite(roomId, false, '', '');
            }
        } else {
            this.cookie.putObject('favouriteRoom', roomId);
            this.closeModal();
            this.loginDialogService.open();
        }
    }

    appendQuickInfoQueryParameterToUrl() {
        const locationParts = window.location.href.split('?');
        const locationWithoutQuery = locationParts[0];
        let query = '';
        if (locationParts[1]) {
            query = '?' + locationParts[1];
        }
        if (!location.href.includes('games-info'))
            window.history.pushState(location.href, '', locationWithoutQuery + '/games-info/' + this.data.roomTitle + query);
    }

    removeQuickInfoQueryParameterFromUrl() {
        const locationParts = window.location.href.split('?');
        let query = '';
        if (locationParts[1]) {
            query = '?' + locationParts[1];
        }
        const param = 'games-info';
        if (location.href.includes(param))
            window.history.pushState(location.href, '', location.href.substr(0, location.href.indexOf(param) - 1) + query);
    }
    ngOnDestroy() {
        this.closeModal();
        if (this.eventEmitSubscription) this.eventEmitSubscription.unsubscribe();
    }
}

// import { Component, OnInit, Inject, ElementRef } from '@angular/core';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// import { UserService } from '@frontend/vanilla/core';
// // OpenLoginDialog, GameLaunchService, CasinoManager, GameDataManager, JackpotDataService
// import { BingoFavouriteService } from '../shared/components/favourite/bingofavourite.service';
// import { CookieService } from 'ngx-cookie';
// import { GlobalClientConfig, FeaturesClientConfig, GameConfigClientConfig } from '../client-config';
//
// @Component({
//     moduleId: module.id,
//     selector: 'quick-info-modal',
//     templateUrl: 'quick-info-modal.component.html'
// })
// export class QuickInfoModalComponent implements OnInit {
//     isLaunchedFromUrl: any = false;
//     game: any;
//     gameTitle: string;
//     src: any;
//     gameProvider: any;
//     provider: any;
//     useFavouriteGames: boolean = false;
//     cta: string;
//     isTouch: boolean;
//     gamePosition: any;
//     gameLaunchedFrom: any;
//     categoryType: any;
//     gameDescription: any = null;
//     jackpotValue: any = null;
//     favouriteStyle: string = 'theme-favorite-i';
//     unFavouriteStyle: string = 'theme-favorite';
//     isFavourite: boolean = false;
//     stickers: any = [];
//     showstickersinoverlay: boolean = true;
//     showjackpotvalueinoverlay: boolean = true;
//     isHotJackpotGroup: boolean = false;
//     jackpotData: any = null;
//     enableHotIcon: boolean = false;
//     hotIcon: any;
//     vendorLogo: any = null;
//     gridPosition: any;
//     isStickersForUk: boolean = false;
//     constructor(public dialogRef: MatDialogRef<QuickInfoModalComponent>,
//         @Inject(MAT_DIALOG_DATA) public data: any,
//         private user: UserService,
//         private favouriteService: BingoFavouriteService,
//         private cookie: CookieService,
//         private loginDialog: OpenLoginDialog,
//         private gameLaunchService: GameLaunchService,
//         private global: GlobalClientConfig,
//         private features: FeaturesClientConfig,
//         private casinoManager: CasinoManager,
//         private gameConfig: GameConfigClientConfig,
//         private gameDataManager: GameDataManager,
//         private jackpotservice: JackpotDataService,
//         private elementRef: ElementRef) {
//     }
//
//     ngOnInit() {
//
//         this.showstickersinoverlay = this.casinoManager.IsFeatureEnabledforLobbytype(this.features.showStickersInOverlay);
//         this.showjackpotvalueinoverlay = this.casinoManager.IsFeatureEnabledforLobbytype(this.features.showJackpotvalueInOverlay);
//         this.setLocalData();
//         this.gameDataManager.getGameDescription(this.game.gameId).subscribe((res) => {
//             if (res)
//                 this.gameDescription = res.gameDescription;
//                 this.checkForAdjustment();
//
//         });
//         this.isTouch = this.casinoManager.isTouch();
//         this.isFavourite = this.favouriteService.checkForFavouriteGame(this.game);
//         if ((this.global.lobbyConfigurations.FavouriteHeart === "true") || (this.global.lobbyConfigurations.FavouriteHeart === "True") || (this.global.lobbyConfigurations.FavouriteHeart === "TRUE")) {
//             this.favouriteStyle = 'theme-heart-solid';
//             this.unFavouriteStyle = 'theme-heart-outline';
//         }
//         this.isLaunchedFromUrl || this.appendQuickInfoQueryParameterToUrl();
//         this.enableHotIcon = this.casinoManager.IsFeatureEnabledforLobbytype(this.features.enableHotIcon);
//         this.hotIcon = this.gameConfig.host + '/htmllobby/images/newlmticons/square/hotjackpot.png';
//         this.vendorLogo = this.gameConfig.host + this.gameConfig.gameIconTemplate.replace('{0}', 'vendorlogos').replace('{1}', this.game.ownerId) + '.jpg';
//         this.isStickersForUk = this.casinoManager.IsFeatureEnabledforLobbytype(this.features.stickersForUk);
//     }
//     ngAfterViewInit() {
//
//         if (this.isStickersForUk)
//             this.gameStickerStyleForUk();
//         else
//             this.gameStickerStyle();
//         this.checkForAdjustment();
//
//     }
//     checkForAdjustment() {
//         if (this.isTouch && window.innerHeight <= 480 &&this.gameDescription)  {
//             let overlay = this.elementRef.nativeElement.querySelectorAll(".qi-desktop-nrectangle");
//             if (overlay.length > 0)
//                 overlay[0].style.top = '10%';
//         }
//     }
//     setLocalData() {
//         this.gameTitle = this.data.gameTitle;
//         this.src = this.data.src;
//         this.gameProvider = this.data.gameProvider;
//         this.provider = this.data.provider;
//         this.game = this.data.game;
//         this.useFavouriteGames = this.data.useFavouriteGames;
//         this.cta = this.data.cta;
//         this.gamePosition = this.data.gamePosition;
//         this.gameLaunchedFrom = this.data.gameLaunchedFrom;
//         this.categoryType = this.data.categoryType;
//         this.isLaunchedFromUrl = this.data.isLaunchedFromUrl;
//         this.stickers = this.data.stickers;
//         this.gridPosition = this.data.gridPosition;
//         this.jackpotservice.jackpotFeedObservable.subscribe((_response: any) => {
//             if (_response) {
//                 let jpFeed = _response.get(this.game.gameId);
//                 if (jpFeed) {
//                     this.jackpotData = jpFeed;
//                     this.jackpotValue = this.jackpotData.amount;
//                     this.isHotJackpotGroup = this.jackpotData.isHotJackpotGroup;
//                 }
//             }
//         });
//     }
//     GameJackpotValue(game: any) {
//         return this.jackpotservice.getGameJackpotValue(game.gameId);
//     }
//     closeModal($event: any = null): void {
//         document.getElementsByTagName('html')[0].classList.remove('quickinfo-open');
//         $event && $event.stopImmediatePropagation();
//         this.isTouch || this.removeQuickInfoQueryParameterFromUrl();
//         this.dialogRef.close();
//     }
//     isFavouriteGame(game: any) {
//         return this.favouriteService.checkForFavouriteGame(game);
//     }
//     toggleFavourite(game: any, $event: any) {
//         $event.stopImmediatePropagation();
//         if (this.user.isAuthenticated) {
//             this.favouriteService.toggleGameFavourite(game);
//             this.isFavourite = !this.isFavourite;
//         } else {
//             this.cookie.putObject('favouriteGame', game);
//             this.closeModal();
//             this.loginDialog.open();
//         }
//     }
//     gameStickerStyle() {
//         let stickerStyle = this.global.messages.GameStickerStyle;
//         let sticker = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-0');
//         for (let i = 0; i < sticker.length; i++) {
//             sticker[i].style.cssText = stickerStyle;
//         }
//     }
//     gameStickerStyleForUk() {
//         let stickerStyle = this.global.messages.UkStickerStyle1;
//         let sticker = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-0');
//         for (let i = 0; i < sticker.length; i++) {
//             sticker[i].style.cssText = stickerStyle;
//         }
//         let stickerStyle1 = this.global.messages.UkStickerStyle2;
//         let sticker1 = this.elementRef.nativeElement.querySelectorAll('.promo-text-badge-1');
//         for (let i = 0; i < sticker1.length; i++) {
//             sticker1[i].style.cssText = stickerStyle1;
//         }
//     }
//     launchGame(game: any): void {
//         this.closeModal();
//         this.gameLaunchService.launchGame(game, this.isTouch, this.gamePosition, this.categoryType, 'q', false, false, false, false, this.gameLaunchedFrom, this.gridPosition)
//     }
//     appendQuickInfoQueryParameterToUrl() {
//
//         let param = Object.keys(this.data.routeParams).length > 0 ? '/game-info/':'game-info/';
//         //let param = window.location.href.indexOf('?') > -1 ? '&gameQuickinfo=' : '/gameQuickinfo/';
//         window.history.pushState(location.href, '', location.href + param + this.game.gameId);
//     }
//
//     removeQuickInfoQueryParameterFromUrl() {
//         let param = 'game-info';
//         window.history.pushState(location.href, '', location.href.substr(0, location.href.indexOf(param) - 1));
//     }
//
// }
//
//
