import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NavigationService } from '@frontend/vanilla/core';

import { BingoFavouriteService } from '../shared/components/favourite/bingofavourite.service';

@Component({
    selector: 'bg-favourite-redirect',
    template: '',
})
export class FavouriteRedirectComponent {
    roomId: string;
    isFavourite: boolean;
    favAddClass: string;
    favRemClass: string;
    lobbyUrl: string;

    constructor(
        private route: ActivatedRoute,
        private bingoFavouriteService: BingoFavouriteService,
        private navigator: NavigationService,
    ) {
        this.route.queryParams.subscribe((queryParams) => {
            this.roomId = queryParams['roomId'];
            const fav = queryParams['isFavourite'];
            if (fav === 'true') {
                this.isFavourite = true;
            } else {
                this.isFavourite = false;
            }
            this.favAddClass = queryParams['favAddClass'];
            this.favRemClass = queryParams['favRemClass'];
            this.lobbyUrl = queryParams['lobbyUrl'];
        });

        this.bingoFavouriteService.toggleFavourite(this.roomId, this.isFavourite, this.favAddClass, this.favRemClass);

        this.navigator.goTo(this.lobbyUrl);
    }
}
