import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';

import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import 'hammerjs';
//import { NguCarouselModule } from '@ngu/carousel';
import { LazyLoadImageModule } from 'ng-lazyload-image';

// This Module's Components
import { CasinoGamesLobbyComponent } from './casino-games-lobby.component';
import { EllipsisPipe } from './ellipsis.pipe';

@NgModule({
    imports: [
        MatTabsModule,
        CommonModule,
        //NguCarouselModule,
        LazyLoadImageModule,
        SwiperComponent,
    ],
    declarations: [CasinoGamesLobbyComponent, EllipsisPipe],
    exports: [CasinoGamesLobbyComponent, MatTabsModule],
})
export class CasinoGamesLobbyModule {}
