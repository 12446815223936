import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { BingoConfigProviderService, EventEmitService, ScheduleService } from '@frontend/bingo/core';
import { SessionStoreService } from '@frontend/vanilla/core';

// import { SortByList } from '../mock-filter-sort';
import { FilterAndSortingService } from './filter-and-sorting.service';

//import { EventEmitService } from '../shared/services/eventemit.service';
@Component({
    selector: 'bg-filter-and-sorting',
    templateUrl: './filter-and-sorting.component.html',
})
export class FilterAndSortingComponent implements OnInit, OnDestroy {
    filterList: any = [];
    SortList: any = [];
    Global: any;
    tempClass: boolean = false;
    filteredResultList: any = [];
    _SortByText: string;
    _filterByCategoryText: string;
    _showResultsText: string;
    _ballText: string;
    _allText: string;
    clientConfig: any;
    @Input() allItems: any;
    @Input() appliedFilters: any = [];
    @Input() selectedSortOption: any;
    @Output()
    onFilterApplied: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        private filterAndSortingService: FilterAndSortingService,
        private scheduleService: ScheduleService,
        private eventService: EventEmitService,
        private session: SessionStoreService,
        @Inject(DOCUMENT) private document: any,
        private configProviderService: BingoConfigProviderService,
        //private eventService: EventEmitService
    ) {}
    ngOnDestroy() {
        this.document.body.classList.remove('scroll-disable');
    }
    ngOnInit() {
        this.filterList = this.getfilterCategoriesByGameTagAndType();
        if (localStorage.getItem('config')) {
            this.clientConfig = JSON.parse(localStorage.getItem('config')!);
            if (!this.Global) this.Global = this.clientConfig?.global;
        }
        this.resetCatagoriesSelection();
        this.SortList = this.clientConfig?.sortTypes;
        this.filteredResultList = [];
        this.appliedFilters = JSON.parse(JSON.stringify(this.appliedFilters)!);
        this.resetCatagoriesSelection();
        this._SortByText = this.Global.messages['SortBy'];
        this._filterByCategoryText = this.Global?.messages?.filterByCategory;
        this._showResultsText = this.Global?.messages['showResults'];
        this._ballText = this.Global?.messages['Ball'];
        this._allText = this.Global?.messages['Alltext'] ? this.Global?.messages['Alltext'] : 'ALL';
        if (this.appliedFilters.length == 0) {
            this.appliedFilters.push({
                category: 'ALL',
                Type: 'ALL',
                selected: true,
                category_class: 'ALL',
            });
        }
        if (this.selectedSortOption === null || this.selectedSortOption == undefined) {
            this.selectedSortOption = {
                sortBy: 'Starting Time (soonest first)',
                sortDirection: '-1',
                sortKey: 'status.state_end_time',
                title: 'Starting Time (soonest first)',
                //   sortBy: "My Favourites first",
                // sortDirection: "1",
                // sortKey: "isFavourite",
                // title: "My Favourites first",
            };
        }

        const filterList = this.filterList;
        filterList.map((x: any) => (x.selected = false));
        this.appliedFilters.forEach((filterItem: any) => {
            filterList.map((x: any) => {
                if (x.category == filterItem.category && filterItem.selected) x.selected = true;
            });
        });
        this.filterList = filterList;
        this.filteredResultList = this.filterAndSortingService.filterItemsByCatagory(this.appliedFilters, this.allItems);
        this._showResultsText = this.Global?.messages['showResults']?.replace('{0}', this.filteredResultList.length);
        this.document.body.classList.add('scroll-disable');
    }
    addClass(obj: any) {
        let isAllClicked = false;
        obj.selected = !obj.selected;
        if (obj.selected) {
            this.appliedFilters.push(obj);
            if (obj.Type === 'ALL') {
                isAllClicked = true;
            }
        } else {
            const index = this.appliedFilters.findIndex((x: any) => x.category == obj.category);
            if (index > -1) this.appliedFilters.splice(index, 1);
        }
        if (isAllClicked) {
            this.appliedFilters = this.filterList.filter((a: any) => a.Type == 'ALL');
            this.filterList.map((x: any) => {
                if (x.Type != 'ALL') x.selected = false;
            });
        } else {
            this.appliedFilters = this.appliedFilters.filter((a: any) => a.Type != 'ALL');
            this.filterList.map((x: any) => {
                if (x.Type == 'ALL') x.selected = false;
            });
        }
        this.filteredResultList = this.filterAndSortingService.filterItemsByCatagory(this.appliedFilters, this.allItems);
        this._showResultsText = this.Global?.messages['showResults']?.replace('{0}', this.filteredResultList.length);
    }

    applyFilterAndSortingSelection() {
        this.scheduleService.getGameData((response: any) => {
            if (response.result.games_list != null && response.result.games_list != undefined) {
                response.result.games_list.forEach((i: any) => {
                    if (response.result.favouriteRooms != undefined) {
                        i.isFavourite = response.result.favouriteRooms.indexOf(i.id) >= 0;
                    }
                });
            }
            this.eventService.raiseEvent('filterClicked');
            this.allItems = response.result.games_list;
            this.filteredResultList = this.filterAndSortingService.filterItemsByCatagory(this.appliedFilters, this.allItems);
            this.onFilterApplied.emit({
                appliedFilterList: this.appliedFilters,
                filteredResultList: this.filteredResultList,
                selectedSortOption: this.selectedSortOption,
            });
            this.session.set('ScheduleFeed', response);
        });
    }
    getfilterCategoriesByGameTagAndType(): any {
        const roomCategory = Array.from(new Set(this.allItems.map((item: any) => item.bingo_type))).sort((a: any, b: any) => {
            if (a > b) {
                return -1;
            } else if (a < b) {
                return 1;
            } else {
                return 0;
            }
        });
        const gameTags = Array.from(new Set(this.allItems.map((item: any) => item.game_tags)));
        const gameArray = gameTags.concat.apply([], gameTags).filter((e: any) => {
            return e;
        });
        const uniqueGameTagsList = Array.from(new Set(gameArray));
        const filterCategories = [];
        filterCategories.push({
            category: 'ALL',
            Type: 'ALL',
            selected: true,
            category_class: 'ALL',
        });
        for (let i = 0; i < roomCategory.length; i++) {
            filterCategories.push({
                category: roomCategory[i],
                Type: 'bingo_type',
                selected: false,
                category_class: 'ball-' + roomCategory[i],
            });
        }
        for (let i = 0; i < uniqueGameTagsList.length; i++) {
            filterCategories.push({
                category: uniqueGameTagsList[i],
                Type: 'game_tags',
                selected: false,
                category_class: uniqueGameTagsList[i],
            });
        }
        return filterCategories;
    }
    resetCatagoriesSelection() {
        this.filterList.map((x: any) => (x.selected = false));
    }
}
