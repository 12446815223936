import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import {
    BingoConfig,
    BingoConfigProviderService,
    CasinoClientConfig,
    ConfirmMessageService,
    FeaturesClientConfig,
    GameLaunchService,
    PlayingNowClientConfig,
    RoomsInfoService,
} from '@frontend/bingo/core';

@Component({
    selector: 'bg-playing-now-component',
    templateUrl: 'playing-now.component.html',
})
export class PlayingNowComponent implements OnInit {
    iframeUrl: SafeResourceUrl;
    showIconImage: boolean = false;
    currentGames: any = [];
    isShow: boolean = true;
    isMiniGameInPorgress: boolean = true;
    roomImageDataList: any = [];
    activeGames: any = [];
    globalClientConfig: any;
    header: string = '';
    //isPayingNowActive :boolean:true;
    iframe: any;
    // [{ 'img_src': 'http://scmedia.cms.test.env.works/$-$/c13990db03a74d21bc69015cdf4aa0a7.png', 'bingo_type': '80', 'room_name': 'DOWN TOWN', 'id': '1' },
    // { 'img_src': 'http://scmedia.cms.test.env.works/$-$/8ac922b4302946d49099f5935eb88023.png', 'bingo_type': '90', 'room_name': 'PENNY BINGO', 'id': '12' },
    // { 'img_src': 'http://scmedia.cms.test.env.works/$-$/a915d0b60fa4419c814a7dbbe2305d22.png', 'bingo_type': '90', 'room_name': 'BIG BEN', 'id': '123' }];

    miniGame: any = [];
    isInGameClient: boolean = false;
    bingoclientconfig: BingoConfig;
    casinoConfiguration: CasinoClientConfig;
    featureConfig: FeaturesClientConfig;
    playingNowConfig: PlayingNowClientConfig;
    constructor(
        public confirmMessageService: ConfirmMessageService,
        private gameLaunchService: GameLaunchService,
        //private navigator: NavigationService,
        private sanitizer: DomSanitizer,
        private roomsInfoService: RoomsInfoService,
        private configProviderService: BingoConfigProviderService,
        private route: ActivatedRoute,
    ) {
        this.bingoclientconfig = this.configProviderService.provideBingoConfig();
        this.casinoConfiguration = this.configProviderService.provideCasinoClientConfig();
        this.featureConfig = this.configProviderService.provideFeaturesConfig();
        this.playingNowConfig = this.configProviderService.providePlayingNowClientConfig();
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.playingNowConfig.playingNowIframeUrl);
    }
    ngOnInit() {
        if (localStorage.getItem('config')) {
            const clientConfig = JSON.parse(localStorage.getItem('config')!);
            if (!this.globalClientConfig) this.globalClientConfig = clientConfig.global;
            if (this.roomImageDataList.length == 0) this.roomImageDataList = clientConfig.rooms;
        }
        const iframe_ingame_status = this.route.snapshot.queryParamMap.get('ingame');
        if (iframe_ingame_status) {
            //document.getElementById('main-content').classList.add('iframe-custom-model');
            this.isInGameClient = true;
        }
        this.iframe = <HTMLIFrameElement>document.getElementById('playing-now-iframe');
        this.header = this.globalClientConfig['messages'].playingNowHeader;
        this.showIconImage = this.featureConfig.showIconImage;
    }

    @HostListener('window:message', ['$event'])
    listenToChildIframe(event: any) {
        if (event.origin.indexOf(this.bingoclientconfig['bingoGameClientDomain']) > -1) {
            if (event.data.type === 'BingoComponentLoaded') {
                this.getPlayingNowGames();
            } else if (event.data.type === 'CurrentGames') {
                let roomnavindex: number;
                this.currentGames = event.data.data;
                let i = 0;
                if (this.currentGames != null && this.currentGames.length > 0)
                    this.currentGames.forEach((gamedata: any) => {
                        if (i < 3) i++;
                        else return;
                        roomnavindex =
                            this.roomImageDataList.findIndex((x: any) => x.roomId == gamedata.gameId) != -1
                                ? this.roomImageDataList.findIndex((x: any) => x.roomId == gamedata.gameId)
                                : this.roomImageDataList.findIndex((x: any) => x.roomId == 'default');
                        const roomImg = this.roomImageDataList[roomnavindex].mobileImage.src;
                        const iconimage = this.showIconImage ? this.roomsInfoService.getIconImage(gamedata.gameId, this.roomImageDataList) : '';
                        this.activeGames.push({
                            img_src: roomImg,
                            bingo_type: gamedata.ballType,
                            icon_image: iconimage,
                            room_name: gamedata.gameName,
                            id: gamedata.gameId,
                            isGameActive: gamedata.isGameActive,
                        });
                    });
            } else if (event.data.type === 'ActiveMiniGame') {
                if (event.data.data) {
                    const gameicon =
                        this.casinoConfiguration.gameIconUrlTemplate.replace('{0}', 'square').replace('{1}', event.data.data.gamename) + '.jpg';
                    this.miniGame = [{ img_src: gameicon, game_name: event.data.data.gamedisplayname, id: event.data.data }];
                }
            }
        }
    }

    getPlayingNowGames() {
        const message = {
            data: { ssoKey: decodeURIComponent(this.playingNowConfig.ssoKey) },
            type: 'GetCurrentGames',
        };
        this.iframe.contentWindow.postMessage(message, '*');
    }

    toggleActiveGames() {
        this.isShow = !this.isShow;
    }

    closePlayingRoom(activeGameId: number) {
        const message = this.globalClientConfig['messages'].closeGameMessage,
            header = this.globalClientConfig['messages'].closeGameHeader,
            closeBtn = this.globalClientConfig['messages'].confirmCloseButton,
            confirmButtonText = this.globalClientConfig['messages'].confirmButtonText;
        this.confirmMessageService.confirmThis(
            header,
            message,
            '',
            confirmButtonText,
            closeBtn,
            () => {
                const postMessage = {
                    data: { ssoKey: decodeURIComponent(this.playingNowConfig.ssoKey), game_id: activeGameId },
                    type: 'CloseGame',
                };
                this.iframe.contentWindow.postMessage(postMessage, '*');
                const index = this.activeGames.findIndex((x: any) => x.Id == activeGameId);
                this.activeGames.splice(index, 1);
            },
            () => {},
        );
    }

    closeMiniGame() {
        const message = this.globalClientConfig['messages'].closeGameMessage,
            header = this.globalClientConfig['messages'].closeGameHeader,
            closeBtn = this.globalClientConfig['messages'].confirmCloseButton,
            confirmButtonText = this.globalClientConfig['messages'].confirmButtonText;
        this.confirmMessageService.confirmThis(
            header,
            message,
            '',
            confirmButtonText,
            closeBtn,
            () => {
                const postMessage = {
                    data: { ssoKey: decodeURIComponent(this.playingNowConfig.ssoKey) },
                    type: 'RemoveMiniGame',
                };
                this.iframe.contentWindow.postMessage(postMessage, '*');
                this.miniGame.splice(0, 1);
            },
            () => {},
        );
    }
    gameLaunch(data: any, index: number) {
        this.gameLaunchService.initiateGameLaunch(
            data.room_name,
            'Bingo',
            'Bingo',
            data.bingo_type,
            'REGULAR',
            this.roomsInfoService.getImage(data.id, this.roomImageDataList),
            data.id,
            false,
            index,
            1,
            '',
            '',
            data.themeid,
        );
        // this.gameLaunchService.bingogameLaunch(data.room_name, 'Bingo', data.bingo_type, 'REGULAR', 'Bingo', data.id, false).subscribe(
        //     (response: any) => {
        //         if (response.redirectUrl) {
        //             response.redirectUrl = decodeURIComponent(response.redirectUrl);
        //             this.navigator.goTo(response.redirectUrl);
        //         }
        //         else {
        //             //this.messageQueueService.addError(this.globalClientConfig.messages['gameLaunchErrorMessage']);
        //         }
        //     }, (error) => {
        //         //this.messageQueueService.addError(this.globalClientConfig.messages['gameLaunchErrorMessage']);
        //     });
    }
    miniGameLaunch() {
        let activeGame = {};
        const index = this.activeGames.findIndex((x: any) => x.isGameActive == true);
        if (index != -1) {
            activeGame = this.activeGames[index];
        } else {
            activeGame = this.activeGames[0];
        }
        this.gameLaunch(activeGame, 0);
    }
    stringFormat(str: string, objs: any[]) {
        let i = 0;
        for (const arg of objs) {
            const strToReplace = '{' + i++ + '}';
            str = str.replace(strToReplace, arg.toString() || '');
        }
        return str;
    }
}
