<div class="superlinks-container">
    <div class="title-section">
        <div class="title-icon">
            <div class="ui-icon-combo">
                <span class="ui-icon-combo__fg theme-bingo-superlinks"></span>
                <span class="ui-icon-combo__bg theme-bingo-badge"></span>
            </div>
        </div>
        <div class="title-text">{{ superLinkText }}</div>
    </div>
    <div class="content-section">
        {{ data.bingoItem.childPlayDescription }}
    </div>
    <div class="btn-section">
        <button class="cancel-btn btn btn-light" (click)="closeModal()">CANCEL</button>
        <button class="ok-btn btn btn-primary" (click)="launchGame()">OK</button>
    </div>
</div>
