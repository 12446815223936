<div class="bingo-widget" [style.width]="bingoWidth + 'px'" (window:resize)="onResize()" *ngIf="loadCarouselinsideWidget && !enableNewCozyScheduleUI">
    <div class="roomslist">
        <div *ngIf="isBingoWidget">
            <div class="rooms-tab" [innerHTML]="Global.messages.bingoroom"></div>
            <div class="see-all" [innerHTML]="Global.messages.seeAll" (click)="goToRooms()"></div>
        </div>
        <div class="bg-roomslist">
            <bg-roomslist-component></bg-roomslist-component>
        </div>
    </div>
</div>
<div
    class="bingo-widget-schedule"
    [style.width]="bingoWidth + 'px'"
    (window:resize)="onResize()"
    *ngIf="loadCarouselinsideWidget && enableNewCozyScheduleUI">
    <div class="roomslist" [ngClass]="isTouch ? 'roomslist-touch' : 'roomslist-nonTouch'">
        <div class="uk-header-style">
            <i class="theme-bingo-2"></i>
            <div class="bingo-schedule-header">
                <div class="rooms-tab bingo-heading" [innerHTML]="Global.messages.bingoroom"></div>
                <div class="see-all" (click)="goToRooms()">
                    <span [innerHTML]="Global.messages.seeAll"></span>
                    <span class="cozy-game-count">({{ gameListCount }})</span>
                    <span class="theme-right"></span>
                </div>
            </div>
            <div class="bingolobby-main scheduler-legend__list">
                <div class="bingorooms-data bingo-heading">
                    <span
                        ><b>{{ gameListCount + ' ' + Global.messages.BingoSchedulerRoomText1 }}</b
                        >{{ ' ' + Global.messages.BingoSchedulerRoomText2 }}</span
                    >
                </div>
            </div>
        </div>
        <div class="bg-roomslist">
            <bg-roomslist-component (update)="setgameCount($event)"></bg-roomslist-component>
        </div>
    </div>
</div>
