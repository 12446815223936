import { ModuleWithProviders, NgModule } from '@angular/core';

import { ToastMessageComponent } from './toast-message.component';

@NgModule({
    declarations: [ToastMessageComponent],
    exports: [ToastMessageComponent],
})
export class ToastMessageModule {
    public static forRoot(): ModuleWithProviders<ToastMessageModule> {
        return {
            ngModule: ToastMessageModule,
        };
    }
}
