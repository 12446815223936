import { NgModule } from '@angular/core';

import { ParallaxDirective } from './cwdj-parallax.directive';

@NgModule({
    declarations: [ParallaxDirective],
    imports: [],
    exports: [ParallaxDirective],
})
export class CwdjParallaxModule {}
