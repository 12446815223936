import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FacetsComponent } from './facets.component';

@NgModule({
    imports: [CommonModule],
    exports: [FacetsComponent],
    declarations: [FacetsComponent],
})
export class FacetsModule {}
