import { ElementRef, Injectable } from '@angular/core';

import { RootServicesModule } from '../shared/root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class NavService {
    constructor() {}

    navContainerScroll(navContainer: ElementRef, selectedItem: any): void {
        if (navContainer?.nativeElement && document.getElementsByTagName(selectedItem)) {
            const navElement: any = document.getElementById(selectedItem);
            if (navElement) {
                navContainer.nativeElement.scrollLeft = navElement.offsetLeft;
            }
        }
    }
    // scrollToDefault(navContainer: ElementRef) {
    //   let activeRoute: string = this.navigation.location.absUrl().substr(this.navigation.location.absUrl().lastIndexOf('/') + 1);
    //   activeRoute = activeRoute && activeRoute.indexOf('?') ? activeRoute.split('?')[0] : activeRoute;
    //   if (activeRoute === 'schedule' || activeRoute === 'home' || activeRoute === 'rooms' || activeRoute === 'bingo' || activeRoute === '' || activeRoute === 'en') {
    //     this.navContainerScroll(navContainer);
    //   }
    // }
}
