//import { VanillaCoreModule} from '@frontend/vanilla/core';
import { CommonModule } from '@angular/common';
// Angular Imports
import { ModuleWithProviders, NgModule } from '@angular/core';

import { NavigationModule } from './../navigation/navigation.module';
import { FavouriteRedirectComponent } from './favourite-redirect.component';
// This Module's Components
import { GameRedirectComponent } from './game-redirect.component';
import { PrebuyRedirectComponent } from './pre-buy-redirect.component';

@NgModule({
    imports: [
        CommonModule,
        //VanillaCoreModule,
        NavigationModule,
    ],
    declarations: [GameRedirectComponent, PrebuyRedirectComponent, FavouriteRedirectComponent],
    exports: [GameRedirectComponent, PrebuyRedirectComponent, FavouriteRedirectComponent],
})
export class ForceRedirectModule {
    public static forRoot(): ModuleWithProviders<ForceRedirectModule> {
        return {
            ngModule: ForceRedirectModule,
        };
    }
}
