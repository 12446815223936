import { Injectable } from '@angular/core';

import { BingoPlatformApiService } from '@frontend/bingo/core';
import { Message, MessageLifetime, MessageQueueService, MessageType, UserService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { RootServicesModule } from '../shared/root-services.module';
import { IPromotionsBanner } from './promotions-banner.model';

@Injectable({
    providedIn: RootServicesModule,
})
export class PromotionsBannerService {
    promotionsBannerData: IPromotionsBanner;
    private promotionsDataPublisher = new BehaviorSubject<any>(null);
    promotionsDataObservable: Observable<any> = this.promotionsDataPublisher.asObservable();

    constructor(
        private api: BingoPlatformApiService,
        private user: UserService,
        private messageQueue: MessageQueueService,
    ) {}

    fetchPromotionsBannerData() {
        if (this.user.isAuthenticated) {
            this.api.post('PromotionsCount').subscribe({
                next: (response: any) => {
                    if (response) {
                        this.promotionsBannerData = response;
                        this.promotionsDataPublisher.next(true);
                    }
                },
                error: (error: string) => {
                    const message: Message = {
                        html: error,
                        type: MessageType.Error,
                        lifetime: MessageLifetime.Single,
                        scope: 'casinocorelobby',
                    };
                    if (message) {
                        this.messageQueue.add(message);
                    }
                },
            });
        }
    }
    getPromotionsBannerData(): any {
        return this.promotionsBannerData;
    }
    addError = (errorMessage: string): void => {
        const message: Message = {
            html: errorMessage,
            type: MessageType.Error,
            lifetime: MessageLifetime.Single,
            scope: 'casinocorelobby',
        };
        if (message) {
            this.messageQueue.add(message);
            setTimeout(() => this.messageQueue.clear(), 1000);
        }
    };
}
