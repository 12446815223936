import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BingoConfigProviderService, ModularCasinoClientConfig } from '@frontend/bingo/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'bg-casino-category',
    templateUrl: 'casino-category.component.html',
})
export class CasinoCategoryComponent implements OnInit, OnDestroy {
    jsondata: any = {};
    routeSubscription: Subscription;
    modularCasinoConfig: ModularCasinoClientConfig;
    constructor(
        private activeroute: ActivatedRoute,
        private configProviderService: BingoConfigProviderService,
    ) {
        this.modularCasinoConfig = this.configProviderService.provideModularCasinoClientConfig();
    }
    ngOnInit() {
        this.routeSubscription = this.activeroute.params.subscribe((params: any) => {
            if (params && params.categoryId && this.modularCasinoConfig.navigationLobbyType) {
                this.jsondata = {
                    lobbyType: this.modularCasinoConfig.navigationLobbyType,
                    categoryRoute: params.categoryId,
                    seeAllProductLinkOverride: 'bingo',
                };
            }
        });
    }
    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }
}
