//import { VanillaCoreModule } from '@frontend/vanilla/core';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { CurrencyPipe } from '@frontend/vanilla/core';
import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import 'hammerjs';
import { CustomButtonComponent } from 'packages/bingo/frontend-lib/core/src/shared/components/designsystems/custombutton.component';
import { DecoratorClassDirective } from 'packages/bingo/frontend-lib/core/src/shared/directives/decorator-class.directive';

import { CasinoGamesLobbyModule } from '../casino-games-lobby/casino-games-lobby.module';
import { ParallaxAnimationModule } from '../parallax-animation/parallax-animation.module';
import { FavouriteModule } from '../shared/components/favourite/favourite.module';
import { FeatureIconModule } from '../shared/components/feature-icon/feature-icon.module';
import { SharedModule } from '../shared/shared.module';
import { CozygameItemComponent } from './rooms-item/game-item/game-item-cozy.component';
import { LockedRoomCardComponent } from './rooms-item/room-locked-card/room-locked-card.component';
import { RoomsItemComponent } from './rooms-item/rooms-item.component';
import { RoomsListComponent } from './rooms-list/rooms-list.component';
// import { LabelHostModule } from '@frontend/vanilla/core';
import { RoomsComponent } from './rooms.component';

@NgModule({
    imports: [
        //VanillaCoreModule,
        CommonModule,
        RouterModule,
        SwiperComponent,
        // LabelHostModule,
        FavouriteModule,
        FeatureIconModule,
        SharedModule,
        CasinoGamesLobbyModule,
        BrowserAnimationsModule,
        MessagePanelComponent,
        ParallaxAnimationModule,
        CurrencyPipe,
        CustomButtonComponent,
        DecoratorClassDirective,
    ],
    declarations: [RoomsComponent, RoomsListComponent, RoomsItemComponent, LockedRoomCardComponent, CozygameItemComponent],
    exports: [RoomsComponent, RoomsListComponent, RoomsItemComponent, LockedRoomCardComponent, CozygameItemComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RoomsModules {
    public static forRoot(): ModuleWithProviders<RoomsModules> {
        return {
            ngModule: RoomsModules,
        };
    }
}
