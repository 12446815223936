import { Component, Input } from '@angular/core';

import { EventEmitService } from '@frontend/bingo/core';

@Component({
    selector: 'bg-sub-navigation',
    templateUrl: 'sub-navigation.component.html',
    styleUrls: ['../../../../../themepark/themes/whitelabel/components/nav-main/styles.scss'],
})
export class SubNavigationComponent {
    isTouch: boolean;
    isMobileWidth: boolean;
    @Input() title: string;

    constructor(private eventService: EventEmitService) {}

    goBack() {
        //this.navigation.goTo('/bingo');
        this.eventService.raiseEvent({ navigateToUrl: '/bingo' });
    }
}
