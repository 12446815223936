// Angular Imports
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SubNavigationComponent } from './sub-navigation.component';

// This Module's Components

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [SubNavigationComponent],
    exports: [SubNavigationComponent],
})
export class SubNavigationModule {
    public static forRoot(): ModuleWithProviders<SubNavigationModule> {
        return {
            ngModule: SubNavigationModule,
            providers: [],
        };
    }
}
