import { Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import {
    BingoConfigProviderService,
    BingoWidgetService,
    ConfirmMessageService,
    FeaturesClientConfig,
    ScreenNameClientConfig,
} from '@frontend/bingo/core';
import { ClaimsService, NavigationService, ProductHomepagesConfig, UserService } from '@frontend/vanilla/core';

import { IPrebuyFilterModel } from '../../tickets/models/prebuy-filter.model';
import { TicketsComponent } from '../../tickets/tickets.component';
import { RootServicesModule } from '../root-services.module';
import { LoginService } from './login-Service';
import { ScreenNameService } from './screen-name.service';

@Injectable({
    providedIn: RootServicesModule,
})
export class PrebuyService {
    isRealPlayer: string;
    globalClientConfig: any;
    isInGameClient: boolean = false;
    _loadingIndicator: any;
    clientConfig: any = JSON.parse(localStorage.getItem('config')!);

    constructor(
        public dialogRef: MatDialogRef<TicketsComponent>,
        private loginService: LoginService,
        private navigator: NavigationService,
        private confirmMessageService: ConfirmMessageService,
        private claimsConfig: ClaimsService,
        private router: Router,
        private bingoWidget: BingoWidgetService,
        private lhProductHomepagesConfig: ProductHomepagesConfig,
        private user: UserService,
        private activeroute: ActivatedRoute,
        private screenNameService: ScreenNameService,
        private configProviderService: BingoConfigProviderService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: IPrebuyFilterModel,
    ) {
        this.isRealPlayer = this.claimsConfig.get('realplayer')!;
        if (this.activeroute.snapshot.queryParamMap.get('ingame=true')) {
            this.isInGameClient = true;
        }
    }

    checkUserStatus(): boolean {
        const featuresConfig: FeaturesClientConfig = this.configProviderService.provideFeaturesConfig();
        const bingoPage = this.clientConfig?.pageConfig?.value;
        if (featuresConfig.enableAccountUpgrade && this.claimsConfig.get('accBusinessPhase') == 'in-shop') {
            //this.navigator.goTo('http://qa2.www.coral.co.uk/en/mobileportal/initaccountupgrade?source=guard&skip=false');
            this.navigator.goTo(bingoPage?.accountUpgradeUrl.url);
        }
        if (this.isRealPlayer === undefined || this.isRealPlayer == null) {
            this.isRealPlayer = this.claimsConfig.get('realplayer')!;
        }
        if (this.isRealPlayer == 'True') return true;
        else {
            this.getConfigDataFromLocalstorage();
            const message = this.globalClientConfig['messages'].preBuyUserDepositMessage,
                header = this.globalClientConfig['messages'].preBuyUserDepositHeader,
                closeBtn = this.globalClientConfig['messages'].confirmCloseButton,
                purchaseButtonText = this.globalClientConfig['messages'].preBuyUserDepositBtn;
            this.confirmMessageService.confirmThis(
                header,
                message,
                '',
                purchaseButtonText,
                closeBtn,
                () => {
                    this.navigator.goTo(bingoPage?.depositUrl?.url);
                },
                () => {},
            );
        }

        return false;
    }

    getConfigDataFromLocalstorage() {
        if (!this.globalClientConfig && localStorage.getItem('config')) {
            const globalConfig = JSON.parse(localStorage.getItem('config') || '');
            this.globalClientConfig = globalConfig.global;
        }
    }

    prebuy(gamesData: any, roomId: any, tabIndex: number, lobbyUrl: string, preBuyDate?: string, prebuySlot?: string | null) {
        const featuresConfig: FeaturesClientConfig = this.configProviderService.provideFeaturesConfig();

        const data = gamesData;
        if (!lobbyUrl || lobbyUrl == '') {
            lobbyUrl = this.router.url;
            if (this.bingoWidget.isBingoWidget()) {
                lobbyUrl = '/en/bingo/rooms';
            }
        }
        const forceUrl = this.getPrebuyUrl(roomId, tabIndex, lobbyUrl, preBuyDate ? preBuyDate : '', prebuySlot ? prebuySlot : '');
        if (this.bingoWidget.isBingoWidget()) {
            return forceUrl;
        }
        if (featuresConfig.disableUserValidationCheck) {
            if (!this.loginService.validateUser(forceUrl)) {
                return;
            }
            //Vanilla 6.9.1 Screen name interceptor bug fix.
            if (featuresConfig.enableScreenNameForPrebuy) {
                if (featuresConfig.enableScreenNameCheck && !this.user.screenname) {
                    this.navigator.goTo(this.lhProductHomepagesConfig.portal + '/mobileportal/screenname', {
                        appendReferrer: forceUrl,
                    });
                    return false;
                }
            }

            if (this.isInGameClient === false) {
                this.validateScreenNameChat(forceUrl);
            }

            if (!this.checkUserStatus()) {
                if (this.router.url.indexOf('forcedprebuy') > -1) {
                    return this.navigator.goTo(lobbyUrl);
                }
                return;
            }
            this.openTicketsComponent(roomId, data, tabIndex, preBuyDate, prebuySlot);
            if (this.router.url.indexOf('forcedprebuy') > -1) {
                this.navigator.goTo(lobbyUrl);
            }
        }
        return null;
    }

    openTicketsComponent(roomId: any, data: any, tabIndex: number, preBuyDate?: string, prebuySlot?: string | null) {
        this.dialogRef = this.dialog.open(TicketsComponent, {
            data: {
                selected_room_id: +roomId,
                today_date: 1,
                dropdown_roomobj: data,
                tabSelectedIndex: tabIndex,
                selectedDate: preBuyDate,
                selectedGameSlot: prebuySlot,
            },
            panelClass: 'bingo-prebuy-matdailog',
        });
    }

    getPrebuyUrl(roomId: any, tabIndex: number, lobbyUrl?: string, preBuyDate?: string, prebuySlot?: string): string {
        const bingoPage = this.clientConfig?.pageConfig?.value;
        const result =
            bingoPage?.bingoHomePage?.url +
            '/bingo/forcedprebuy' +
            '?selected_room_id=' +
            roomId +
            '&tabSelectedIndex=' +
            tabIndex +
            '&lobbyUrl=' +
            lobbyUrl +
            '&date=' +
            preBuyDate +
            '&gameslot=' +
            prebuySlot;
        return result;
    }

    async validateScreenNameChat(forceUrl: string | null) {
        const screenNameConfig: ScreenNameClientConfig = this.configProviderService.provideScreenNameClientConfig();
        const data = {
            url: forceUrl,
            from: 'preBuyForced',
        };
        if (screenNameConfig.enableClientScreenName) {
            const screenName = await this.screenNameService.getChatName();
            if (screenName == '' || screenName == null) {
                this.screenNameService.openScreenChatNameComponent(data);
                return false;
            }
        }
        return null;
    }
}
