import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { CasinoPlatformLoaderModule } from '@casinocore/loader';

import { CasinoCategoryComponent } from './casino-category.component';

@NgModule({
    imports: [CommonModule, CasinoPlatformLoaderModule],
    declarations: [CasinoCategoryComponent],
    exports: [CasinoCategoryComponent],
})
export class CasinoCategoryModule {
    public static forRoot(): ModuleWithProviders<CasinoCategoryModule> {
        return {
            ngModule: CasinoCategoryModule,
        };
    }
}
