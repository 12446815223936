import { Injectable } from '@angular/core';

import { RootServicesModule } from '../../root-services.module';

@Injectable({
    providedIn: RootServicesModule,
})
export class ToastMessageService {
    eventId: any;
    isShown: boolean;
    iconClass: string;
    message: string;
    isIconShown: boolean;

    constructor() {}

    on(iconClass: string, message: string, isIconShown: boolean = false) {
        this.isShown = true;
        this.eventId = setTimeout(() => {
            this.off();
        }, 2000);
        this.iconClass = iconClass;
        this.message = message;
        this.isIconShown = isIconShown;
    }

    off() {
        clearTimeout(this.eventId);
        this.isShown = false;
    }
}
