import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'bg-locked-room-card',
    templateUrl: 'room-locked-card.component.html',
})
export class LockedRoomCardComponent implements OnInit {
    modelData: any;

    constructor(
        public dialogRef: MatDialogRef<LockedRoomCardComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}
    ngOnInit() {
        this.modelData = this.data;
    }

    close() {
        this.dialogRef.close();
    }

    continue() {
        this.dialogRef.close();
    }
}
