import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { BingoConfigProviderService, FeaturesClientConfig } from '@frontend/bingo/core';
import { NavigationService, TrackingService, UserService } from '@frontend/vanilla/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IPromotionsBanner } from './promotions-banner.model';
import { PromotionsBannerService } from './promotions-banner.service';

class PromotionsBannerStyles {
    iconType: string;
    containerBgColor: string;
    textColor: string;
    rightArrowColor: string;
}

@Component({
    selector: 'bg-promotions-banner',
    templateUrl: 'promotions-banner.component.html',
})
export class PromotionsBannerComponent implements OnInit, OnDestroy {
    @Input() categoryName: string;

    isLoading: boolean;
    promotionsBanner: IPromotionsBanner;
    isPromotionsBannerShow: boolean;
    styles: PromotionsBannerStyles;
    promoCount: string;
    private destroy$ = new Subject<void>();
    bingoFeature: FeaturesClientConfig;

    constructor(
        private user: UserService,
        private navigation: NavigationService,
        private trackingService: TrackingService,
        private promotionsBannerService: PromotionsBannerService,
        private configProviderService: BingoConfigProviderService,
    ) {
        this.styles = new PromotionsBannerStyles();
        this.bingoFeature = this.configProviderService.provideFeaturesConfig();
    }

    ngOnInit(): void {
        this.fetchPromotionsBannerData();
        //this.subscribeToUserLoginEvent();
    }

    onClick(event: Event): void {
        event.preventDefault();
        const url = this.promotionsBanner?.promotionsCTA?.url;
        if (url) {
            this.trackEvent();
            this.navigation.goTo(url);
        }
    }
    onCloseBanner(event: Event): void {
        event.stopPropagation();
        event.stopImmediatePropagation();
        sessionStorage.setItem('HidePromotionsBanner', 'true');
        this.isPromotionsBannerShow = false;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        sessionStorage.removeItem('HidePromotionsBanner');
    }

    private fetchPromotionsBannerData(): void {
        this.isLoading = true;
        if (this.user.isAuthenticated && this.bingoFeature.isEnablePromotionsBanner) {
            // this.promotionsBannerService.fetchPromotionsBannerData();
            this.promotionsBannerService.promotionsDataObservable.pipe(takeUntil(this.destroy$)).subscribe((isReady: boolean) => {
                if (isReady) {
                    const promotionsBannerRes: any = this.promotionsBannerService.getPromotionsBannerData();
                    if (promotionsBannerRes) {
                        this.assignData(promotionsBannerRes);
                    }
                }
            });
        }
    }

    private assignData(promotionsBanner: IPromotionsBanner): void {
        if (promotionsBanner && !this.promotionsBanner) {
            this.promotionsBanner = promotionsBanner;
            const config = this.bingoFeature;
            const count = config?.showPromotionsCount && promotionsBanner.promoCount > 0 ? ' (' + promotionsBanner.promoCount + ') ' : '';
            this.promoCount = (this.promotionsBanner?.promotionsCTA?.text ? this.promotionsBanner.promotionsCTA.text : '') + count ?? '';
            this.styles.iconType = promotionsBanner.styles?.IconType;
            this.styles.textColor = promotionsBanner.styles?.textColor;
            this.styles.containerBgColor = promotionsBanner.styles?.ContainerBgColor;
            this.styles.rightArrowColor = promotionsBanner.styles?.RightArrowColor;
            //this.isPromotionsBannerShow = promotionsBanner.promoCount > 0 ? true : false;
        }
    }

    private trackEvent(): void {
        this.trackingService.triggerEvent('Event.Tracking', {
            'component.CategoryEvent': 'promo hub',
            'component.LabelEvent': 'bingo',
            'component.ActionEvent': 'click',
            'component.PositionEvent': 'banner',
            'component.LocationEvent': this.categoryName,
            'component.EventDetails': 'bingo promotions',
            'component.URLClicked': this.promotionsBanner?.promotionsCTA?.url,
        });
    }
}
