import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FacetItems } from '../../../models/facetItems';

@Component({
    selector: 'bg-facets',
    templateUrl: './facets.component.html',
})
export class FacetsComponent implements OnInit {
    @Input() facetItems: FacetItems[];
    @Output()
    onFacetRemoved: EventEmitter<any> = new EventEmitter<any>();
    _ballText: string;
    Global: any;
    constructor(private activeRoute: ActivatedRoute) {
        //this.facetItems = [{name:'50 Ball',color:'#f7b334'},{name:'90 Ball',color:'#3e8ede'},{name:'20k All Stars long text xxxxxxx',color:'#f7b334'}]
    }
    ngOnInit() {
        this.Global = this.activeRoute.snapshot.data['config'].global;
        this._ballText = this.Global.messages['Ball'];
    }

    removeFilter(filterItem: any) {
        this.facetItems.splice(this.facetItems.indexOf(filterItem), 1);
        this.onFacetRemoved.emit(this.facetItems);
    }
}
