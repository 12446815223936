import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { interval } from 'rxjs';

@Component({
    selector: 'bg-jp-counter',
    templateUrl: 'jp-counter.component.html',
})
export class JpCounterComponent implements OnInit, OnDestroy {
    @Input() time: any;
    @Input() decimal: any;
    @Input() amt: any;
    timerSubscription: any;
    displayamt: any;

    constructor() {}
    ngOnInit() {
        this.amt = parseFloat(this.amt);
        this.time = 1;
        this.displayamt = this.formatcurrency(this.amt.toFixed(2));
        this.update();
    }
    formatcurrency(value: any) {
        const inputarr = value.toString().split('.');
        const realpart = inputarr[0];
        const decimalpart = inputarr[1];
        let amt = '';
        for (let i = realpart.length; i > 0; i = i - 3) {
            if (i - 3 > 0) {
                amt = ',' + realpart.substring(i - 3, i) + amt;
            } else {
                amt = realpart.substring(0, i) + amt;
            }
        }
        return amt + '.' + decimalpart;
    }
    update() {
        this.timerSubscription = interval(this.time * 1500).subscribe((response) => {
            if (response) {
                this.displayamt = this.formatcurrency(this.amt.toFixed(2));
                const randomnum = +Math.floor(Math.random() * 2 + 1);
                this.amt += 0.01 * randomnum; // to 2 decimal instead of tofixed which will return a string
            }
        });
    }

    ngOnDestroy() {
        if (this.timerSubscription) clearInterval(this.timerSubscription);
    }
}
