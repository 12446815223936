<div class="facets-container">
    <div class="facets-item-list">
        <div class="facet-item" *ngFor="let filterItem of facetItems">
            <div>
                <div class="facet-text" [ngClass]="filterItem.category_class">
                    {{ filterItem.Type == 'bingo_type' ? filterItem.category + ' ' + _ballText : filterItem.category }}
                </div>
                <div><i class="theme-ex facet-close" (click)="removeFilter(filterItem)"></i></div>
            </div>
        </div>
    </div>
</div>
