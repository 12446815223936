import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GameLaunchService } from '@frontend/bingo/core';
import { UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { filter } from 'rxjs/internal/operators/filter';

@Component({
    selector: 'bg-game-redirect',
    template: '',
})
export class GameRedirectComponent {
    gameName: string;
    gameType: string;
    ballType: string;
    launchType: string;
    gameIcon: string;
    gameId: string;
    favstatus: boolean;
    originUrl: string;
    specialproduct: string;
    themeid: string;

    constructor(
        private route: ActivatedRoute,
        private gameLaunchService: GameLaunchService,
        private user: UserService,
    ) {
        this.route.params.subscribe((params) => {
            this.gameName = params['gameName'];
        });
        this.route.queryParams.subscribe((queryParams) => {
            this.gameType = queryParams['gameType'];
            this.ballType = queryParams['ballType'];
            this.launchType = queryParams['launchType'];
            this.gameIcon = queryParams['gameIcon'];
            this.gameId = queryParams['gameId'];
            this.favstatus = queryParams['favstatus'];
            this.originUrl = queryParams['originUrl'];
            this.themeid = queryParams['themeid'];
        });
        this.specialproduct = this.route.snapshot.paramMap.get('specialproduct')!;
        this.user.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            if (localStorage.getItem('forcedGameLaunch')) {
                localStorage.removeItem('forcedGameLaunch');
                window.location.reload();
            }
        });

        this.gameLaunchService.initiateGameLaunch(
            this.gameName,
            this.gameType,
            this.gameType,
            this.ballType,
            this.launchType,
            this.gameIcon,
            this.gameId,
            this.favstatus,
            0,
            1,
            this.originUrl,
            this.specialproduct,
            this.themeid,
        );
    }
}
