import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BingoConfigProviderService, FeaturesClientConfig, GameLaunchService } from '@frontend/bingo/core';
import {
    CookieService,
    DeviceService,
    TrackingService,
    UserAutologoutEvent,
    UserLoginEvent,
    UserLogoutEvent,
    UserService,
} from '@frontend/vanilla/core';
import { filter } from 'rxjs/operators';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { BingoFavouriteService } from '../shared/components/favourite/bingofavourite.service';
import { GamesService } from './casinogames.service';
import { JackpotService } from './jackpot.service';

@Component({
    selector: 'bg-casino-games-lobby',
    templateUrl: 'casino-games-lobby.component.html',
    styleUrls: ['casino-games-lobby.component.css'],
})
export class CasinoGamesLobbyComponent implements OnInit {
    _loadingIndicator: any;
    isActive: boolean = false;
    //public carouselOne: NguCarouselConfig;
    icon: any;
    jackpart: any;
    jackpotData: any;
    iconToggle: boolean;
    categories: any = [];
    id: any;
    iconUrl: any;
    gameCategories: any;
    favoratesGames: any;
    recentlyGames: any;
    favoratesGamesIdList: any = [];
    recentlyPlayedGamesIdList: any = [];
    lobbyRecentlyGames: any = [];
    lobbyFavourateGames: any = [];
    isloggedin: boolean = true;
    iframedata: any;
    jackpotfeed: any;
    footerElement: any;
    recentlyplay: any;
    favourates: any;
    showCasinoPane: boolean;
    footerElmt = document.getElementsByTagName('footer')[0] as HTMLDivElement;
    isMobileView: boolean;
    isInGameClient: boolean = false;
    commonMessages: any;
    featuresclientconfig: FeaturesClientConfig;
    swiperOptionsCasinoGames: SwiperOptions = {
        modules: [Navigation, Pagination, Autoplay],
        loop: false,
        autoHeight: true,
        allowTouchMove: false,
        spaceBetween: 10,
        direction: 'horizontal',
        slidesPerView: 'auto',
        centeredSlides: false,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
            waitForTransition: false,
        },
        pagination: false,
        observer: true,
        rewind: true,
        slidesPerGroup: 1,
        keyboard: true,
        watchOverflow: true,
        mousewheel: false,
        scrollbar: false,
        preventClicks: false,
        preventClicksPropagation: false,
    };

    constructor(
        private gamesService: GamesService,
        private activeRoute: ActivatedRoute,
        private user: UserService,
        private jackpotService: JackpotService,
        private tracking: TrackingService,
        private gameLaunchService: GameLaunchService,
        private dss: DeviceService,
        private cookie: CookieService,
        private bingoFavouriteService: BingoFavouriteService,
        private configProviderService: BingoConfigProviderService,
    ) {
        this.featuresclientconfig = this.configProviderService.provideFeaturesConfig();
    }

    ngOnInit() {
        this.isMobileView = this.dss.isMobilePhone;
        if (localStorage.getItem('config')) {
            const clientConfig = JSON.parse(localStorage.getItem('config') || '');
            if (!this.commonMessages) this.commonMessages = clientConfig.global;
        }
        if (
            this.activeRoute.snapshot.queryParamMap.get('openpopup') == 'prebuy' ||
            this.activeRoute.snapshot.queryParamMap.get('openpopup') == 'mytickets'
        ) {
        } else if (this.activeRoute.snapshot.queryParamMap.get('hideHeader') == 'true') {
            this.isInGameClient = true;
        }

        this.user.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            this.gamesService.allCategories().subscribe((response: any) => {
                localStorage.setItem('CasinoLmtFeed', JSON.stringify(response));
                this.getCasinoPan();
            });
        });

        //TODO: store two keys in session, one for anonymous user one for logged in user, make service to handle the setting and getting
        //of data from session storage which would make the requests on the basis of authenticated/unauthenticated user

        this.user.events.pipe(filter((e) => e instanceof UserLogoutEvent)).subscribe(() => {
            this.gamesService.allCategories().subscribe((response: any) => {
                localStorage.setItem('CasinoLmtFeed', JSON.stringify(response));
                this.getCasinoPan();
            });
        });

        this.user.events.pipe(filter((e) => e instanceof UserAutologoutEvent)).subscribe(() => {
            this.gamesService.allCategories().subscribe((response: any) => {
                localStorage.setItem('CasinoLmtFeed', JSON.stringify(response));
                this.getCasinoPan();
            });
        });

        if (!this.isInGameClient && !this.isMobileView) {
            this.recentlyplay = this.commonMessages.messages.recentlyPlayed;
            this.favourates = this.commonMessages.messages.favourates;
            this.showCasinoPane = this.featuresclientconfig['showCasinoPane'];
            if (this.showCasinoPane === false) {
                if (this.footerElmt && this.footerElmt.style) this.footerElmt.style.marginBottom = '0';
            }
            this.getCasinoPan();
            this.isloggedin = true;
            // this.carouselOne = {
            //     grid: { xs: 2, sm: 6, md: 6, lg: 8, all: 0 },
            //     slide: 1,
            //     speed: 400,
            //     interval : {
            //         timing: 4000
            //     },
            //     point: {
            //         visible: false
            //     },
            //     load: 2,
            //     touch: true,
            //     loop: true
            // };
            this.jackpotsFeed();
        }
    }

    getCasinoPan() {
        this.user.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            this.getRecentlyCategoryGames();
            this.getFavorateCategoryGames();
        });
        const casinoFeedFromSession = JSON.parse(localStorage.getItem('CasinoLmtFeed') || '');
        if (casinoFeedFromSession && casinoFeedFromSession.categoryDataList && casinoFeedFromSession.categoryDataList.length > 0) {
            this.categories = casinoFeedFromSession;
            if (this.user.isAuthenticated) {
                this.getRecentlyCategoryGames();
                this.getFavorateCategoryGames();
            }
            this.iconUrl = casinoFeedFromSession.gameIconTemplateUrl;
        } else {
            this.gamesService.allCategories().subscribe((response: any) => {
                localStorage.setItem('CasinoLmtFeed', JSON.stringify(response));
                this.categories = response;
                if (this.user.isAuthenticated) {
                    this.getRecentlyCategoryGames();
                    this.getFavorateCategoryGames();
                }
                this.iconUrl = response.gameIconTemplateUrl;
            });
        }
    }

    checkFavoriteGameCookie() {
        const roomName = this.cookie.getObject('favouriteRoom');
        if (roomName) {
            this.bingoFavouriteService.addFavourite(roomName.toString());
            this.cookie.remove('favouriteRoom');
        }
    }

    getRecentlyCategoryGames() {
        this.gamesService.recentlyplayedgames().subscribe((response: any) => {
            this.recentlyGames = response;
            if (this.recentlyGames === undefined || this.categories.categoryDataList === undefined) return true;
            //if (this.recentlyGames.length > 0) {
            for (let i = 0; i < this.recentlyGames.length; i++) {
                this.recentlyPlayedGamesIdList.push({
                    gameId: this.recentlyGames[i],
                    isSelected: false,
                });
            }
            for (let i = 0; i <= this.recentlyGames.length; i++) {
                for (let j = 0; j < this.categories.categoryDataList.length; j++) {
                    for (let lg = 0; lg < this.categories.categoryDataList[j].categoryGameList.length; lg++) {
                        if (
                            this.categories.categoryDataList[j].categoryGameList[lg].gameId === this.recentlyGames[i] &&
                            this.recentlyPlayedGamesIdList[i].isSelected == false
                        ) {
                            this.recentlyPlayedGamesIdList[i].isSelected = true;
                            this.lobbyRecentlyGames.push(this.categories.categoryDataList[j].categoryGameList[lg]);
                        }
                    }
                }
            }
            if (this.lobbyRecentlyGames.length > 0) {
                this.categories.categoryDataList.push({
                    categoryTitle: this.recentlyplay,
                    categoryId: 'RecentlyPlayed-id',
                    categoryGameList: this.lobbyRecentlyGames,
                });
            }
            //}
            return false;
        });
    }

    jackpotsFeed() {
        this.jackpotService.jackpotFeedObservable.subscribe((jackpots) => {
            // this.categories;
            if (jackpots && this.categories && this.categories.categoryDataList && this.categories.categoryDataList.length) {
                for (let catId = 0; catId < this.categories.categoryDataList.length; catId++) {
                    const category = this.categories.categoryDataList[catId];
                    if (category && category.categoryGameList && category.categoryGameList.length > 0) {
                        for (let gameIndex = 0; gameIndex < category.categoryGameList.length; gameIndex++) {
                            const game = category.categoryGameList[gameIndex];
                            if (game && game.gameId && jackpots.get(game.gameId)) {
                                game.jackpotValue = jackpots.get(game.gameId);
                            }
                        }
                    }
                }
            }
        });
        this.jackpotService.startFeed();
    }

    getFavorateCategoryGames() {
        this.checkFavoriteGameCookie();

        this.gamesService.favourites().subscribe((response: any) => {
            this.favoratesGames = response;
            if (
                this.favoratesGames &&
                this.favoratesGames.name &&
                this.favoratesGames.name.favourites &&
                this.favoratesGames.name.favourites.length > 0 &&
                this.categories.categoryDataList
            ) {
                for (let i = 0; i < this.favoratesGames.name.favourites.length; i++) {
                    this.favoratesGamesIdList.push({
                        gameId: this.favoratesGames.name.favourites[i],
                        isSelected: false,
                    });
                }
                for (let i = 0; i <= this.favoratesGames.name.favourites.length; i++) {
                    for (let j = 0; j < this.categories.categoryDataList.length; j++) {
                        for (let lg = 0; lg < this.categories.categoryDataList[j].categoryGameList.length; lg++) {
                            if (
                                this.categories.categoryDataList[j].categoryGameList[lg].gameId === this.favoratesGames.name.favourites[i] &&
                                this.favoratesGamesIdList[i].isSelected == false
                            ) {
                                this.favoratesGamesIdList[i].isSelected = true;
                                this.lobbyFavourateGames.push(this.categories.categoryDataList[j].categoryGameList[lg]);
                            }
                        }
                    }
                }
                if (this.lobbyFavourateGames.length > 0) {
                    this.categories.categoryDataList.push({
                        categoryTitle: this.favourates,
                        categoryId: 'Favourites-id',
                        categoryGameList: this.lobbyFavourateGames,
                    });
                }
            }
        });
    }

    onResize() {
        const tabsElementHeight = document.getElementsByClassName('fixed-footer')[0] as HTMLDivElement;
        const footerElement = document.getElementsByTagName('footer')[0] as HTMLDivElement;
        this.isActive = !this.isActive;
        if (!this.isActive) {
            if (document.querySelector('.fixed-footer')) {
                document.querySelector('.fixed-footer')!.classList.add('casino-lobby');
            }
            this.tracking.triggerEvent('Event_Product_Bingo_CasinoPane', { 'page.referringAction': 'Bingo_CasinoPane_Action_Hide_Clicked' });
            tabsElementHeight.style.height = 62.1 + 'px';
            footerElement.style.marginBottom = '5%';
        } else {
            if (document.querySelector('.fixed-footer')) {
                document.querySelector('.fixed-footer')!.classList.remove('casino-lobby');
            }
            this.tracking.triggerEvent('Event_Product_Bingo_CasinoPane', { 'page.referringAction': 'Bingo_CasinoPane_Action_Show_Clicked' });
            if (window.innerWidth <= 1023 && window.innerWidth >= 768) {
                tabsElementHeight.style.height = 184.4 + 'px';
                footerElement.style.marginBottom = '22%';
            } else if (window.innerWidth <= 1365 && window.innerWidth >= 1024) {
                tabsElementHeight.style.height = 238.1 + 'px';
                footerElement.style.marginBottom = '22%';
            } else {
                tabsElementHeight.style.height = 238.1 + 'px';
                footerElement.style.marginBottom = '17%';
                //window.scrollTo(0,document.body.scrollHeight);
            }
        }
    }

    PaneCategoryChange() {
        const tabsElementHeight = document.getElementsByClassName('fixed-footer')[0] as HTMLDivElement;
        this.isActive = true;
        if (document.querySelector('.fixed-footer')) {
            document.querySelector('.fixed-footer')!.classList.remove('casino-lobby');
        }
        if (window.innerWidth <= 1023 && window.innerWidth >= 768) {
            tabsElementHeight.style.height = 184.4 + 'px';
        } else {
            tabsElementHeight.style.height = 238.1 + 'px';
        }
    }

    getGame(gameData: any, index: any) {
        this.triggerTracking(gameData, index);
        this.gameLaunchService.initiateGameLaunch(
            gameData.gameName,
            gameData.provider,
            'Casino',
            '',
            'REGULAR',
            this.iconUrl.replace('{0}', gameData.iconStyle).replace('{1}', gameData.gameId) + '.jpg',
            gameData.gameId,
            false,
            0,
            1,
            '',
            '',
            gameData.themeid,
        );
    }

    triggerTracking(data: any, index: any) {
        if (this.user.isAuthenticated) {
            const eventData = {
                'page.referringAction': 'Bingo_CasinoPane_Action_Game_Launched',
                'game.id': data.gameId,
                'game.index': index,
            };
            this.tracking.triggerEvent('Event_Product_Bingo_CasinoPane', eventData);
        } else {
            const eventData = {
                'page.referringAction': 'Bingo_CasinoPane_Action_PreLogin_Game_Launch',
                'game.id': data.gameId,
                'game.index': index,
            };
            this.tracking.triggerEvent('Event_Product_Bingo_CasinoPane', eventData);
        }
    }
}
