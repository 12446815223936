import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CasinoPlatformLoaderService } from '@casinocore/loader';
import {
    BingoConfigProviderService,
    BingoPlatformApiService,
    BingoWidgetService,
    EventEmitService,
    FeaturesClientConfig,
} from '@frontend/bingo/core';
import { ProductHomepagesConfig, UserService } from '@frontend/vanilla/core';

import { RootServicesModule } from '../../root-services.module';
import { LoginService } from '../../services/login-Service';
import { ToastMessageService } from '../toast-message/toast-message.service';

//import { OtherProductService } from '@casinocore/platform';

@Injectable({
    providedIn: RootServicesModule,
})
export class BingoFavouriteService {
    toasterRequired: any;
    _favouriteAddedMessage: string;
    _favouriteRemovedMessage: string;
    _favouriteAddedErrorMessage: string;
    _favouriteRemovedErrorMessage: string;
    lobbyUrl: string;
    Global: any;

    constructor(
        private api: BingoPlatformApiService,
        private toastMessageService: ToastMessageService,
        private eventEmitService: EventEmitService,
        private loginService: LoginService,
        private router: Router,
        private bingoWidget: BingoWidgetService,
        private user: UserService,
        private lhProductHomepagesConfig: ProductHomepagesConfig,
        private configProviderService: BingoConfigProviderService,
        //private otherProductService: OtherProductService,
        private casinoPlatformLoaderService: CasinoPlatformLoaderService,
    ) {}

    addFavourite(roomId: string): Promise<any> {
        const body: any = {
            setFavouriteRoom: {
                roomId: roomId,
                isFavourite: true,
            },
        };
        return this.api.put('SetFavourite', body, { withCredentials: true, showSpinner: false }).toPromise().then();
    }

    removeFavourite(roomId: string): Promise<any> {
        const body: any = {
            setFavouriteRoom: {
                roomId: roomId,
                isFavourite: false,
            },
        };
        return this.api.put('SetFavourite', body, { withCredentials: true, showSpinner: false }).toPromise().then();
    }

    toggleFavourite(roomId: string, isFavourite: boolean, favAddClass: string, favRemClass: string) {
        this.getConfigDataFromLocalstorage();
        const forceUrl = this.getFavouriteUrl(roomId, isFavourite, favAddClass, favRemClass);
        if (this.bingoWidget.isBingoWidget()) {
            if (!this.user.isAuthenticated) {
                this.bingoWidget.postMessage('UserAction', { promptLogin: true, redirectUrl: forceUrl, loginMessageKey: 'Favourite' });
                return;
            }
        } else {
            if (!this.loginService.validateUser(forceUrl)) {
                return;
            }
        }

        if (isFavourite) {
            this.removeFavourite(roomId).then(
                (response: any) => {
                    if (response && this.toasterRequired) {
                        this.toastMessageService.on('theme-heart-broken', this._favouriteRemovedMessage, true);
                    }
                    isFavourite = !isFavourite;
                    if (this.bingoWidget.isBingoWidget()) this.bingoWidget.postMessage('favoriteUpdated', { roomid: roomId });
                    this.eventEmitService.raiseEvent({ favoriteUpdated: roomId });
                    //this.otherProductService.updateFavGamesForOtherProducts();
                    this.casinoPlatformLoaderService.updateFavGamesForOtherProducts();
                },
                (error) => {
                    if (error && this.toasterRequired) {
                        this.toastMessageService.on('theme-heart-broken', this._favouriteRemovedErrorMessage, true);
                    }
                    //this.emitParentView();
                },
            );
        } else {
            this.addFavourite(roomId).then(
                (response: any) => {
                    if (response && this.toasterRequired) {
                        this.toastMessageService.on('theme-bingo-heart', this._favouriteAddedMessage, true);
                    }
                    isFavourite = !isFavourite;
                    if (this.bingoWidget.isBingoWidget()) this.bingoWidget.postMessage('favoriteUpdated', { roomid: roomId });
                    this.eventEmitService.raiseEvent({ favoriteUpdated: roomId });
                    // this.otherProductService.updateFavGamesForOtherProducts();
                    this.casinoPlatformLoaderService.updateFavGamesForOtherProducts();
                },
                (error) => {
                    if (error && this.toasterRequired) {
                        this.toastMessageService.on('theme-bingo-heart', this._favouriteAddedErrorMessage, true);
                    }
                },
            );
        }
    }

    getFavouriteUrl(roomId: string, isFavourite: boolean, favAddClass: string, favRemClass: string): string {
        const clientConfig = JSON.parse(localStorage.getItem('config')!);
        const bingoPage = clientConfig?.pageConfig?.value;
        if (this.bingoWidget.isBingoWidget()) {
            this.lobbyUrl = this.lhProductHomepagesConfig.casino;
        } else {
            this.lobbyUrl = this.router.url;
        }
        const result =
            bingoPage.bingoHomePage.url +
            '/bingo/forcedfavourite' +
            '?roomId=' +
            roomId +
            '&isFavourite=' +
            isFavourite +
            '&favAddClass=' +
            favAddClass +
            '&favRemClass=' +
            favRemClass +
            '&lobbyUrl=' +
            this.lobbyUrl;
        return result;
    }

    getConfigDataFromLocalstorage() {
        const featuresclientconfig: FeaturesClientConfig = this.configProviderService.provideFeaturesConfig();
        if (!this.Global && localStorage.getItem('config')) {
            const globalConfig = JSON.parse(localStorage.getItem('config')!);
            this.Global = globalConfig.global;
            this.toasterRequired = featuresclientconfig['favouritesConfirmationPopUp'];
            this._favouriteAddedMessage = this.Global.messages['favouriteAddedMessage'];
            this._favouriteRemovedMessage = this.Global.messages['favouriteRemovedMessage'];
            this._favouriteAddedErrorMessage = this.Global.messages['errorOccuredWhileAddingToFavMessage'];
            this._favouriteRemovedErrorMessage = this.Global.messages['errorOccuredWhileRemovingFromFavMessage'];
        }
    }
}
