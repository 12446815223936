<!-- <vn-message-panel id="home-message-panel">
</vn-message-panel> -->

<!-- <bg-navigation *ngIf="initData.navigationItems.length > 0" [navigationItems]="initData.navigationItems"></bg-navigation> -->
<!-- <router-outlet></router-outlet>-->

<div class="lobby-min-height" [hidden]="!showLoading">
    <!-- <div class="padding-skeleton">
    </div> -->
    <div>
        <div class="bg-home-skeleton sk-bingo-container">
            <div class="sk-banner"></div>
            <div class="sk-title"></div>
            <div class="sk-cards-wrapper">
                <div *ngFor="let number of [0, 1, 2, 3, 4, 5]" class="sk-card">
                    <div class="sk-card-image"></div>
                    <div class="sk-info-wrap">
                        <div class="sk-title-section">
                            <div class="sk-circle"></div>
                            <div class="sk-title"></div>
                        </div>
                        <div class="sk-card-details"></div>
                    </div>
                </div>
            </div>
            <div class="sk-title"></div>
            <div class="sk-games">
                <div *ngFor="let number of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]" class="sk-game-card"></div>
            </div>
        </div>
    </div>
</div>
<div [hidden]="showLoading">
    <bg-teasers [categoryName]="categoryText" *ngIf="enableHomeTeasers && !enableTeasersInRooms && !enableBingoNPM"></bg-teasers>
    <bg-teasers-npm
        [categoryName]="categoryText"
        [embeddedPosition]="'Mainteasers'"
        *ngIf="enableHomeTeasers && !enableTeasersInRooms && enableBingoNPM"></bg-teasers-npm>
    <div [ngClass]="{ 'bingo-news-feed-widget': isTouch }" *ngIf="isNewsFeedNpmEnabled">
        <ng-container [bgCasinoModuleLoad]="'NewsFeedV2WidgetComponent'" [data]="newFeedWidgetData"></ng-container>
    </div>

    <div class="slot-races-npm">
        <ng-container [bgCasinoModuleLoad]="'SlotRacesWidgetComponent'"></ng-container>
    </div>
    <div class="bingo-tournaments-home" *ngIf="displayTournamentsOnTop && isBingoTournamentsEnbaled && enableBingoTournamentsWidget">
        <div class="tournament_category_name">{{ messages?.BingoTournamentsCategoryName }}</div>
        <bgl-slot-races-widget [fromBingoHomepage]="true"></bgl-slot-races-widget>
    </div>

    <div class="roomslist-error">
        <vn-message-panel></vn-message-panel>
    </div>
    <div
        [ngClass]="{
            'bingo-backgroungImage-layer': parallaxInput && parallaxInput.backgroundLayer1,
            'bingo-backgroungImage-parallax': (parallaxInput && parallaxInput.backgroundLayer2) || (parallaxInput && parallaxInput.backgroundLayer3)
        }">
        <bg-parallax-animation
            *ngIf="parallaxInput && (parallaxInput.backgroundLayer1 || parallaxInput.backgroundLayer2 || parallaxInput.backgroundLayer3)"
            [parallaxData]="parallaxInput">
        </bg-parallax-animation>
        <div [ngClass]="{ roomslist: !showVerticalRoomsInHomePage }">
            <ng-container *ngIf="!showVerticalRoomsInHomePage">
                <h1 class="rooms-tab" [innerHTML]="Global?.messages?.bingoroom"></h1>
                <div class="see-all" [innerHTML]="Global?.messages?.seeAll" *ngIf="displaySeeAllOnLobby" (click)="gotToRooms()"></div>
            </ng-container>
            <div [ngClass]="showVerticalRoomsInHomePage ? 'room-component' : 'bg-roomslist'">
                <bg-roomslist-component [showVerticalRoomsInHomePage]="showVerticalRoomsInHomePage"></bg-roomslist-component>
            </div>
        </div>
    </div>
    <div *ngIf="!(modularCasinoEnabled && enableCasinoNpmGrid)">
        <div class="casino-games-div" *ngIf="!enableCasinoWidget">
            <div class="casino-div" *ngIf="this.configuredCasinoCategoryIds">
                <div class="casino-games" *ngFor="let category of orderedCategoriesGameData; let i = index">
                    <h2 class="casino-game-panel-header">{{ category.categoryTitle }}</h2>
                    <div class="casino-games-container">
                        <!-- <ngu-carousel #casinoGameCarousel [inputs]="carouselConfig"
                            [dataSource]="category.categoryGameList" *ngIf="!isTouch">
                            <ngu-item *nguCarouselDef="let game ;let i=index" class="casino-game">
                                <div class="casino-game-icon" (click)="launchGameFromHomePage(game)">
                                    <span class="casino-jackpotValue">{{game.jackpotValue}}</span>
                                    <img [lazyLoad]="game.imageUrl">
                                </div>
                            </ngu-item>
                            <button NguCarouselPrev class="theme-left home-button-left"
                                [ngClass]="{'enable-btn':orderedCategoriesGameData[i]?.categoryGameList?.length < 5}"
                                [hidden]='casinoGameCarousel.isFirst'></button>
                            <button NguCarouselNext class="theme-right home-button-right"
                                [ngClass]="{'enable-btn':orderedCategoriesGameData[i]?.categoryGameList?.length < 5}"
                                [hidden]='casinoGameCarousel.isLast'></button>
                        </ngu-carousel> -->
                        <vn-swiper #casinoGameCarousel *ngIf="!isTouch" [swiperOptions]="swiperOptionsHome">
                            <div class="casino-game" *ngFor="let data of category.categoryGameList">
                                <div class="casino-game-icon" (click)="launchGameFromHomePage(data)">
                                    <span class="casino-jackpotValue">{{ data.jackpotValue }}</span>
                                    <img [lazyLoad]="data.imageUrl" alt="Game Url" />
                                </div>
                            </div>
                            <!-- <button NguCarouselPrev class="theme-left home-button-left" [ngClass]="{'enable-btn':orderedCategoriesGameData[i]?.categoryGameList?.length < 5}"
                                [hidden]='casinoGameCarousel.isFirst'></button>
                            <button NguCarouselNext class="theme-right home-button-right" [ngClass]="{'enable-btn':orderedCategoriesGameData[i]?.categoryGameList?.length < 5}"
                                [hidden]='casinoGameCarousel.isLast'></button> -->
                        </vn-swiper>
                        <div class="touch-game-icons" *ngIf="isTouch">
                            <div class="game-icons" [ngClass]="{ scrollable: isTouch }">
                                <div *ngFor="let game of category.categoryGameList; let i = index" class="casino-game">
                                    <div class="casino-game-icon" (click)="launchGameFromHomePage(game)">
                                        <span class="casino-jackpotValue">{{ game.jackpotValue }}</span>
                                        <img [lazyLoad]="game.imageUrl" alt="Game Url" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="casino-games-list sidepanel-games" *ngIf="recentlyPlayedGamesExist">
                    <div class="casino-game-panel-header">{{ rpgPanelTitle }}</div>
                    <div class="casino-games-container">
                        <!-- <ngu-carousel #recentlyPlayedCarousel [inputs]="carouselConfig"
                            [dataSource]="recentlyPlayedGames" *ngIf="!isTouch">
                            <ngu-item *nguCarouselDef="let game " class="casino-game embed-game">
                                <div class="casino-game-icon" (click)="launchGameFromHomePage(game)">
                                    <span class="casino-jackpotValue">{{game.jackpotValue}}</span>
                                    <img [lazyLoad]="game.imageUrl">
                                </div>
                            </ngu-item>
                            <button NguCarouselPrev class="theme-left home-button-left"
                                [ngClass]="{'enable-btn': recentlyPlayedGames && recentlyPlayedGames.length < 8}"
                                [hidden]='recentlyPlayedCarousel.isFirst'></button>
                            <button NguCarouselNext class="theme-right home-button-right"
                                [ngClass]="{'enable-btn': recentlyPlayedGames && recentlyPlayedGames.length < 8}"
                                [hidden]='recentlyPlayedCarousel.isLast'></button>
                        </ngu-carousel> -->
                        <vn-swiper #recentlyPlayedCarousel *ngIf="!isTouch" [swiperOptions]="swiperOptionsHome">
                            <div class="casino-game embed-game" *ngFor="let game of recentlyPlayedGames">
                                <div class="casino-game-icon" (click)="launchGameFromHomePage(game)">
                                    <span class="casino-jackpotValue">{{ game.jackpotValue }}</span>
                                    <img [lazyLoad]="game.imageUrl" />
                                </div>
                            </div>
                            <!-- <button NguCarouselPrev class="theme-left home-button-left" [ngClass]="{'enable-btn': recentlyPlayedGames && recentlyPlayedGames.length < 8}"
                                [hidden]='recentlyPlayedCarousel.isFirst'></button>
                            <button NguCarouselNext class="theme-right home-button-right" [ngClass]="{'enable-btn': recentlyPlayedGames && recentlyPlayedGames.length < 8}"
                                [hidden]='recentlyPlayedCarousel.isLast'></button> -->
                        </vn-swiper>
                        <div class="touch-game-icons" *ngIf="isTouch">
                            <div class="game-icons" [ngClass]="{ scrollable: isTouch }">
                                <div *ngFor="let game of recentlyPlayedGames" class="casino-game embed-game">
                                    <div class="casino-game-icon" (click)="launchGameFromHomePage(game)">
                                        <span class="casino-jackpotValue">{{ game.jackpotValue }}</span>
                                        <img [lazyLoad]="game.imageUrl" alt="Game Url" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bingo-tournaments-home" *ngIf="!displayTournamentsOnTop && isBingoTournamentsEnbaled && enableBingoTournamentsWidget">
        <div class="tournament_category_name">{{ messages?.BingoTournamentsCategoryName }}</div>
        <bgl-slot-races-widget [fromBingoHomepage]="true"></bgl-slot-races-widget>
    </div>
    <div *ngIf="modularCasinoEnabled && enableCasinoNpmGrid" class="casino-grid">
        <ng-container [bgCasinoModuleLoad]="'CasinoGridOnLobbyTypeComponent'" [data]="jsondata"></ng-container>
    </div>
    <div *ngIf="enableCasinoWidget" class="CasinoWidgetContainer">
        <iframe
            [src]="casinolobbyWidgetUrl | trustAsResourceUrl"
            frameborder="0"
            width="100%"
            scrolling="no"
            id="CasinoLobbyWidget"
            alt="Game Url"
            class="casinoWidgetIframe">
        </iframe>
    </div>
    <div class="lobby-min-height" [hidden]="!showWidgetLoading" *ngIf="enableCasinoWidget">
        <!-- <div class="padding-skeleton">
        </div> -->
        <div>
            <div class="bg-roomslist-skeleton sk-bingo-container">
                <div class="sk-cards-wrapper">
                    <div *ngFor="let number of [0, 1, 2, 3, 4, 5, 6, 7]" class="sk-card">
                        <div class="sk-card-image"></div>
                        <div class="sk-info-wrap">
                            <div class="sk-title-section">
                                <div class="sk-circle"></div>
                                <div class="sk-title"></div>
                            </div>
                            <div class="sk-card-details"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
