import { Injectable } from '@angular/core';

//import { CasinoPlatformLoaderService } from '@casinocore/loader';

@Injectable({ providedIn: 'root' })
export class GLobalSearchService {
    constructor() {} // private casinoPlatformLoaderService: CasinoPlatformLoaderService

    init(): void {
        addEventListener('casino:Recent_Games_Updated', this.handleRecentlyPlayedGames, false);
        // addEventListener('gs:search:Request_Recent_Games_From_Casino', this.getAndDispatchRecentlyPlayed, false);
    }

    // private getAndDispatchRecentlyPlayed = () => {
    //     const recentGames = this.casinoPlatformLoaderService.updateRecentlyplayedGamesOnOtherProducts();
    //      recentGames && Object.keys(recentGames).length > 0 && this.dispatchRecentGames(recentGames);

    //     const cozyBingoRecentGames: any = this.casinoPlatformLoaderService.updateRecentlyplayedGamesOnOtherProducts();
    //     if (cozyBingoRecentGames && cozyBingoRecentGames.result && cozyBingoRecentGames.result.rooms && cozyBingoRecentGames.result.rooms.length > 0) {
    //         let recentgames = {};
    //         recentgames['cozybingo'] = cozyBingoRecentGames.result.rooms;
    //         this.dispatchRecentGames(recentgames);
    //     }

    // }

    private dispatchRecentGames = (recentGames: any) => {
        dispatchEvent(new CustomEvent('gs:search:Casino_Recent_Games_Updated', { detail: recentGames }));
    };

    private handleRecentlyPlayedGames = (event: CustomEvent) => {
        if (event && event.detail) {
            this.dispatchRecentGames(event.detail);
        }
    };
}
