import { Routes } from '@angular/router';

import { VANILLA_LAZY_ROUTES } from '@frontend/loaders/routes';
import { ClientConfigProductName, routeData } from '@frontend/vanilla/core';

import { BingoWidgetComponent } from './BingoWidget/bingo-widget.component';
import { CasinoCategoryComponent } from './casino-category/casino-category.component';
import { FavouriteRedirectComponent } from './force-redirect/favourite-redirect.component';
import { GameRedirectComponent } from './force-redirect/game-redirect.component';
import { PrebuyRedirectComponent } from './force-redirect/pre-buy-redirect.component';
import { GameIframeLauncherComponent } from './game-Iframe-launcher/game-Iframe-launcher.component';
import { HomeComponent } from './home/home.component';
import { MainComponent } from './main/main.component';
import { ConfigResolver } from './resolver/config-resolver.component';
import { RoomsListComponent } from './rooms/rooms-list/rooms-list.component';
import { RoomsComponent } from './rooms/rooms.component';
import { AuthGuardService as AuthGuard } from './shared/services/auth-guard.service';
import { BingoTournamentsGuardService as TournamentsGuard } from './shared/services/bingotournaments-guard.service';
import { PrebuyGuardService as PrebuyGuard } from './shared/services/prebuy-guard.service';
import { TicketsComponent } from './tickets/tickets.component';

export const appRoutes: Routes = [
    {
        path: '',
        component: MainComponent,
        resolve: {
            config: ConfigResolver,
        },
        children: [
            {
                path: '',
                component: HomeComponent,
                data: { Home: 'true' },
                resolve: {
                    config: ConfigResolver,
                },
            },
            {
                path: 'games-info/:room-name',
                component: RoomsListComponent,
                resolve: {
                    config: ConfigResolver,
                },
            },

            {
                path: 'forcedprebuy',
                component: PrebuyRedirectComponent,
                resolve: {
                    config: ConfigResolver,
                },
            },
        ],
        data: routeData({ allowAnonymous: true }),
    },
    {
        path: '{culture}',
        children: [
            ...VANILLA_LAZY_ROUTES,
            {
                path: 'p',
                loadChildren: () => import('@frontend/vanilla/features/public-page').then((m) => m.ROUTES),
                data: {
                    publicPageRoot: 'MobilePortal-v1.0/PublicPages/',
                    product: ClientConfigProductName.BINGO,
                },
            },
            {
                path: '',
                component: MainComponent,
                resolve: {
                    config: ConfigResolver,
                },
                children: [
                    {
                        path: '',
                        component: HomeComponent,
                        data: { Home: 'true' },
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'games-info/:room-name',
                        component: RoomsListComponent,
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'forcedprebuy',
                        component: PrebuyRedirectComponent,
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                ],
                data: routeData({ allowAnonymous: true }),
            },
            {
                path: 'bingo',
                component: MainComponent,
                resolve: {
                    config: ConfigResolver,
                },
                data: routeData({ allowAnonymous: true }),
                children: [
                    {
                        path: '',
                        component: HomeComponent,
                        resolve: {
                            config: ConfigResolver,
                        },
                        data: { Home: 'true' },
                        children: [
                            {
                                path: 'games-info/:room-name',
                                component: RoomsListComponent,
                                resolve: {
                                    config: ConfigResolver,
                                },
                            },
                        ],
                    },
                    {
                        path: 'prebuy',
                        component: TicketsComponent,
                        resolve: {
                            config: ConfigResolver,
                        },
                        // data: routeData({ allowAnonymous: true }),
                        //canActivate: [GameclientPrebuyGuard]
                    },
                    {
                        path: 'forcedgamelaunch/:gameName',
                        component: GameRedirectComponent,
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'forcedprebuy',
                        component: PrebuyRedirectComponent,
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'forcedfavourite',
                        component: FavouriteRedirectComponent,
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'menu/launchroom/:roomid',
                        component: RoomsListComponent,
                        data: {
                            roomId: 'all',
                        },
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'menu/mytickets',
                        component: RoomsListComponent,
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'menu/mytickets/games-info/:roomid',
                        component: RoomsListComponent,
                        data: {
                            roomId: 'all',
                        },
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'menu/prebuy',
                        component: RoomsListComponent,
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'schedule/:ballType/games-info/:room-name',
                        component: RoomsListComponent,
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'schedule/games-info/:room-name',
                        component: RoomsListComponent,
                        data: {
                            roomId: 'schedule',
                        },
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'schedule/games-info/:room-name',
                        component: RoomsListComponent,
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'schedule/90-ball',
                        component: RoomsListComponent,
                        data: {
                            roomId: '90-ball',
                        },
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'schedule/80-ball',
                        component: RoomsListComponent,
                        data: {
                            roomId: '80-ball',
                        },
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'schedule/75-ball',
                        component: RoomsListComponent,
                        data: {
                            roomId: '75-ball',
                        },
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'schedule/50-ball',
                        component: RoomsListComponent,
                        data: {
                            roomId: '50-ball',
                        },
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'schedule/40-ball',
                        component: RoomsListComponent,
                        data: {
                            roomId: '40-ball',
                        },
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'schedule/30-ball',
                        component: RoomsListComponent,
                        data: {
                            roomId: '30-ball',
                        },
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'schedule/36-ball',
                        component: RoomsListComponent,
                        data: {
                            roomId: '36-ball',
                        },
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'schedule',
                        component: RoomsListComponent,
                        data: {
                            roomId: 'all',
                        },
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'myfavourites',
                        component: RoomsListComponent,
                        resolve: {
                            config: ConfigResolver,
                        },
                        canActivate: [AuthGuard],
                    },
                    {
                        path: 'sessionbingo',
                        resolve: {
                            config: ConfigResolver,
                        },
                        canActivate: [PrebuyGuard],
                        loadChildren: () => import('./session-bingo/session-routing.module').then((m) => m.SessionBingoRoutingModule),
                    },
                    {
                        path: 'bingotournaments',
                        resolve: {
                            config: ConfigResolver,
                        },
                        canActivate: [TournamentsGuard],
                        loadChildren: () => import('@frontend/bingo/bingo-tournaments').then((m) => m.BingoTournamentRoutingModule),
                    },
                    {
                        path: 'packagebingo',
                        resolve: {
                            config: ConfigResolver,
                        },
                        canActivate: [PrebuyGuard],
                        loadChildren: () => import('./session-bingo/package-routing.module').then((m) => m.PackageBingoRoutingModule),
                    },
                    {
                        path: 'sessionpopup',
                        resolve: {
                            config: ConfigResolver,
                        },
                        canActivate: [PrebuyGuard],
                        loadChildren: () => import('./session-bingo/buy-now-routing.module').then((m) => m.BuyNowGridRoutingModule),
                    },
                    {
                        path: 'schedule/gameIframelauncher',
                        component: GameIframeLauncherComponent,
                        data: {
                            roomId: 'gameIframeLauncher',
                        },
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                    {
                        path: 'games/:categoryId',
                        component: CasinoCategoryComponent,
                    },
                    {
                        path: 'rooms',
                        component: RoomsComponent,
                        resolve: {
                            config: ConfigResolver,
                        },
                        children: [
                            {
                                path: '',
                                component: RoomsListComponent,
                                data: {
                                    roomId: 'all',
                                },
                                resolve: {
                                    config: ConfigResolver,
                                },
                            },
                            {
                                path: '90-ball',
                                component: RoomsListComponent,
                                data: {
                                    roomId: '90-ball',
                                },
                                resolve: {
                                    config: ConfigResolver,
                                },
                            },
                            {
                                path: '80-ball',
                                component: RoomsListComponent,
                                data: {
                                    roomId: '80-ball',
                                },
                                resolve: {
                                    config: ConfigResolver,
                                },
                            },
                            {
                                path: '75-ball',
                                component: RoomsListComponent,
                                data: {
                                    roomId: '75-ball',
                                },
                                resolve: {
                                    config: ConfigResolver,
                                },
                            },
                            {
                                path: '50-ball',
                                component: RoomsListComponent,
                                data: {
                                    roomId: '50-ball',
                                },
                                resolve: {
                                    config: ConfigResolver,
                                },
                            },
                            {
                                path: '40-ball',
                                component: RoomsListComponent,
                                data: {
                                    roomId: '40-ball',
                                },
                                resolve: {
                                    config: ConfigResolver,
                                },
                            },
                            {
                                path: '30-ball',
                                component: RoomsListComponent,
                                data: {
                                    roomId: '30-ball',
                                },
                                resolve: {
                                    config: ConfigResolver,
                                },
                            },
                            {
                                path: '36-ball',
                                component: RoomsListComponent,
                                data: {
                                    roomId: '36-ball',
                                },
                                resolve: {
                                    config: ConfigResolver,
                                },
                            },
                            {
                                path: ':ballType/games-info/:room-name',
                                component: RoomsListComponent,
                                resolve: {
                                    config: ConfigResolver,
                                },
                            },
                            {
                                path: 'games-info/:room-name',
                                component: RoomsListComponent,
                                resolve: {
                                    config: ConfigResolver,
                                },
                            },
                        ],
                    },
                    {
                        path: 'search',
                        resolve: {
                            config: ConfigResolver,
                        },
                        loadChildren: () => import('./search/search.module').then((m) => m.SearchModule),
                    },
                    {
                        path: 'casinosidepane',
                        resolve: {
                            config: ConfigResolver,
                        },
                        loadChildren: () => import('./casino-side-pane/casino-side-pane.module').then((m) => m.CasinoSidePaneModule),
                    },
                    {
                        path: 'bingowidget/cozybingo',
                        component: BingoWidgetComponent,
                        resolve: {
                            config: ConfigResolver,
                        },
                    },
                ],
            },
            {
                path: 'bingowidget/cozybingo',
                component: BingoWidgetComponent,
                resolve: {
                    config: ConfigResolver,
                },
            },
        ],
    },
    {
        path: '**',
        loadChildren: () => import('@frontend/vanilla/features/not-found').then((m) => m.ROUTES),
    },
];
